import React from 'react'
import { useLoadScript, GoogleMap } from '@react-google-maps/api'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
    getDetails,
    getZipCode,
} from 'use-places-autocomplete'
import Input from '@components/input/input.component'

const libraries = ['places']

const PlacesAutoComplete = () => {
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
    })

    const handleSelect = async (address: any) => {
        setValue(address, false)
        clearSuggestions()
        try {
            const results = await getGeocode({ address })
            const { lat, lng } = await getLatLng(results[0])
            console.log(`${address} Coordinates --> lat: ${lat}, lng: ${lng}`)
            console.log(results)
        } catch (error) {
            console.error('Error fetching geocode or lat/lng:', error)
        }
    }

    return (
        <div>
            Auto complete map
            <Input
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}
                placeholder="Select Your Location"
            />
            {status === 'OK' &&
                data.map(({ place_id, description }) => (
                    <div
                        key={place_id}
                        onClick={() => handleSelect(description)}
                    >
                        {description}
                    </div>
                ))}
        </div>
    )
}

const Experiment = () => {
    const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: API_KEY,
        libraries: ['places'],
    })

    if (!isLoaded) return <div>Loading...</div>

    return (
        <div className="flex flex-col rounded-lg shadow-md themes-bg-white container-global content-full-height">
            <div className="overflow-auto p-4">
                <PlacesAutoComplete />
            </div>
        </div>
    )
}

export default Experiment
