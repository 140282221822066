import Button from '@components/button/button.component'
import useCreateOrganisation from './create-organisation.service'
import { FormikProvider } from 'formik'

import OrganisationDetailsForm from '../organisation-details/components/details/details-form.component'

const CreateOrganisation = () => {
    const { formik, navigate } = useCreateOrganisation()
    return (
        <FormikProvider value={formik}>
            <div className="flex flex-col container-global">
                <div className="flex p-3">
                    <div className="flex w-full justify-between items-center">
                        <div className="flex">
                            <span className="flex text-size-L font-bold">
                                New Organisation
                            </span>
                        </div>
                        <div className="flex">
                            <Button
                                variant="brand"
                                onClick={() => {
                                    formik.submitForm()
                                }}
                                label="SAVE AND CREATE"
                            />
                        </div>
                    </div>
                </div>
                <div className="border-b"></div>

                <div className="p-3">
                    <OrganisationDetailsForm
                        formik={formik}
                        isEdit={true}
                        showAddressForm
                    />
                </div>

                <div className="border-b"></div>
                <div className="p-3 flex justify-between">
                    <Button
                        onClick={() => {
                            navigate(-1)
                        }}
                        label="EXIT"
                    />
                </div>
            </div>
        </FormikProvider>
    )
}

export default CreateOrganisation
