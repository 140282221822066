import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useFormik } from 'formik'
import { useEffect } from 'react'

// Hold - Still waiting API filter quotes
interface IBookingFilter
    extends Pick<
        IFetchParams,
        'consignee' | 'transport' | 'shipper' | 'fileExportType'
    > {}

export const initialBookingFilter: IBookingFilter = {
    fileExportType: 'xls',
    consignee: [],
    transport: [],
    shipper: [],
}

const useBookingsFilter = (
    onFilterSubmit: (data: IFetchParams) => void,
    isGenerateReport?: boolean,
) => {
    const dropdown = useDropdown()
    const formik = useFormik<IBookingFilter>({
        initialValues: initialBookingFilter,
        onSubmit: (values) => {
            generateFilter(values)
        },
    })

    useEffect(() => {
        dropdown.getConsigneeOrganisations()
        dropdown.getShipperOrganisations()
    }, [])

    const generateFilter = async (values: IBookingFilter) => {
        let params: IFetchParams = { ...values, size: 50, page: 1 }
        if (isGenerateReport) params = { ...values, size: 2000, page: 1 }
        onFilterSubmit(params)
    }

    return { formik, dropdown }
}

export default useBookingsFilter
