import { useFormik } from 'formik';
import { useEffect } from 'react';
var useTabMaster = function (_a) {
    var handleSearch = _a.handleSearch, initialSearch = _a.initialSearch;
    var formik = useFormik({
        initialValues: { searchTerm: '' },
        onSubmit: function (values) {
            handleSearch && handleSearch(values.searchTerm);
        },
        validate: function (values) {
            var errors = {};
            if (values.searchTerm.length < 3 &&
                values.searchTerm.length !== 0) {
                errors.searchTerm = 'Search term must be at least 3 characters';
            }
            return errors;
        },
    });
    useEffect(function () {
        if (!initialSearch)
            return;
        formik.setValues({ searchTerm: initialSearch });
    }, [initialSearch]);
    return {
        formik: formik,
    };
};
export default useTabMaster;
