import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
export const initialState = {
    isMenuHover: false,
}
const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setMenuHover(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isMenuHover: action.payload,
            }
        },
    },
})

export const menuHoverSelector = (state: RootState) => state.layout.isMenuHover
export const { setMenuHover } = layoutSlice.actions
export default layoutSlice.reducer
