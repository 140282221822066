import { IFetchParams, IResponseData } from 'common/common.interface'
import { IShipment } from './interface/shipment.interface'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { errorAxiosToast, fetch, post, put } from 'common/common.service'
import { IFormCreateShipmentPayload } from 'form-validation/shipment-create.validation'
import { Toast } from '@components/toast/toast.component'
import { IFormShipmentDetailPayload } from 'form-validation/shipment-details.validation'

export const getShipments = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const response = await fetch<IResponseData<IShipment[]>, IFetchParams>({
            endpoint: endpoints.shipments,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Bookings Data',
            })
        }
        return undefined
    }
}

export const getShipmentDetail = async (code: string) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}`
        const response = await fetch<IResponseData<IShipment>, IFetchParams>({
            endpoint: endpoint,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Fetch',
            message: 'Failed Fetch Shipment Detail',
        })
        return undefined
    }
}

export const submitShipment = async (payload: IFormCreateShipmentPayload) => {
    try {
        const endpoint = `${endpoints.shipments}`
        const response: IResponseData<any> = await post<
            IResponseData<any>,
            IFormCreateShipmentPayload
        >({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'New shipment is created',
            type: 'success',
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Submit Shipment Data',
        })
        return undefined
    }
}

export const updateShipment = async (
    shipmentCode: string,
    payload: IFormShipmentDetailPayload,
) => {
    try {
        const endpoint = `${endpoints.shipments}/${shipmentCode}`
        await put<IResponseData<any>, IFormShipmentDetailPayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Update Success',
            message: 'Shipment is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Update',
            message: 'Failed Update Shipment Data',
        })
        return undefined
    }
}

export const updateShipmentStatus = async (
    shipmentCode: string,
    statusShipment: string,
) => {
    try {
        const endpoint = `${endpoints.shipments}/${shipmentCode}/change_status?status=${statusShipment}`
        await put<IResponseData<any>, IFormShipmentDetailPayload>({
            endpoint: endpoint,
        })

        Toast({
            header: 'Update Success',
            message: 'Success update shipment status',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Update',
            message: 'Failed Update Shipment status Data',
        })
        return undefined
    }
}
