import { IRoute } from 'common/common.interface'
import Settings from 'pages/settings/settings.component'
import { DashboardInProgress } from 'pages/work-in-progress/work-in-progress.component'
import Bookings from 'pages/bookings/bookings.component'
import CreateOrganisation from 'pages/organisation/create-organisation/create-organisation.component'
import Contact from 'pages/contact/contact.component'
import OrganisationDetail from 'pages/organisation/organisation-details/organisation-details.component'
import Organisation from 'pages/organisation/organisation.component'
import Shipments from 'pages/shipments/shipments.component'
import UserAccess from 'pages/user-acces/user-access.component'
import UserAccessDetail from 'pages/user-acces/user-access-detail/user-access-detail.component'
import UserAccessForm from 'pages/user-acces/user-access-form/user-access-form.component'
import Quotes from 'pages/quotes/quotes.component'
import FormBooking from 'pages/bookings/form-booking/form-booking.component'
import Experiment from 'pages/experiment/experiment.component'
import FormQuote from 'pages/quotes/form/form.component'
import CreateShipment from 'pages/shipments/form/create-shipment/create-shipment.component'
import DetailShipment from 'pages/shipments/form/detail-shipment/detail-shipment.component'

export const loglinesRoutes: IRoute[] = [
    {
        path: '/',
        text: 'Dashboard',
        Content: DashboardInProgress,
        show: false,
        icon: 'ri-dashboard-line',
        textTranslationCode: 'menu.dashboard',
    },
    {
        path: '/dashboard',
        text: 'Dashboard',
        Content: DashboardInProgress,
        show: true,
        icon: 'ri-dashboard-line',
        textTranslationCode: 'menu.dashboard',
    },
    {
        path: '/quotes',
        text: 'Quotes',
        Content: Quotes,
        show: true,
        icon: 'ri-file-paper-line',
        textTranslationCode: 'menu.quotes',
    },
    {
        path: '/quote-details/:id',
        text: 'Detail Quote',
        Content: () => FormQuote({ isDetailPage: true }),
        textTranslationCode: 'quotes.quote_details',
    },
    {
        path: '/new-quote',
        text: 'Quote',
        Content: () => FormQuote({ isDetailPage: false }),
        textTranslationCode: 'menu.quotes_new',
    },
    {
        path: '/inquiry',
        text: 'Inquiry',
        Content: Bookings,
        show: true,
        icon: 'ri-book-line',
    },
    {
        path: '/inquiry/create',
        text: 'Inquiry',
        Content: () => FormBooking({ isPageDetails: false }),
    },
    {
        path: '/inquiry/:id',
        text: 'Inquiry',
        Content: () => FormBooking({ isPageDetails: true }),
    },
    {
        path: 'freight-forwarding',
        text: 'Freight Forwarding',
        show: true,
        icon: 'ri-truck-line',
        expandable: true,
        sub: [
            {
                path: '/freight-forwarding/sea',
                text: 'SEA',
                breadcrumbText: 'Freight Forwarding: Sea',
                Content: () => Shipments('SEA'),
                show: true,
            },
            {
                path: '/freight-forwarding/air',
                text: 'AIR',
                breadcrumbText: 'Freight Forwarding: Air',
                Content: () => Shipments('AIR'),
                show: true,
            },
            // {
            //     path: '/freight-forwarding/consolidation',
            //     text: 'Consolidation',
            //     breadcrumbText: 'Freight Forwarding: Consolidation',
            //     Content: () => Shipments('CONSOLIDATION'),
            //     show: true,
            // },
        ],
    },
    {
        path: '/freight-forwarding/sea/create',
        text: 'SEA',
        breadcrumbText: 'Freight Forwarding: Sea',
        Content: () => CreateShipment('SEA'),
    },
    {
        path: '/freight-forwarding/air/create',
        text: 'AIR',
        breadcrumbText: 'Freight Forwarding: AIR',
        Content: () => CreateShipment('AIR'),
    },
    {
        path: '/freight-forwarding/consolidation/create',
        text: 'CONSOLIDATION',
        breadcrumbText: 'Freight Forwarding: Consolidation',
        Content: () => CreateShipment('CONSOLIDATION'),
    },
    {
        path: '/freight-forwarding/details/:id',
        text: 'SHIPMENT DEATILS',
        breadcrumbText: 'Freight Forwarding: Details',
        Content: () => DetailShipment(),
    },
    {
        path: 'booking-details',
        text: 'Booking',
        Content: () => FormBooking({ isPageDetails: true }),
        textTranslationCode: 'menu.bookings_details',
    },
    {
        path: 'organisation',
        text: 'Organisation',
        show: true,
        icon: 'ri-contacts-book-2-line',
        textTranslationCode: 'menu.organisation',
        expandable: true,
        sub: [
            {
                path: '/organisation',
                text: 'Organisation',
                textTranslationCode: 'menu.organisation',
                Content: Organisation,
                show: true,
            },
            {
                path: '/contact-list',
                text: 'Contact List',
                textTranslationCode: 'menu.contact_list',
                Content: Contact,
                show: true,
            },
        ],
    },
    {
        path: 'organisation-detail/:id',
        text: 'Organisation Details',
        Content: OrganisationDetail,
        textTranslationCode: 'menu.organisation_detail',
    },
    {
        path: 'create-organisation',
        text: 'Organisation ',
        Content: CreateOrganisation,
        textTranslationCode: 'menu.organisation_create',
    },
    {
        path: '/manage-users',
        text: 'Manage User',
        textTranslationCode: 'menu.manage_user',
        Content: UserAccess,
        show: true,
        icon: 'ri-user-line',
    },
    {
        path: '/manage-users/details/:id',
        text: 'User Details',
        textTranslationCode: 'menu.manage_user_details',
        Content: UserAccessDetail,
    },
    // {
    //     path: '/profile',
    //     text: 'Profile',
    //     Content: () => ManageUserForm({ isNew: false, isProfilePage: true }),
    // },
    {
        path: 'manage-users/create/',
        text: 'Create User',
        Content: () => UserAccessForm({ isNew: true, isProfilePage: false }),
    },
    {
        path: 'manage-users/update/:id',
        text: 'Update User',
        Content: () => UserAccessForm({ isNew: false, isProfilePage: false }),
    },
    {
        path: '/settings',
        text: 'Settings',
        Content: Settings,
        show: true,
        icon: 'ri-settings-line',
        textTranslationCode: 'menu.settings',
    },
    {
        path: 'experiment',
        text: 'Experiment Page',
        Content: Experiment,
    },
]
