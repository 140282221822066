import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import './checkbox.style.css';
function Checkbox(_a) {
    var _b = _a.isChecked, isChecked = _b === void 0 ? false : _b, _c = _a.useFormik, useFormik = _c === void 0 ? false : _c, name = _a.name, value = _a.value, onChecked = _a.onChecked, disabled = _a.disabled, className = _a.className, formikOnChange = _a.formikOnChange;
    var _d = useState(false), checked = _d[0], setChecked = _d[1];
    useEffect(function () {
        if (!useFormik) {
            setChecked(isChecked);
        }
    }, [isChecked, useFormik]);
    var handleChange = function (e) {
        var val = e.target.checked;
        if (useFormik && formikOnChange) {
            // If it's a Formik checkbox, trigger Formik's onChange
            formikOnChange(e);
        }
        else {
            // Otherwise, handle local state change
            setChecked(val);
            onChecked && onChecked(val);
        }
    };
    return (_jsx("input", { className: "rounded-logistical-radius-2 cursor-pointer loglines-checkbox themes-border-color ".concat(className), type: "checkbox", checked: useFormik ? isChecked : checked, name: name, disabled: disabled, onChange: handleChange }));
}
export default Checkbox;
