import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { Toast } from '@components/toast/toast.component'
import { addressListValidation } from 'form-validation/organisation-details.validation'
import { addressListDummy } from 'pages/organisation/organisation.dummy'
import { IAddressList } from '../../organisation-details.interface'
import {
    createOrganisationAddress,
    deleteOrganisationAddress,
    getCities,
    getCountries,
    getOrganisationAddressData,
    getStates,
    getUnlocodes,
    updateOrganisationAddress,
} from 'pages/organisation/organisation.repository'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
    citiesDataSelector,
    citiesIsLoadingSelector,
    countriesDataSelector,
    countriesIsLoadingSelector,
    setCitiesData,
    setCountriesData,
    setIsLoadingCities,
    setIsLoadingCountries,
    setIsLoadingStates,
    setIsLoadingUnlocodes,
    setStatesData,
    setUnlocodesData,
    statesDataSelector,
    statesIsLoadingSelector,
    unlocodesDataSelector,
    unlocodesIsLoadingSelector,
} from 'pages/organisation/organisation.slice'

const useAddressListService = () => {
    const { id = 'not found' } = useParams()
    const dispatch = useDispatch()

    const countries = useSelector(countriesDataSelector)
    const states = useSelector(statesDataSelector)
    const cities = useSelector(citiesDataSelector)
    const unlocodes = useSelector(unlocodesDataSelector)
    const isLoadingCountries = useSelector(countriesIsLoadingSelector)
    const isLoadingStates = useSelector(statesIsLoadingSelector)
    const isLoadingCities = useSelector(citiesIsLoadingSelector)
    const isLoadingUnlocodes = useSelector(unlocodesIsLoadingSelector)

    const [addressListData, setAddressListData] = useState<IAddressList[]>([])
    const [addressDetail, setAddressDetail] = useState<IAddressList>()
    const [loading, setLoading] = useState<boolean>(false)

    const formikOrgAddressDetail = useFormik<IAddressList>({
        initialValues: {
            code: '',
            address_name: '',
            street: '',
            street_2: '',
            city: {
                code: '',
                name: '',
            },
            state: {
                code: '',
                name: '',
            },
            country: {
                code: '',
                name: '',
            },
            post_code: '',
            unlocode: '',
            relatedCity: '',
            is_main: false,
            is_active: false,
        },
        onSubmit: async (values) => {
            if (id) {
                const res = await updateOrganisationAddress(id, values)
                if (res) {
                    loadAddressList()
                }
            }
        },
        enableReinitialize: true,
    })

    const formikOrgCreateAddress = useFormik<IAddressList>({
        initialValues: {
            code: '',
            address_name: '',
            street: '',
            street_2: '',
            city: {
                code: '',
                name: '',
            },
            state: {
                code: '',
                name: '',
            },
            country: {
                code: '',
                name: '',
            },
            post_code: '',
            unlocode: '',
            relatedCity: '',
            is_main: false,
            is_active: false,
        },
        onSubmit: async (values) => {
            if (id) {
                const res = await createOrganisationAddress(id, values)
                if (res) {
                    loadAddressList()
                }
            }
        },
        enableReinitialize: true,
    })

    // useEffect

    useEffect(() => {
        loadAddressList()
        loadCountries()
        loadUnlocodes()
        return () => {
            setAddressListData([])
        }
    }, [])

    useEffect(() => {
        if (formikOrgCreateAddress.values.country.code !== '') {
            loadStates({ country: formikOrgCreateAddress.values.country.code })
        }
    }, [formikOrgCreateAddress.values.country])

    useEffect(() => {
        if (formikOrgCreateAddress.values.state.code !== '') {
            loadCities({ state: formikOrgCreateAddress.values.state.code })
        }
    }, [formikOrgCreateAddress.values.state])

    useEffect(() => {
        if (formikOrgAddressDetail.values.country?.code !== '') {
            loadStates({ country: formikOrgAddressDetail.values.country.code })
        }
    }, [formikOrgAddressDetail.values.country])

    useEffect(() => {
        if (formikOrgAddressDetail.values.state?.code !== '') {
            loadCities({ state: formikOrgAddressDetail.values.state.code })
        }
    }, [formikOrgAddressDetail.values.state])

    const handleAddressDetail = (data: IAddressList) => {
        setAddressDetail(data)
        formikOrgAddressDetail.setValues(data)
    }

    const handleDuplicate = async () => {
        if (id && addressDetail) {
            const res = await createOrganisationAddress(id, addressDetail)
            if (res) {
                loadAddressList()
            }
        }
    }
    const loadAddressList = async () => {
        try {
            setLoading(true)
            const response = await getOrganisationAddressData(id)

            console.log('ini', response)
            setAddressListData(response.data as IAddressList[])

            if (response?.data?.length > 0) {
                formikOrgAddressDetail.setValues(response.data?.[0])
                setAddressDetail(response.data?.[0])
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const deleteAddress = async (dataId: string) => {
        try {
            setLoading(true)
            const response = await deleteOrganisationAddress(id, dataId)
            const filterData = addressListData.filter(
                (data) => data.code !== dataId,
            )
            setAddressListData(filterData)

            if (filterData?.length > 0) {
                formikOrgAddressDetail.setValues(filterData[0])
                setAddressDetail(filterData[0])
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const loadCountries = async () => {
        try {
            dispatch(setIsLoadingCountries(true))
            const response = await getCountries()
            const transformedData = response.data?.map((type) => ({
                value: type.code,
                label: type.name,
            }))
            dispatch(setCountriesData(transformedData))
            dispatch(setIsLoadingCountries(false))
        } catch (e) {
            dispatch(setIsLoadingCountries(false))
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const loadStates = async ({ country }: { country?: string }) => {
        try {
            dispatch(setIsLoadingStates(true))
            const response = await getStates({ country })
            console.log(response)

            const transformedData = response.data?.map((type) => ({
                value: type.code,
                label: type.name,
            }))
            dispatch(
                setStatesData(
                    response.data?.length === 0
                        ? [{ value: 'NO DATA FOUND', label: 'NO DATA FOUND' }]
                        : transformedData,
                ),
            )
            dispatch(setIsLoadingStates(false))
        } catch (e) {
            dispatch(setIsLoadingStates(false))
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const loadCities = async ({ state }: { state?: string }) => {
        try {
            dispatch(setIsLoadingCities(true))
            const response = await getCities({ state })
            const transformedData = response.data?.map((type) => ({
                value: type.code,
                label: type.name,
            }))
            dispatch(
                setCitiesData(
                    response.data?.length === 0
                        ? [{ value: 'NO DATA FOUND', label: 'NO DATA FOUND' }]
                        : transformedData,
                ),
            )
            dispatch(setIsLoadingCities(false))
        } catch (e) {
            dispatch(setIsLoadingCities(false))
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const loadUnlocodes = async () => {
        try {
            dispatch(setIsLoadingUnlocodes(true))
            const response = await getUnlocodes()
            const transformedData = response.data?.map((type) => ({
                value: type.code,
                label: type.name,
            }))
            dispatch(setUnlocodesData(transformedData))
            dispatch(setIsLoadingUnlocodes(false))
        } catch (e) {
            dispatch(setIsLoadingUnlocodes(false))
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    // Modal Service

    return {
        addressDetail,
        setAddressDetail,
        handleAddressDetail,
        addressListData,
        deleteAddress,
        setAddressListData,
        loading,
        formikOrgAddressDetail,
        formikOrgCreateAddress,
        countries,
        states,
        cities,
        unlocodes,
        isLoadingCities,
        isLoadingCountries,
        isLoadingStates,
        isLoadingUnlocodes,
        handleDuplicate,
    }
}

export default useAddressListService
