var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/alt-text */
// import { Spinner } from 'flowbite-react'
import './spinner-table.style.css';
import loadingImage from '@assets/gif/LOADING-MLS-GRAY.gif';
export default function SpinnerTable(_a) {
    var message = _a.message, className = _a.className;
    return (_jsxs("div", __assign({ className: "spinner-container flex-col gap-4 ".concat(className) }, { children: [_jsx("img", { src: loadingImage, className: "w-20 h-20" }), _jsx("p", { children: message })] })));
}
