import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
    packLinesSelector,
    setFetchPackLines,
    setPackLinesData,
    shipmentDetailSelector,
} from '../detail-shipment.slice'
import {
    deletePackLines,
    fetchPackLines,
    submitPackLines,
    updatePackLines,
} from 'repository/pack-lines.repository'
import { useFormik } from 'formik'
import {
    IFormPackLine,
    IFormPackLinePayload,
    usePackLinesValidation,
} from 'form-validation/pack-line.validation'
import { useModal } from '@components/modal/modal.service'
import { IPackLines } from 'repository/interface/packlines.interface'

export const usePackLines = () => {
    const dropdownService = useDropdown()
    const dispatch = useAppDispatch()

    // States
    const [isLoading, setIsLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [isNew, setIsNew] = useState(true)

    // Selector
    const packLines = useSelector(packLinesSelector)
    const shipmentDetailData = useSelector(shipmentDetailSelector)

    // Variable
    const { data, isFetchNew } = packLines
    const modalService = useModal()

    // Formik
    const formikTotal = useFormik({
        validateOnChange: true,
        initialValues: {
            total_lines: '0',
            total_packs: '0',
            total_weight: '0',
            total_volume: '0',
        },
        onSubmit: (values) => {
            console.log(values)
        },
    })
    const packLineService = usePackLinesValidation()
    const formikPackLines = useFormik<IFormPackLine>({
        initialValues: packLineService.initialValue,
        validationSchema: packLineService.schema,
        validateOnChange: true,
        onSubmit: (values) => {
            submit(values)
        },
    })

    const getPackLines = async () => {
        if (!shipmentDetailData?.code) return
        if (!isFetchNew) return
        setIsLoading(true)
        const response = await fetchPackLines(shipmentDetailData.code)
        if (!response?.data?.length) {
            dispatch(setPackLinesData([]))
        } else {
            dispatch(setPackLinesData(response.data))
        }

        // count total
        const data = response?.data ?? []
        let totalLines = data.length
        let totalPacks = 0
        let totalWeight = 0
        let totalVolume = 0

        data.forEach((d) => {
            totalPacks += d.total_packs ?? 0
            totalVolume += d.volume ?? 0
            totalWeight += d.weight ?? 0
        })

        formikTotal.setValues({
            total_lines: totalLines.toString(),
            total_packs: totalPacks.toString(),
            total_weight: totalWeight.toString(),
            total_volume: totalVolume.toString(),
        })

        setIsLoading(false)
        dispatch(setFetchPackLines(false))
    }

    const resetForm = () => {
        formikPackLines.setValues(packLineService.initialValue)
    }

    const getPayloadUnitValue = (value: string | undefined) => {
        if (value === undefined) return undefined
        if (value === '0') return undefined
        return { code: value }
    }
    const getPayloadQtyValue = (value: string | undefined) => {
        if (value === undefined) return undefined
        if (value === '0') return undefined
        return value
    }

    const submit = async (value: IFormPackLine) => {
        const code = shipmentDetailData.code ?? ''
        const packLinesCode = value.pack_line_code ?? ''
        const payload: IFormPackLinePayload = {
            total_packs: value.total_packs,
            pack_type: value.pack_type,
            product_categories: value.product_categories,
            hs_codes: value.hs_codes,
            length: getPayloadQtyValue(value?.length?.qty),
            length_unit: getPayloadUnitValue(value?.length?.unit),
            width: getPayloadQtyValue(value?.width?.qty),
            width_unit: getPayloadUnitValue(value?.width?.unit),
            height: getPayloadQtyValue(value?.height?.qty),
            height_unit: getPayloadUnitValue(value?.height?.unit),
            weight: getPayloadQtyValue(value?.weight?.qty),
            weight_unit: getPayloadUnitValue(value?.weight?.unit),
            volume: getPayloadQtyValue(value?.volume?.qty),
            volume_unit: getPayloadUnitValue(value?.volume?.unit),
            goods_description: value.goods_description,
            in_container: value.in_container,
        }
        setSubmitLoading(true)
        let response: true | undefined
        if (isNew) {
            response = await submitPackLines(code, payload)
        } else {
            response = await updatePackLines(code, packLinesCode, payload)
        }
        if (!response) {
            setSubmitLoading(false)
            return
        }

        resetForm()
        modalService.closeModalHandling()
        setSubmitLoading(false)
        getPackLines()
    }

    const selectRow = (value: IPackLines) => {
        const newValue: IFormPackLine = {
            code: value.code ?? '',
            total_packs: value.total_packs ?? 0,
            pack_type: value.pack_type ?? '',
            product_categories:
                value.product_categories?.map((v) => v.code) ?? [],
            hs_codes: value.hs_codes?.map((v) => v.code) ?? [],
            length: {
                qty: value.length?.toString() ?? '0',
                unit: value.length_unit?.code,
                unitLabel: value.length_unit?.symbol,
            },
            width: {
                qty: value.width?.toString() ?? '0',
                unit: value.width_unit?.code,
                unitLabel: value.width_unit?.symbol,
            },
            height: {
                qty: value.height?.toString() ?? '0',
                unit: value.height_unit?.code,
                unitLabel: value.height_unit?.symbol,
            },
            weight: {
                qty: value.weight?.toString() ?? '0',
                unit: value.weight_unit?.code,
                unitLabel: value.weight_unit?.symbol,
            },
            volume: {
                qty: value.volume?.toString() ?? '0',
                unit: value.volume_unit?.code,
                unitLabel: value.volume_unit?.symbol,
            },
            goods_description: value.goods_description ?? '',
            in_container: value.in_container ?? false,
            pack_id: value.pack_id ?? '(Auto Generated)',
            pack_line_code: value.code ?? '',
        }
        formikPackLines.setValues(newValue)
        modalService.openModalHandling()
    }

    const deleteItem = async () => {
        const code = shipmentDetailData.code ?? ''
        const containerCode = formikPackLines.getFieldProps('code').value
        setDeleteLoading(true)
        await deletePackLines(code, containerCode)
        setDeleteLoading(false)
        resetForm()
        modalService.closeModalHandling()
        dispatch(setFetchPackLines(true))
        getPackLines()
    }

    useEffect(() => {
        getPackLines()
    }, [isFetchNew, shipmentDetailData])

    useEffect(() => {
        dropdownService.getPackTypes()
        dropdownService.getHSCodes()
        dropdownService.getProductCategories()
        dropdownService.getLengthUnit()
        dropdownService.getVolumeUnit()
        dropdownService.getWeightUnit()
    }, [])

    return {
        dropdownService,
        modalService,
        isLoading,
        data,
        formikTotal,
        formikPackLines,
        isNew,
        submitLoading,
        deleteLoading,
        deleteItem,
        setIsNew,
        resetForm,
        submit,
        selectRow,
    }
}
