// Components
import Tab from '@components/tab/tab.component'
import StatusCard from '@components/status-card/status-card.component'
import Button from '@components/button/button.component'

// Static Data
import { organisationDetailTabHeaders } from './organisation-details.static'

// Services
import useOrganisationDetail from './organisation-details.service'

const OrganisationDetail = () => {
    const {
        tabFilter,
        navigate,
        organisationDetailData,
        allModal,
        setTabFilter,
        getContent,
    } = useOrganisationDetail()

    const handleNavigateBack = () => navigate(-1)
    const borderComponent = <div className="border-b"></div>

    return (
        <div className="flex flex-col container-global">
            <header className="text-size-L font-bold p-3 flex justify-between">
                <div className="flex py-1">
                    <span className="mr-3">{organisationDetailData?.name}</span>
                    <StatusCard
                        status={
                            organisationDetailData?.is_active
                                ? 'ACTIVE'
                                : 'NON ACTIVE'
                        }
                    />
                </div>
            </header>
            {borderComponent}

            <Tab
                containerClassName="px-3 overflow-auto"
                items={organisationDetailTabHeaders}
                tabFilter={tabFilter}
                onChange={setTabFilter}
            />
            {borderComponent}

            <div className="p-3">{getContent()}</div>
            {borderComponent}

            <footer className="p-3 flex justify-between">
                <Button onClick={handleNavigateBack} label="EXIT" />
                <Button onClick={handleNavigateBack} label="EMAIL" />
            </footer>

            {allModal}
        </div>
    )
}

export default OrganisationDetail
