import { IResponseData } from 'common/common.interface'
import {
    deleteRequest,
    fetch,
    post,
    postAllHttp,
    put,
} from 'common/common.service'
import { Toast } from '@components/toast/toast.component'
import { IOrganisation, IOrganisationFilter } from './organisation.interface'
import { organisationEndpoints } from './organisation.static'
import {
    IAccounts,
    IAddressList,
    IContactList,
    IEDocs,
    IFormikOrgDetail,
    INotes,
    Ilabel,
} from './organisation-details/organisation-details.interface'
import { IDropdown } from '@components/dropdown/dropdown.interface'

export const getOrganisationData = async (filter: IOrganisationFilter) => {
    try {
        const filteredParams = Object.fromEntries(
            Object.entries(filter).filter(
                ([, value]) =>
                    value !== null && value !== undefined && value !== '',
            ),
        )

        const response = await fetch<
            IResponseData<IOrganisation[]>,
            IOrganisationFilter
        >({
            endpoint: organisationEndpoints.organisations,
            params: filteredParams,
        })

        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Organisation data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Organisation data',
                message: `Unknown error occurred while fetching Organisation data."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IOrganisation[]>
        err.isSuccess = false
        return err
    }
}

export const getOrganisationDetail = async (organisationId?: string) => {
    try {
        const response = await fetch<IResponseData<IOrganisation>, void>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}`,
        })
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Organisation detail',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Organisation detail',
                message: `Unknown error occurred while fetching Organisation detail."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IOrganisation>
        err.isSuccess = false
        return err
    }
}

export const getOrganisationCategories = async (): Promise<
    IResponseData<Ilabel[]>
> => {
    try {
        const response = await fetch<IResponseData<Ilabel[]>, void>({
            endpoint: organisationEndpoints.categories,
        })

        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Organisation Types',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Organisation Types',
                message: `Unknown error occurred while fetching Organisation Types data.`,
                type: 'error',
            })
        }
        const err = error as IResponseData<Ilabel[]>
        err.isSuccess = false
        return err
    }
}

export const getLanguages = async (): Promise<IResponseData<Ilabel[]>> => {
    try {
        const response = await fetch<IResponseData<Ilabel[]>, void>({
            endpoint: organisationEndpoints.languages,
        })

        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Languages',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Languages',
                message: `Unknown error occurred while fetching data.`,
                type: 'error',
            })
        }
        const err = error as IResponseData<Ilabel[]>
        err.isSuccess = false
        return err
    }
}

export const getTax = async (): Promise<IResponseData<Ilabel[]>> => {
    try {
        const response = await fetch<IResponseData<Ilabel[]>, void>({
            endpoint: organisationEndpoints.tax_types,
        })

        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Tax Type',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Tax Type',
                message: `Unknown error occurred while fetching data.`,
                type: 'error',
            })
        }
        const err = error as IResponseData<Ilabel[]>
        err.isSuccess = false
        return err
    }
}

export const getCountries = async (): Promise<IResponseData<Ilabel[]>> => {
    try {
        const response = await fetch<IResponseData<Ilabel[]>, void>({
            endpoint: organisationEndpoints.countries,
        })

        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Countries',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Countries',
                message: `Unknown error occurred while fetching data.`,
                type: 'error',
            })
        }
        const err = error as IResponseData<Ilabel[]>
        err.isSuccess = false
        return err
    }
}

export const getStates = async ({
    country,
}: {
    country?: string
}): Promise<IResponseData<Ilabel[]>> => {
    try {
        const response = await fetch<IResponseData<Ilabel[]>, void>({
            endpoint: organisationEndpoints.states,
            params: {
                country: country,
            },
        })

        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'States',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'States',
                message: `Unknown error occurred while fetching data.`,
                type: 'error',
            })
        }
        const err = error as IResponseData<Ilabel[]>
        err.isSuccess = false
        return err
    }
}

export const getCities = async ({
    state,
}: {
    state?: string
}): Promise<IResponseData<Ilabel[]>> => {
    try {
        const response = await fetch<IResponseData<Ilabel[]>, void>({
            endpoint: organisationEndpoints.cities,
            params: {
                state: state,
            },
        })

        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Cities',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Cities',
                message: `Unknown error occurred while fetching data.`,
                type: 'error',
            })
        }
        const err = error as IResponseData<Ilabel[]>
        err.isSuccess = false
        return err
    }
}

export const getUnlocodes = async (): Promise<IResponseData<Ilabel[]>> => {
    try {
        const response = await fetch<IResponseData<Ilabel[]>, void>({
            endpoint: organisationEndpoints.unlocodes,
        })

        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Unlocodes',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Unlocodes',
                message: `Unknown error occurred while fetching data.`,
                type: 'error',
            })
        }
        const err = error as IResponseData<Ilabel[]>
        err.isSuccess = false
        return err
    }
}

export const postCreateOrganisation = async (payload: IFormikOrgDetail) => {
    try {
        const endpoint = `${organisationEndpoints.organisations}`
        const response: any = await postAllHttp({
            endpoint,
            payload: {
                name: payload.name,
                addresses: payload.addresses,
                category: payload.category,
                email_1: payload.email_1,
                email_2: payload.email_2,
                phone_no: payload.phone_no,
                mobile_no: payload.mobile_no,
                tax_type: payload.tax_type,
                tax_number: payload.tax_number,
                is_services: payload.is_services,
                is_carrier: payload.is_carrier,
                is_shipper: payload.is_shipper,
                is_consignee: payload.is_consignee,
                is_agent: payload.is_agent,
                is_supplier: payload.is_supplier,
                is_customer: payload.is_customer,
                is_transport: payload.is_transport,
                is_warehouse: payload.is_warehouse,
                is_notify: payload.is_notify,
                is_vip: payload.is_vip,
                is_sales: payload.is_sales,
                is_store: payload.is_store,
                language: payload.language,
                is_active: payload.is_org_active,
                fax: payload.fax,
                website_url: payload.website_url,
                consignment_delivery_address:
                    payload.consignment_delivery_address,
            },
        })

        // TODO: Chain request, if organisation status 201 then post address

        if (response && response.status === 201) {
            const organisationCode: any = response.data?.message
            console.log('organisationCode', organisationCode?.message)

            const addressEndpoint = `${organisationEndpoints.organisations}/${organisationCode}/addresses`
            await post<IResponseData<any>, any>({
                endpoint: addressEndpoint,
                payload: {
                    is_main: true,
                    is_active: true,
                    name: payload.address_name,
                    street: payload.street,
                    street_2: payload.street_2,
                    city: payload.city,
                    state: payload.state,
                    country: payload.country,
                    post_code: payload.post_code,
                    unlocode: payload.unlocode,
                },
            })

            Toast({
                header: 'Save Organisation',
                message: `Organisation successfully saved`,
                type: 'success',
            })
        }

        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Save Organisation',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Save Organisation',
                message: `Unknown error occurred while fetching`,
                type: 'error',
            })
        }
        return error
    }
}

export const updateOrganisationDetail = async (
    organisationId: string,
    payload: any,
) => {
    try {
        const response = await put<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}`,
            payload,
        })
        if (response) {
            Toast({
                header: 'Organisation Updated',
                message: `Organisation: ${organisationId} successfully updated.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IOrganisation>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const updateOrganisationAddress = async (
    organisationId: string,
    payload: IAddressList,
) => {
    try {
        const response = await put<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/addresses/${payload.code}`,
            payload,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IAddressList>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const createOrganisationAddress = async (
    organisationId: string,
    payload: IAddressList,
) => {
    try {
        const response = await post<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/addresses`,
            payload,
        })
        if (response) {
            Toast({
                header: 'Organisation Created',
                message: `Organisation: ${organisationId} successfully created.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IAddressList>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const updateOrganisationContact = async (
    organisationId: string,
    payload: any,
) => {
    try {
        const response = await put<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/contacts/${payload.code}`,
            payload,
        })
        if (response) {
            Toast({
                header: 'Contact Updated',
                message: `Organisation: ${organisationId} successfully Updated.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IAddressList>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const createOrganisationContact = async (
    organisationId: string,
    payload: any,
) => {
    try {
        const response = await post<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/contacts`,
            payload,
        })
        if (response) {
            Toast({
                header: 'Contact Created',
                message: `Organisation: ${organisationId} successfully created.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IAddressList>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const getOrganisationEDocs = async (organisationId?: string) => {
    try {
        const response = await fetch<IResponseData<IEDocs[]>, void>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/edocs`,
        })
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Organisation EDoc',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Organisation EDoc',
                message: `Unknown error occurred while fetching Organisation EDoc."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IEDocs[]>
        err.isSuccess = false
        return err
    }
}

export const createOrganisationEDocs = async (
    organisationId: string,
    payload: any,
) => {
    try {
        const response = await post<IResponseData<IEDocs>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/edocs`,
            payload,
        })
        if (response) {
            Toast({
                header: 'Edocs Created',
                message: `Edocs: ${organisationId} successfully created.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IEDocs>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const getOrganisationDocumentTypes = async () => {
    try {
        const response = await fetch<IResponseData<Ilabel[]>, void>({
            endpoint: `${organisationEndpoints.document_types}`,
        })
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Document Types',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Document Types',
                message: `Unknown error occurred while fetching Document Types"`,
                type: 'error',
            })
        }
        const err = error as IResponseData<Ilabel[]>
        err.isSuccess = false
        return err
    }
}

export const updateOrganisationEDoc = async (
    organisationId: string,
    payload: any,
) => {
    try {
        const response = await put<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/edocs/${payload.code}`,
            payload,
        })
        if (response) {
            Toast({
                header: 'Edocs Updated',
                message: `Edocs: ${organisationId} successfully updated.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IAddressList>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const getOrganisationAddressData = async (organisationId?: string) => {
    try {
        const response = await fetch<IResponseData<IAddressList[]>, void>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/addresses`,
        })
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Organisation Address',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Organisation Address',
                message: `Unknown error occurred while fetching Organisation address."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IAddressList[]>
        err.isSuccess = false
        return err
    }
}

export const getOrganisationContactData = async (organisationId?: string) => {
    try {
        const response = await fetch<IResponseData<IContactList[]>, void>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/contacts`,
        })
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Organisation Contact',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Organisation Contact',
                message: `Unknown error occurred while fetching Organisation contact."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IContactList[]>
        err.isSuccess = false
        return err
    }
}

export const getOrganisationNoteData = async (organisationId?: string) => {
    try {
        const response = await fetch<IResponseData<INotes[]>, void>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/notes`,
        })
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Organisation Note',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Organisation Note',
                message: `Unknown error occurred while fetching Organisation Note."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<INotes[]>
        err.isSuccess = false
        return err
    }
}

export const updateOrganisationNote = async (
    organisationId: string,
    payload: any,
) => {
    try {
        const response = await put<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/notes/${payload.code}`,
            payload,
        })
        if (response) {
            Toast({
                header: 'Note Updated',
                message: `Organisation: ${organisationId} successfully Updated.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<INotes>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const createOrganisationNote = async (
    organisationId: string,
    payload: any,
) => {
    try {
        const response = await post<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/notes`,
            payload,
        })
        if (response) {
            Toast({
                header: 'Note Created',
                message: `Organisation: ${organisationId} successfully created.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<INotes>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const updateOrganisationAccount = async (
    organisationId: string,
    payload: any,
) => {
    try {
        const response = await put<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/accounts/${payload.code}`,
            payload,
        })
        if (response) {
            Toast({
                header: 'Accounts Updated',
                message: `Organisation: ${organisationId} successfully Updated.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IAccounts>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const createOrganisationAccount = async (
    organisationId: string,
    payload: any,
) => {
    try {
        const response = await post<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/accounts`,
            payload,
        })
        if (response) {
            Toast({
                header: 'Accounts Created',
                message: `Organisation: ${organisationId} successfully created.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IAccounts>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const getOrganisationAccountData = async (organisationId?: string) => {
    try {
        const response = await fetch<IResponseData<IAccounts[]>, void>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/accounts`,
        })
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Organisation Account',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Organisation Account',
                message: `Unknown error occurred while fetching Organisation Note."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IAccounts[]>
        err.isSuccess = false
        return err
    }
}

export const deleteOrganisationAddress = async (
    organisationId: string,
    addressId: string,
) => {
    try {
        const response = await deleteRequest<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/addresses/${addressId}`,
        })

        if (response) {
            Toast({
                header: 'Organisation Address',
                message: `Address: ${addressId} successfully deleted.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IAccounts>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const deleteOrganisationContacts = async (
    organisationId: string,
    contactId: string,
) => {
    try {
        const response = await deleteRequest<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/contacts/${contactId}`,
        })
        if (response) {
            Toast({
                header: 'Organisation Contact',
                message: `Contact: ${contactId} successfully deleted.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IAccounts>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const deleteOrganisationNotes = async (
    organisationId: string,
    noteId: string,
) => {
    try {
        const response = await deleteRequest<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/notes/${noteId}`,
        })
        if (response) {
            Toast({
                header: 'Organisation Note',
                message: `Note: ${noteId} successfully deleted.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IAccounts>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const deleteOrganisationAccounts = async (
    organisationId: string,
    accountId: string,
) => {
    try {
        const response = await deleteRequest<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/accounts/${accountId}`,
        })
        if (response) {
            Toast({
                header: 'Organisation Account',
                message: `Account: ${accountId} successfully deleted.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IAccounts>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}

export const deleteOrganisationEDoc = async (
    organisationId: string,
    eDocId: string,
) => {
    try {
        const response = await deleteRequest<IResponseData<any>, string>({
            endpoint: `${organisationEndpoints.organisations}/${organisationId}/edocs/${eDocId}`,
        })
        if (response) {
            Toast({
                header: 'Organisation EDoc',
                message: `EDoc: ${eDocId} successfully deleted.`,
                type: 'success',
            })
        }
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IAccounts>
        err.isSuccess = false
        Toast({
            header: 'Failed!',
            message: 'Failed',
            type: 'error',
        })
        return err
    }
}
