import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useFormik } from 'formik'
import { useEffect } from 'react'

// Hold - Still waiting API filter quotes
interface IContactFilter
    extends Pick<
        IFetchParams,
        'job_category' | 'organisation' | 'fileExportType'
    > {}

export const initialBookingFilter: IContactFilter = {
    fileExportType: 'xls',
    organisation: [],
    job_category: [],
}

const useContactFilter = (
    onFilterSubmit: (data: IFetchParams) => void,
    isGenerateReport?: boolean,
) => {
    const dropdown = useDropdown()
    const formik = useFormik<IContactFilter>({
        initialValues: initialBookingFilter,
        onSubmit: (values) => {
            generateFilter(values)
        },
    })

    useEffect(() => {
        dropdown.getJobCategories()
        dropdown.getOrganisations()
    }, [])

    const generateFilter = async (values: IContactFilter) => {
        let params: IFetchParams = { ...values, size: 50, page: 1 }
        if (isGenerateReport) params = { ...values, size: 2000, page: 1 }
        onFilterSubmit(params)
    }

    return { formik, dropdown }
}

export default useContactFilter
