var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import './pills.style.css';
var Pills = function (_a) {
    var items = _a.items, onChange = _a.onChange, _b = _a.isRounded, isRounded = _b === void 0 ? true : _b, _c = _a.variant, variant = _c === void 0 ? 'brand-white' : _c, _d = _a.containerClass, containerClass = _d === void 0 ? '' : _d, _e = _a.itemClass, itemClass = _e === void 0 ? '' : _e;
    var _f = useState(0), activeIndex = _f[0], setActiveIndex = _f[1];
    var roundedClass = isRounded ? 'rounded-full' : 'rounded';
    return (_jsx("div", __assign({ className: "pills ".concat(containerClass), style: { scrollbarWidth: 'none' } }, { children: items === null || items === void 0 ? void 0 : items.map(function (x, idx) {
            var isActive = activeIndex === idx ? "".concat(variant, "-active") : variant;
            return (_jsx("div", __assign({ className: " pills-item ".concat(roundedClass, " ").concat(isActive, " ").concat(itemClass), onClick: function () {
                    setActiveIndex(idx);
                    onChange(x);
                } }, { children: x === null || x === void 0 ? void 0 : x.label }), "pill-".concat(idx)));
        }) })));
};
export default Pills;
