import axios, { AxiosResponse } from 'axios'
import { IBlob, IFetch, IFetchOptions, IPost, IRoute } from './common.interface'
import { useEffect } from 'react'
import { removeSession } from 'pages/login/login.slice'
import { getConfig } from 'config/config.service'
import { loglinesRoutes } from './routes/loglines.routes'
import { Toast } from '@components/toast/toast.component'

const getBaseUrl = () => {
    const config = getConfig()
    return config.apiUrl
}

export const fetch = async <D, T>(props: IFetch<T>) => {
    try {
        const response: AxiosResponse<D> = await axios.get(
            getBaseUrl() + props.endpoint,
            {
                params: props.params,
            },
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            removeSession()
            throw new Error('Unauthorized Access: Please log in')
        } else {
            throw new Error('No Data Available')
        }
    }
}

export const fetchOptions = async <D>(props: IFetchOptions) => {
    try {
        const response: AxiosResponse<D> = await axios.get(
            getBaseUrl() + props.endpoint,
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            removeSession()
            throw new Error('Unauthorized Access: Please log in')
        } else {
            throw new Error('No Data Available')
        }
    }
}

export const post = async <D, T>(props: IPost<T>) => {
    const response: AxiosResponse<D> = await axios.post(
        getBaseUrl() + props.endpoint,
        props?.payload,
        {
            headers: {
                'Content-Type': props?.isFormData
                    ? 'multipart/form-data'
                    : 'application/json',
            },
            params: props?.params,
        },
    )

    if (response.status === 401) {
        removeSession()
    }
    return response.data
}

export const postAllHttp = async <D, T>(props: IPost<T>) => {
    const response: AxiosResponse<D> = await axios.post(
        getBaseUrl() + props.endpoint,
        props?.payload,
        {
            headers: {
                'Content-Type': props?.isFormData
                    ? 'multipart/form-data'
                    : 'application/json',
            },
            params: props?.params,
        },
    )

    if (response.status === 401) {
        removeSession()
    }
    return response
}

export const put = async <D, T>(props: IPost<T>) => {
    const response: AxiosResponse<D> = await axios.put(
        getBaseUrl() + props.endpoint,
        props?.payload,
    )

    if (response.status === 401) {
        removeSession()
    }
    return response.data
}

export const deleteRequest = async <D, T>(props: IFetch<T>) => {
    const response: AxiosResponse<D> = await axios.delete(
        getBaseUrl() + props.endpoint,
        { params: props.params },
    )

    if (response.status === 401) {
        removeSession()
    }
    return response.data
}

export const fecthBlob = async <D, T>(props: IBlob<T>) => {
    try {
        const response: AxiosResponse<D> = await axios.get(
            getBaseUrl() + props.endpoint,
            {
                params: props?.params,
                responseType: 'blob',
            },
        )
        return response
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            removeSession()
            throw new Error('Unauthorized Access: Please log in')
        } else {
            throw new Error('No Data Available')
        }
    }
}

export const getModuleParentId = () => {
    let parentIds: string[] = []

    loglinesRoutes.forEach((dt) => {
        if (!dt.parentId) {
            return
        }

        parentIds.push(dt.parentId)

        let sub: IRoute[] | undefined = dt.sub
        if (sub) {
            sub?.forEach((dtSub) => {
                if (!dtSub.parentId) {
                    return
                }
                parentIds.push(dtSub.parentId)
            })
        }
    })
    return parentIds
}

/**
 * Hook that alerts clicks outside of the passed refCombine words with spaces and change the first letter of each word to uppercase
 */
export function useOutsideClickHandling(ref: any, func: () => void) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                func()
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}

export const getRoutesByClient = () => {
    // custom function to get route by client
    // not implemented on standalone project
}

export const errorAxiosToast = (
    error: unknown,
    { header, message }: { header: string; message: string },
) => {
    if (error instanceof Error) {
        Toast({
            header: 'Network Error',
            message: `${error.message}`,
            type: 'error',
        })
    } else {
        Toast({
            header: header,
            message: message,
            type: 'error',
        })
    }
    return undefined
}

export const priceFormat = (price: number) => {
    return price.toLocaleString('en-US', {
        minimumFractionDigits: price % 1 !== 0 ? 2 : 0,
        maximumFractionDigits: 2,
    })
}
