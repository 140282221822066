import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import { useEDocumentationListService } from './eDocumentation.service'

const EDocumentationListForm = () => {
    const { documentTypes } = useEDocumentationListService()
    return (
        <div>
            <FormDropdown
                label="Document Type"
                name="document_type.code"
                placeholder="Select Contact Type"
                options={documentTypes}
            />
            <FormTextarea
                label="Description (Optional)"
                placeholder="Enter Description"
                name="description"
                parentDivClassName="col-span-2"
            />

            {/* <div className="border-b"></div>
            <div className="p-3 overflow-auto h-full">
                <PreviewDocument fileUrl={'www.google.com'} />
            </div> */}
        </div>
    )
}

export default EDocumentationListForm
