export var getStatusClass = function (status) {
    var retClass;
    switch (status) {
        case 'Open':
        case 'Confirmed':
            retClass = 'themes-status-green';
            break;
        case 'In Progress':
            retClass = 'themes-status-yellow';
            break;
        case 'Canceled':
            retClass = 'themes-status-red';
            break;
        default:
            retClass = 'themes-status-gray';
            break;
    }
    return retClass;
};
