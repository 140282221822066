var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useState } from "react";
import "./toggle-switch.style.css";
var ToggleSwitch = function (_a) {
    var initialOn = _a.initialOn, _b = _a.disabled, disabled = _b === void 0 ? false : _b, label = _a.label, onClick = _a.onClick;
    var _c = useState(initialOn), isOn = _c[0], setIsOn = _c[1];
    var disabledClass = disabled ? "opacity-70" : "";
    return (_jsxs("div", __assign({ className: "flex justify-between items-center w-full ".concat(disabledClass) }, { children: [label && _jsx("div", { children: label }), _jsxs("label", __assign({ className: "switch" }, { children: [_jsx("input", { type: "checkbox", checked: initialOn, onClick: function () {
                            setIsOn(!isOn);
                            onClick && onClick();
                        }, disabled: disabled }), _jsx("span", { className: "slider round" })] }))] })));
};
export default ToggleSwitch;
