import { useState } from 'react';
export var useModal = function () {
    var _a = useState(false), isModalOpen = _a[0], setModalOpen = _a[1];
    var openModalHandling = function () { return setModalOpen(true); };
    var closeModalHandling = function () { return setModalOpen(false); };
    var toggleModalHandling = function () { return setModalOpen(!isModalOpen); };
    return {
        isModalOpen: isModalOpen,
        openModalHandling: openModalHandling,
        closeModalHandling: closeModalHandling,
        toggleModalHandling: toggleModalHandling,
    };
};
