import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { ShipmentType } from '../../shipments.interface'

// Hold - Still waiting API filter quotes
interface IShipmentFilter
    extends Pick<
        IFetchParams,
        | 'consignee'
        | 'origin'
        | 'destination'
        | 'carrier'
        | 'container_mode'
        | 'container_type'
        | 'warehouse'
        | 'created_by'
        | 'fileExportType'
        | 'etd_end'
        | 'etd_start'
    > {}

export const initialShipmentFilter: IShipmentFilter = {
    fileExportType: 'xls',
    consignee: [],
    origin: [],
    destination: [],
    carrier: [],
    container_mode: [],
    container_type: [],
    warehouse: [],
    created_by: [],
}

const useShipmentsFilter = (
    onFilterSubmit: (data: IFetchParams) => void,
    isGenerateReport: boolean = false,
    shipmentType: ShipmentType = 'AIR',
) => {
    const dropdown = useDropdown()
    const formik = useFormik<IShipmentFilter>({
        initialValues: initialShipmentFilter,
        onSubmit: (values) => {
            generateFilter(values)
        },
    })

    useEffect(() => {
        dropdown.getConsigneeOrganisations()
        dropdown.getContainerModes(shipmentType)
        dropdown.getOrganisationCarrier()
        dropdown.getOrganisationWarehouse()
        dropdown.getAllContainerModes()
        dropdown.getUnlocos()
    }, [])

    const generateFilter = async (values: IShipmentFilter) => {
        let params: IFetchParams = { ...values, size: 50, page: 1 }
        if (isGenerateReport) params = { ...values, size: 2000, page: 1 }
        onFilterSubmit(params)
    }

    return { formik, dropdown }
}

export default useShipmentsFilter
