var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage, useField } from 'formik';
import ToggleSwitch from '@components/toggle-switch/toggle-switch.component'; // Adjust the path as needed
var FormToggleSwitch = function (_a) {
    var label = _a.label, _b = _a.disabled, disabled = _b === void 0 ? false : _b, handleClick = _a.handleClick, props = __rest(_a, ["label", "disabled", "handleClick"]);
    var _c = useField(props.name), field = _c[0], meta = _c[1], helpers = _c[2];
    var isError = meta.touched && meta.error;
    var handleToggle = function () {
        var newValue = !field.value;
        helpers.setValue(newValue);
        handleClick && handleClick();
    };
    return (_jsxs("div", __assign({ className: "mb-3" }, { children: [_jsx(ToggleSwitch, { initialOn: field.value, label: label, disabled: disabled, onClick: handleToggle }), isError && (_jsx("div", __assign({ className: "ml-small-x text-size-XS themes-text-red-v3" }, { children: _jsx(ErrorMessage, { name: props.name, component: "p" }) })))] })));
};
export default FormToggleSwitch;
