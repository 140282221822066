import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import useDropdown from 'common/dropdown/dropdown.service'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { ITransportMode } from 'repository/interface/transport-mode.interface'
import useOverlay from '@components/overlay/overlay.service'
import moment from 'moment'
import { IContact } from 'repository/interface/contact.interface'
import {
    fetchOrganisations,
    fetchOrganisationsAddress,
} from 'repository/dropdown.repository'
import { IAddress } from 'repository/interface/address.interface'
import {
    IFormCreateShipment,
    IFormCreateShipmentPayload,
    useShipmentCreateValidation,
} from 'form-validation/shipment-create.validation'
import { submitShipment } from 'repository/shipment.repository'
import { ShipmentType } from 'pages/shipments/shipments.interface'
import { IOrganisation } from 'form-validation/form-contact.validation'
import { IResponseData } from 'common/common.interface'

const useCreateShipment = (shipmentType: ShipmentType) => {
    const navigate = useNavigate()
    const serviceRatesOverlay = useOverlay()
    const dropdownService = useDropdown()

    // states
    const [selectedTransport, setSelectedTransport] =
        useState<IDropdownItem<ITransportMode> | null>(null)
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)
    const [shipperAddress, setShipperAddres] = useState<
        IDropdownItem<IAddress>[]
    >([])
    const [consigneeAddress, setConsigneeAddres] = useState<
        IDropdownItem<IAddress>[]
    >([])
    const [agents, setAgents] = useState<IDropdownItem<IOrganisation>[]>([])
    const [notify, setNotify] = useState<IDropdownItem<IOrganisation>[]>([])

    // formik
    const shipmentValidation = useShipmentCreateValidation()
    const formik = useFormik<IFormCreateShipment>({
        validationSchema: shipmentValidation.schema,
        validateOnChange: true,
        initialValues: shipmentValidation.generateInitialValue(shipmentType),
        onSubmit: (values) => {
            submitForm(values)
        },
    })

    const submitForm = async (value: IFormCreateShipment) => {
        setIsSubmitLoading(true)
        const payload: IFormCreateShipmentPayload = {
            transport_mode: value.transport_mode,
            shipper: {
                code: value.shipper,
            },
            shipper_address: {
                code: value.shipper_address,
            },
            include_pick_up: value.include_pick_up,
            consignee: {
                code: value.consignee,
            },
            consignee_address: {
                code: value.consignee_address,
            },
            with_delivery: false,
            etd: moment(value.etd).format('YYYY-MM-DD HH:mm:ss'),
            eta: moment(value.etd).format('YYYY-MM-DD HH:mm:ss'),
            origin_location: {
                code: value.origin_location,
            },
            destination_location: {
                code: value.destination_location,
            },
            agent: value.agent?.code ? { code: value.agent.code } : undefined,
            notify_contacts:
                value.notify_contacts.length < 1 || undefined || null
                    ? undefined
                    : value.notify_contacts.map((d) => {
                          return { code: d.code ?? '' }
                      }),
            status: value.status,
        }

        try {
            let response: IResponseData<any> | undefined
            response = await submitShipment(payload)

            setIsSubmitLoading(false)
            if (response) {
                const shipmenCode = response.message ?? ''
                navigate('/freight-forwarding/details/' + shipmenCode)
                formik.resetForm()
            }
        } catch (error) {
            setIsSubmitLoading(false)
            console.log(error)
        }
    }

    const addNewNotify = () => {
        const notifyContactData = formik.values.notify_contacts
        const newContact: IOrganisation = {
            code: '',
            name: '',
        }

        formik.setValues((state) => ({
            ...state,
            notify_contacts: [...notifyContactData, newContact],
        }))
    }

    const deleteNotifyByIndex = (index: number) => {
        const notifyData = formik.values.notify_contacts
        const updateData = notifyData.filter((d, i) => index !== i)
        formik.setValues((state) => ({ ...state, notify_contacts: updateData }))
    }

    const addressHandling = async (
        selectedCode: string,
        type: 'shipper' | 'consignee',
    ) => {
        const response = await fetchOrganisationsAddress({
            organisationCode: selectedCode,
        })
        if (!response) return

        const dropdown: IDropdownItem<IAddress>[] = response.data.map((d) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        if (type === 'consignee') {
            setConsigneeAddres(dropdown)
        } else {
            setShipperAddres(dropdown)
        }
    }

    const getAgent = async () => {
        const response = await fetchOrganisations('agents')
        if (!response) {
            setAgents([])
            return
        }

        const dropdown: IDropdownItem<IOrganisation>[] = response.data.map(
            (d) => {
                return {
                    label: `${d.name}`,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        setAgents(dropdown)
    }

    const getNotifies = async () => {
        const response = await fetchOrganisations('notifies')
        if (!response) {
            setNotify([])
            return
        }

        const dropdown: IDropdownItem<IOrganisation>[] = response.data.map(
            (d) => {
                return {
                    label: `${d.name}`,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        setNotify(dropdown)
    }

    useEffect(() => {
        dropdownService.getUnlocos()
        dropdownService.getShipperOrganisations()
        dropdownService.getConsigneeOrganisations()
        dropdownService.getOrganisations()
        dropdownService.getBookingStatuses()
        getNotifies()
        getAgent()
    }, [])

    useEffect(() => {
        if (!selectedTransport) return
        dropdownService.getContainerModes(selectedTransport.value as string)
    }, [selectedTransport])

    return {
        addressHandling,
        navigate,
        deleteNotifyByIndex,
        addNewNotify,
        setSelectedTransport,
        getNotifies,
        getAgent,
        submitForm,
        formik,
        dropdownService,
        serviceRatesOverlay,
        isSubmitLoading,
        shipperAddress,
        consigneeAddress,
        agents,
        notify,
    }
}

export default useCreateShipment
