import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'
import { IMeasurement } from '@components/measurement-input/measurement-input.interface'

export interface IFormPackLine {
    total_packs: number
    pack_type: string
    product_categories: string[]
    hs_codes: string[]
    length: IMeasurement
    width: IMeasurement
    height: IMeasurement
    weight: IMeasurement
    volume: IMeasurement
    goods_description: string
    in_container: boolean
    pack_id?: string
    pack_line_code?: string
    code?: string
}
export interface IFormPackLinePayload {
    total_packs: number
    pack_type: string
    product_categories: string[]
    hs_codes: string[]
    length?: string
    length_unit?: { code: string }
    width?: string
    width_unit?: { code: string }
    height?: string
    height_unit?: { code: string }
    weight?: string
    weight_unit?: { code: string }
    volume?: string
    volume_unit?: { code: string }
    goods_description?: string
    in_container?: boolean
}

export const usePackLinesValidation = () => {
    const schema = Yup.object<IFormPackLine>().shape({
        total_packs: Yup.number()
            .required(requiredMessage('Total Packs'))
            .min(1, 'Total Packs must be at least 1'),
        pack_type: Yup.string().required(requiredMessage('Pack Type')),
        product_categories: Yup.array().of(Yup.string()),
        hs_codes: Yup.array().of(
            Yup.string().required(requiredMessage('HS Code')),
        ),
        length: Yup.object<IMeasurement>().shape({
            qty: Yup.string().nullable(),
            unit: Yup.string().nullable(),
        }),
        width: Yup.object<IMeasurement>().shape({
            qty: Yup.string().nullable(),
            unit: Yup.string().nullable(),
        }),
        height: Yup.object<IMeasurement>().shape({
            qty: Yup.string().nullable(),
            unit: Yup.string().nullable(),
        }),
        weight: Yup.object<IMeasurement>().shape({
            qty: Yup.string().nullable(),
            unit: Yup.string().nullable(),
        }),
        volume: Yup.object<IMeasurement>().shape({
            qty: Yup.string().nullable(),
            unit: Yup.string().nullable(),
        }),
        goods_description: Yup.string().max(
            255,
            'Goods Description must be at most 255 characters',
        ),
        in_container: Yup.boolean().required(requiredMessage('In Container')),
        pack_id: Yup.string(),
    })

    const initialValue: IFormPackLine = {
        pack_id: '(Auto Generated)',
        total_packs: 0,
        pack_type: '',
        product_categories: [],
        hs_codes: [],
        goods_description: '',
        in_container: false,
        length: { qty: '0' },
        width: { qty: '0' },
        height: { qty: '0' },
        volume: { qty: '0' },
        weight: { qty: '0' },
        pack_line_code: '',
    }

    return {
        initialValue,
        schema,
    }
}
