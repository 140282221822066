/* eslint-disable no-unused-vars */
import { createOrgValidation } from 'form-validation/create-organisation.validation'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'

import {
    IFormikOrgDetail,
    Ilabel,
} from '../organisation-details/organisation-details.interface'
import {
    getCities,
    getCountries,
    getStates,
    getUnlocodes,
    postCreateOrganisation,
} from '../organisation.repository'
import { useDispatch, useSelector } from 'react-redux'
import {
    citiesDataSelector,
    citiesIsLoadingSelector,
    countriesDataSelector,
    countriesIsLoadingSelector,
    setCitiesData,
    setCountriesData,
    setIsLoadingCities,
    setIsLoadingCountries,
    setIsLoadingStates,
    setIsLoadingUnlocodes,
    setStatesData,
    setUnlocodesData,
    statesDataSelector,
    statesIsLoadingSelector,
    unlocodesDataSelector,
    unlocodesIsLoadingSelector,
} from '../organisation.slice'
import { useEffect } from 'react'

const useCreateOrganisation = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const countries = useSelector(countriesDataSelector)
    const states = useSelector(statesDataSelector)
    const cities = useSelector(citiesDataSelector)
    const unlocodes = useSelector(unlocodesDataSelector)
    const isLoadingCountries = useSelector(countriesIsLoadingSelector)
    const isLoadingStates = useSelector(statesIsLoadingSelector)
    const isLoadingCities = useSelector(citiesIsLoadingSelector)
    const isLoadingUnlocodes = useSelector(unlocodesIsLoadingSelector)

    // formik
    const formik = useFormik<IFormikOrgDetail>({
        validationSchema: createOrgValidation,
        initialValues: {
            code: 'Auto Generate',
            name: '',
            category: '',
            is_shipper: false,
            is_consignee: false,
            is_agent: false,
            is_customer: false,
            is_transport: false,
            is_warehouse: false,
            is_carrier: false,
            is_supplier: false,
            is_notify: false,
            is_vip: false,
            is_services: false,
            is_sales: false,
            is_store: false,
            email_1: '',
            email_2: '',
            phone_no: '',
            mobile_no: '',
            tax_type: '',
            tax_number: '',
            language: '',
            is_org_active: true,

            address_name: '',
            street: '',
            street_2: '',
            city: {
                code: '',
            },
            state: {
                code: '',
            },
            country: {
                code: '',
            },
            post_code: '',
            unlocode: '',
            is_main: false,
            is_active: false,
            consignment_delivery_address: false,
        },
        onSubmit: async (values) => {
            await postCreateOrganisation(values)
            navigate('/organisation')
        },
    })

    // useEffect
    useEffect(() => {
        loadCountries()
        loadUnlocodes()
    }, [])

    useEffect(() => {
        if (formik.values.country.code !== '') {
            loadStates({ country: formik.values.country.code })
        }
    }, [formik.values.country])

    useEffect(() => {
        if (formik.values.state.code !== '') {
            loadCities({ state: formik.values.state.code })
        }
    }, [formik.values.state])

    const loadCountries = async () => {
        try {
            dispatch(setIsLoadingCountries(true))
            const response = await getCountries()
            const transformedData = response.data?.map((type) => ({
                value: type.code,
                label: type.name,
            }))
            dispatch(setCountriesData(transformedData))
            dispatch(setIsLoadingCountries(false))
        } catch (e) {
            dispatch(setIsLoadingCountries(false))
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const loadStates = async ({ country }: { country?: string }) => {
        try {
            dispatch(setIsLoadingStates(true))
            const response = await getStates({ country })
            console.log(response)

            const transformedData = response.data?.map((type) => ({
                value: type.code,
                label: type.name,
            }))
            dispatch(
                setStatesData(
                    response.data?.length === 0
                        ? [{ value: 'NO DATA FOUND', label: 'NO DATA FOUND' }]
                        : transformedData,
                ),
            )
            dispatch(setIsLoadingStates(false))
        } catch (e) {
            dispatch(setIsLoadingStates(false))
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const loadCities = async ({ state }: { state?: string }) => {
        try {
            dispatch(setIsLoadingCities(true))
            const response = await getCities({ state })
            const transformedData = response.data?.map((type) => ({
                value: type.code,
                label: type.name,
            }))
            dispatch(
                setCitiesData(
                    response.data?.length === 0
                        ? [{ value: 'NO DATA FOUND', label: 'NO DATA FOUND' }]
                        : transformedData,
                ),
            )
            dispatch(setIsLoadingCities(false))
        } catch (e) {
            dispatch(setIsLoadingCities(false))
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const loadUnlocodes = async () => {
        try {
            dispatch(setIsLoadingUnlocodes(true))
            const response = await getUnlocodes()
            const transformedData = response.data?.map((type) => ({
                value: type.code,
                label: type.name,
            }))
            dispatch(setUnlocodesData(transformedData))
            dispatch(setIsLoadingUnlocodes(false))
        } catch (e) {
            dispatch(setIsLoadingUnlocodes(false))
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    return {
        formik,
        navigate,
        countries,
        states,
        cities,
        unlocodes,
        isLoadingCities,
        isLoadingCountries,
        isLoadingStates,
        isLoadingUnlocodes,
    }
}

export default useCreateOrganisation
