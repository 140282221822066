var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import './measurement-input.style.css';
function MeasurementInput(_a) {
    var _b;
    var options = _a.options, measurement = _a.measurement, setMeasurement = _a.setMeasurement, defaultOption = _a.defaultOption, _c = _a.required, required = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.disabledVariant, disabledVariant = _e === void 0 ? 'gray' : _e, _f = _a.type, type = _f === void 0 ? 'number' : _f, _g = _a.useUppercaseLabel, useUppercaseLabel = _g === void 0 ? false : _g, props = __rest(_a, ["options", "measurement", "setMeasurement", "defaultOption", "required", "disabled", "disabledVariant", "type", "useUppercaseLabel"]);
    var _h = useState(false), dropdownOpen = _h[0], setDropdownOpen = _h[1];
    var dropdownRef = useRef(null);
    var _j = useState(false), focus = _j[0], setFocus = _j[1];
    var isRequired = required ? 'required' : '';
    var labelFocus = focus || dropdownOpen ? 'themes-text-brand-v5' : '';
    var isDisabled = disabled ? "disabled-".concat(disabledVariant) : '';
    var borderFocus = focus || dropdownOpen ? 'themes-border-brand-v5' : '';
    var cursorDisabled = disabled ? '' : 'cursor-pointer';
    var handleFocusEvent = function () {
        setFocus(true);
    };
    var handleQtyChange = function (e) {
        var value = e.target.value;
        // Jika input kosong, set qty menjadi null atau string kosong
        var data = __assign(__assign({}, measurement), { qty: value === '' ? undefined : value });
        setMeasurement(data);
    };
    var handleUnitChange = function (selectedOption) {
        var value = selectedOption.value;
        var unitLabel = selectedOption.label;
        var data = __assign(__assign({}, measurement), { unit: value, unitLabel: unitLabel });
        setMeasurement(data);
        setDropdownOpen(false);
    };
    var toggleDropdownIcon = function (e) {
        e.stopPropagation(); // Prevents event bubbling
        setDropdownOpen(!dropdownOpen);
    };
    // Close dropdown when clicking outside
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (dropdownRef.current &&
                !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
                setFocus(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        if (defaultOption) {
            var value = defaultOption.value;
            var unitLabel = defaultOption.label;
            var data = __assign(__assign({}, measurement), { unit: value, unitLabel: unitLabel });
            setMeasurement(data);
        }
        return function () {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (_jsxs("div", __assign({ className: "measurement-input-container", ref: dropdownRef }, { children: [_jsxs("div", __assign({ className: "w-full h-inherit " }, { children: [_jsx("label", { children: _jsx("div", __assign({ className: "h-[1px]" }, { children: _jsx("p", __assign({ className: "".concat(isRequired, " ").concat(labelFocus) }, { children: useUppercaseLabel
                                    ? (_b = props.label) === null || _b === void 0 ? void 0 : _b.toUpperCase()
                                    : props.label })) })) }), _jsxs("div", __assign({ className: "flex rounded border ".concat(borderFocus) }, { children: [_jsx("input", { type: type, value: measurement.qty || '', onChange: handleQtyChange, placeholder: (props === null || props === void 0 ? void 0 : props.placeholder) || 'Enter here', className: "".concat(borderFocus, " ").concat(isDisabled), onFocus: handleFocusEvent, disabled: disabled, onBlur: function () {
                                    props.onBlur && props.onBlur();
                                } }), _jsxs("div", __assign({ className: "unit ".concat(isDisabled), onClick: function (data) {
                                    return !disabled && toggleDropdownIcon(data);
                                } }, { children: [_jsx("span", __assign({ className: "border-l pl-2 pr-2" }, { children: !(measurement === null || measurement === void 0 ? void 0 : measurement.unitLabel)
                                            ? 'SELECT'
                                            : measurement === null || measurement === void 0 ? void 0 : measurement.unitLabel })), _jsx("i", { className: "".concat(dropdownOpen ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line', " ").concat(cursorDisabled) })] }))] }))] })), dropdownOpen && (_jsx("div", __assign({ className: "dropdown-list" }, { children: !options.length ? (_jsx("div", __assign({ className: "themes-bg-white h-10 flex items-center justify-center themes-text-gray-v5" }, { children: "No options" }))) : (options.map(function (unitOption) {
                    var activeClass = unitOption.value === (measurement === null || measurement === void 0 ? void 0 : measurement.unit)
                        ? 'themes-bg-brand-v2'
                        : 'themes-bg-white';
                    return (_jsx("div", __assign({ onClick: function () { return handleUnitChange(unitOption); }, className: "".concat(activeClass, " p-2 cursor-pointer rounded themes-bg-hover-gray-v5:hover") }, { children: unitOption.label }), unitOption.value));
                })) })))] })));
}
export default MeasurementInput;
