import { ITabItem } from '@components/tab/tab.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useState } from 'react'
import useContact from '../contact.service'

export const useContactForm = ({
    isCreate,
    isEdit,
}: {
    isCreate: boolean
    isEdit: boolean
}) => {
    const dropdownService = useDropdown()

    const buttonSubmitLabel = isCreate ? 'CREATE CONTACT LIST' : 'SAVE CHANGES'
    const height = 'h-[calc(100vh-328px)]'

    // form related state
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'Details',
        key: 'status',
        value: 'details',
        className: 'gray',
    })

    return {
        isCreate,
        isEdit,
        buttonSubmitLabel,
        height,
        dropdownService,
        tabFilter,
        setTabFilter,
    }
}
