import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IChargeCode } from 'repository/interface/charge-code.interface'
import { ICurrency } from 'repository/interface/currency.interface'
import {
    IOrganisation,
    IOrganisationCategories,
} from 'repository/interface/organisation.interface'
import { IRoles } from 'repository/interface/role.interface'
import { IServiceRate } from 'repository/interface/service-rate.interface'
import { IShippingRate } from 'repository/interface/shipping-rate.interface'
import { ITransportMode } from 'repository/interface/transport-mode.interface'
import { IUnit } from 'repository/interface/unit.interface'
import { IUnlocodes } from 'repository/interface/unlocodes.interface'
import { IBranch } from 'repository/interface/branch.interface'
import { IContactTypes } from 'repository/interface/contact-types.interface'
import { RootState } from 'store'
import {
    ICities,
    ICountries,
    IStates,
} from 'repository/interface/country.interface'
import { IJobCategories } from 'repository/interface/user.interface'
import { IBookingStatus } from 'repository/interface/booking.interface'
import { IPaymentType } from 'repository/interface/payment.interface'
import {
    ICommonDropdown,
    ICommonDropdown2,
} from 'repository/interface/common.interface'
import {
    IConsoleCategory,
    IConsolidation,
} from 'repository/interface/consolidation.interface'
import { IHSCode } from 'repository/interface/hs.-code.interface'

interface IDropdownInitial {
    currency: IDropdownItem<ICurrency>[]
    containerType: IDropdownItem[]
    transportMode: IDropdownItem<ITransportMode>[]
    shippingRates: IDropdownItem<IShippingRate>[]
    serviceRates: IDropdownItem<IServiceRate>[]
    weightUnits: IDropdownItem<IUnit>[]
    lengthUnits: IDropdownItem<IUnit>[]
    volumeUnits: IDropdownItem<IUnit>[]
    containerModes: IDropdownItem<ITransportMode>[]
    allContainerModes: IDropdownItem<ITransportMode>[]
    unlocodes: IDropdownItem<IUnlocodes>[]
    chargeCodes: IDropdownItem<IChargeCode>[]
    organisations: IDropdownItem<IOrganisation>[]
    shipperOrganisations: IDropdownItem<IOrganisation>[]
    consigneeOrganisations: IDropdownItem<IOrganisation>[]
    agentOrganisations: IDropdownItem<IOrganisation>[]
    warehouseOrganisations: IDropdownItem<IOrganisation>[]
    carrierOrganisations: IDropdownItem<IOrganisation>[]
    roles: IDropdownItem<IRoles>[]
    branches: IDropdownItem<IBranch>[]
    countries: IDropdownItem<ICountries>[]
    states: IDropdownItem<IStates>[]
    cities: IDropdownItem<ICities>[]
    jobCategories: IDropdownItem<IJobCategories>[]
    bookingStatuses: IDropdownItem<IBookingStatus>[]
    organisationCategories: IDropdownItem<IOrganisationCategories>[]
    contactTypes: IDropdownItem<IContactTypes>[]
    paymentTypes: IDropdownItem<IPaymentType>[]
    houseBillStatus: IDropdownItem<ICommonDropdown>[]
    consoleType: IDropdownItem<ICommonDropdown>[]
    consoleCategory: IDropdownItem<IConsoleCategory>[]
    consolidation: IDropdownItem<IConsolidation>[]
    shipmentStatus: IDropdownItem<ICommonDropdown>[]
    packTypes: IDropdownItem<ICommonDropdown>[]
    productCategories: IDropdownItem<ICommonDropdown2>[]
    hsCodes: IDropdownItem<IHSCode>[]
    deliveryMode: IDropdownItem<ICommonDropdown>[]
    noteCategory: IDropdownItem<ICommonDropdown>[]
    documentType: IDropdownItem<ICommonDropdown>[]
}

const initialState: IDropdownInitial = {
    currency: [],
    containerType: [],
    transportMode: [],
    shippingRates: [],
    serviceRates: [],
    weightUnits: [],
    lengthUnits: [],
    volumeUnits: [],
    containerModes: [],
    unlocodes: [],
    chargeCodes: [],
    allContainerModes: [],
    organisations: [],
    shipperOrganisations: [],
    consigneeOrganisations: [],
    agentOrganisations: [],
    roles: [],
    branches: [],
    countries: [],
    states: [],
    cities: [],
    jobCategories: [],
    bookingStatuses: [],
    organisationCategories: [],
    contactTypes: [],
    warehouseOrganisations: [],
    carrierOrganisations: [],
    paymentTypes: [],
    houseBillStatus: [],
    consoleType: [],
    consoleCategory: [],
    consolidation: [],
    shipmentStatus: [],
    packTypes: [],
    productCategories: [],
    hsCodes: [],
    deliveryMode: [],
    noteCategory: [],
    documentType: [],
}

const dropdownSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setCurrency: (
            state,
            action: PayloadAction<IDropdownItem<ICurrency>[]>,
        ) => {
            state.currency = action.payload
        },
        setJobCategory: (
            state,
            action: PayloadAction<IDropdownItem<IJobCategories>[]>,
        ) => {
            state.jobCategories = action.payload
        },
        setBranches: (
            state,
            action: PayloadAction<IDropdownItem<IBranch>[]>,
        ) => {
            state.branches = action.payload
        },
        setRoles: (state, action: PayloadAction<IDropdownItem<IRoles>[]>) => {
            state.roles = action.payload
        },
        setContainerType: (state, action: PayloadAction<IDropdownItem[]>) => {
            state.containerType = action.payload
        },
        setTransportMode: (
            state,
            action: PayloadAction<IDropdownItem<ITransportMode>[]>,
        ) => {
            state.transportMode = action.payload
        },
        setShippingRates: (
            state,
            action: PayloadAction<IDropdownItem<IShippingRate>[]>,
        ) => {
            state.shippingRates = action.payload
        },
        setServiceRates: (
            state,
            action: PayloadAction<IDropdownItem<IServiceRate>[]>,
        ) => {
            state.serviceRates = action.payload
        },
        setWeightUnits: (
            state,
            action: PayloadAction<IDropdownItem<IUnit>[]>,
        ) => {
            state.weightUnits = action.payload
        },
        setVolumeUnits: (
            state,
            action: PayloadAction<IDropdownItem<IUnit>[]>,
        ) => {
            state.volumeUnits = action.payload
        },
        setLengthUnits: (
            state,
            action: PayloadAction<IDropdownItem<IUnit>[]>,
        ) => {
            state.lengthUnits = action.payload
        },
        setContainerModes: (
            state,
            action: PayloadAction<IDropdownItem<ITransportMode>[]>,
        ) => {
            state.containerModes = action.payload
        },
        setAllContainerModes: (
            state,
            action: PayloadAction<IDropdownItem<ITransportMode>[]>,
        ) => {
            state.allContainerModes = action.payload
        },
        setUnlocodes: (
            state,
            action: PayloadAction<IDropdownItem<IUnlocodes>[]>,
        ) => {
            state.unlocodes = action.payload
        },
        setChargeCodes: (
            state,
            action: PayloadAction<IDropdownItem<IChargeCode>[]>,
        ) => {
            state.chargeCodes = action.payload
        },
        setOrganisations: (
            state,
            action: PayloadAction<IDropdownItem<IOrganisation>[]>,
        ) => {
            state.organisations = action.payload
        },
        setShipperOrganisations: (
            state,
            action: PayloadAction<IDropdownItem<IOrganisation>[]>,
        ) => {
            state.shipperOrganisations = action.payload
        },
        setConsigneeOrganisations: (
            state,
            action: PayloadAction<IDropdownItem<IOrganisation>[]>,
        ) => {
            state.consigneeOrganisations = action.payload
        },
        setAgentOrganisations: (
            state,
            action: PayloadAction<IDropdownItem<IOrganisation>[]>,
        ) => {
            state.agentOrganisations = action.payload
        },
        setWarehouseOrganisations: (
            state,
            action: PayloadAction<IDropdownItem<IOrganisation>[]>,
        ) => {
            state.warehouseOrganisations = action.payload
        },
        setCarrierOrganisations: (
            state,
            action: PayloadAction<IDropdownItem<IOrganisation>[]>,
        ) => {
            state.carrierOrganisations = action.payload
        },
        setCountries: (
            state,
            action: PayloadAction<IDropdownItem<ICountries>[]>,
        ) => {
            state.countries = action.payload
        },
        setStates: (state, action: PayloadAction<IDropdownItem<IStates>[]>) => {
            state.states = action.payload
        },
        setCities: (state, action: PayloadAction<IDropdownItem<ICities>[]>) => {
            state.cities = action.payload
        },
        setBookingStatuses: (
            state,
            action: PayloadAction<IDropdownItem<IBookingStatus>[]>,
        ) => {
            state.bookingStatuses = action.payload
        },
        setOrganisationCategories: (
            state,
            action: PayloadAction<IDropdownItem<IOrganisationCategories>[]>,
        ) => {
            state.organisationCategories = action.payload
        },
        setContactTypes: (
            state,
            action: PayloadAction<IDropdownItem<IContactTypes>[]>,
        ) => {
            state.contactTypes = action.payload
        },
        setPaymentTypes: (
            state,
            action: PayloadAction<IDropdownItem<IPaymentType>[]>,
        ) => {
            state.paymentTypes = action.payload
        },
        setHouseBillStatus: (
            state,
            action: PayloadAction<IDropdownItem<ICommonDropdown>[]>,
        ) => {
            state.houseBillStatus = action.payload
        },
        setConsoleType: (
            state,
            action: PayloadAction<IDropdownItem<ICommonDropdown>[]>,
        ) => {
            state.consoleType = action.payload
        },
        setConsoleCategory: (
            state,
            action: PayloadAction<IDropdownItem<IConsoleCategory>[]>,
        ) => {
            state.consoleCategory = action.payload
        },
        setConsolidation: (
            state,
            action: PayloadAction<IDropdownItem<IConsolidation>[]>,
        ) => {
            state.consolidation = action.payload
        },
        setShipmentStatus: (
            state,
            action: PayloadAction<IDropdownItem<ICommonDropdown>[]>,
        ) => {
            state.shipmentStatus = action.payload
        },
        setPackTypes: (
            state,
            action: PayloadAction<IDropdownItem<ICommonDropdown>[]>,
        ) => {
            state.packTypes = action.payload
        },
        setProductCategories: (
            state,
            action: PayloadAction<IDropdownItem<ICommonDropdown2>[]>,
        ) => {
            state.productCategories = action.payload
        },
        setHSCodes: (
            state,
            action: PayloadAction<IDropdownItem<IHSCode>[]>,
        ) => {
            state.hsCodes = action.payload
        },
        setDeliveryMode: (
            state,
            action: PayloadAction<IDropdownItem<ICommonDropdown>[]>,
        ) => {
            state.deliveryMode = action.payload
        },
        setNoteCategory: (
            state,
            action: PayloadAction<IDropdownItem<ICommonDropdown>[]>,
        ) => {
            state.noteCategory = action.payload
        },
        setDocumentType: (
            state,
            action: PayloadAction<IDropdownItem<ICommonDropdown>[]>,
        ) => {
            state.documentType = action.payload
        },
    },
})

export const {
    setBranches,
    setContainerType,
    setCurrency,
    setTransportMode,
    setShippingRates,
    setServiceRates,
    setWeightUnits,
    setVolumeUnits,
    setLengthUnits,
    setContainerModes,
    setAllContainerModes,
    setUnlocodes,
    setChargeCodes,
    setOrganisations,
    setShipperOrganisations,
    setConsigneeOrganisations,
    setAgentOrganisations,
    setCountries,
    setStates,
    setCities,
    setRoles,
    setJobCategory,
    setBookingStatuses,
    setOrganisationCategories,
    setContactTypes,
    setWarehouseOrganisations,
    setCarrierOrganisations,
    setPaymentTypes,
    setHouseBillStatus,
    setConsoleCategory,
    setConsoleType,
    setConsolidation,
    setShipmentStatus,
    setPackTypes,
    setHSCodes,
    setProductCategories,
    setDeliveryMode,
    setNoteCategory,
    setDocumentType,
} = dropdownSlice.actions

export const dropdownBranchesSelector = (state: RootState) =>
    state.dropdown.branches
export const dropdownJobCategorySelector = (state: RootState) =>
    state.dropdown.jobCategories
export const dropdownRolesSelector = (state: RootState) => state.dropdown.roles
export const dropdownCurrencySelector = (state: RootState) =>
    state.dropdown.currency
export const dropdownContainerTypeSelector = (state: RootState) =>
    state.dropdown.containerType
export const dropdownTransportModeSelector = (state: RootState) =>
    state.dropdown.transportMode
export const dropdownShippingRatesSelector = (state: RootState) =>
    state.dropdown.shippingRates
export const dropdownServiceRatesSelector = (state: RootState) =>
    state.dropdown.serviceRates
export const dropdownServiceLengthSelector = (state: RootState) =>
    state.dropdown.lengthUnits
export const dropdownServiceVolumeSelector = (state: RootState) =>
    state.dropdown.volumeUnits
export const dropdownServiceWeightSelector = (state: RootState) =>
    state.dropdown.weightUnits
export const dropdownContainerModeSelector = (state: RootState) =>
    state.dropdown.containerModes
export const dropdownAllContainerModeSelector = (state: RootState) =>
    state.dropdown.allContainerModes
export const dropdownUnlocodesSelector = (state: RootState) =>
    state.dropdown.unlocodes
export const dropdownChargeCodesSelector = (state: RootState) =>
    state.dropdown.chargeCodes
export const dropdownOrganisationsSelector = (state: RootState) =>
    state.dropdown.organisations
export const dropdownShipperOrganisationsSelector = (state: RootState) =>
    state.dropdown.shipperOrganisations
export const dropdownConsigneeOrganisationsSelector = (state: RootState) =>
    state.dropdown.consigneeOrganisations
export const dropdownAgentOrganisationsSelector = (state: RootState) =>
    state.dropdown.agentOrganisations
export const dropdownWarehouseOrganisationsSelector = (state: RootState) =>
    state.dropdown.warehouseOrganisations
export const dropdownCarrierOrganisationsSelector = (state: RootState) =>
    state.dropdown.carrierOrganisations
export const dropdownCountriesSelector = (state: RootState) =>
    state.dropdown.countries
export const dropdownStatesSelector = (state: RootState) =>
    state.dropdown.states
export const dropdownCitiesSelector = (state: RootState) =>
    state.dropdown.cities
export const dropdownBookingStatusesSelector = (state: RootState) =>
    state.dropdown.bookingStatuses
export const dropdownOrganisationCategoriesSelector = (state: RootState) =>
    state.dropdown.organisationCategories
export const dropdownContactTypesSelector = (state: RootState) =>
    state.dropdown.contactTypes
export const dropdownPaymentTypeSelector = (state: RootState) =>
    state.dropdown.paymentTypes
export const dropdownHouseBillStatusSelector = (state: RootState) =>
    state.dropdown.houseBillStatus
export const dropdownConsoleType = (state: RootState) =>
    state.dropdown.consoleType
export const dropdownConsoleCategory = (state: RootState) =>
    state.dropdown.consoleCategory
export const dropdownConsolidation = (state: RootState) =>
    state.dropdown.consolidation
export const dropdownShipmentStatus = (state: RootState) =>
    state.dropdown.shipmentStatus
export const dropdownPackTypes = (state: RootState) => state.dropdown.packTypes
export const dropdownProductCategories = (state: RootState) =>
    state.dropdown.productCategories
export const dropdownHsCodes = (state: RootState) => state.dropdown.hsCodes
export const dropdownDeliveryModeSelector = (state: RootState) =>
    state.dropdown.deliveryMode
export const dropdownNoteCategorySelector = (state: RootState) =>
    state.dropdown.noteCategory
export const dropdownDocumentTypeSelector = (state: RootState) =>
    state.dropdown.documentType

export default dropdownSlice.reducer
