import Button from '@components/button/button.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInputFile from '@components/form-input-file/form-input-file.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import LabelData from '@components/label-data/label-data.component'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFormEdocs } from 'form-validation/edocs.validation'
import { FormikProps, FormikProvider } from 'formik'

const EDocsForm = ({
    formik,
    deleteHandling,
    selectFileHandling,
    submitLoading,
    isNew = true,
    deleteLoading,
}: {
    formik: FormikProps<IFormEdocs>
    deleteHandling: () => void
    selectFileHandling: (e: React.ChangeEvent<HTMLInputElement>) => void
    submitLoading: boolean
    deleteLoading: boolean
    isNew: boolean
}) => {
    const dropdownService = useDropdown()
    const { values } = formik
    const headerClass = '!text-size-M'
    const dataClass = 'text-size-S'

    return (
        <div className="flex flex-col h-full">
            <div className={`flex-col overflow-auto h-[calc(100vh-240px)] p-4`}>
                <div
                    className={`${isNew ? 'hidden' : ''} flex gap-4 justify-between sm:flex-wrap mb-3`}
                >
                    <LabelData
                        label="eDocument Details"
                        data={'Show general information of this media.'}
                        dataClass={`${dataClass}`}
                        headerClass={`${headerClass}`}
                        containerClass="w-full"
                    />
                    <Button
                        label="Download"
                        icon="ri-link"
                        onClick={() => {
                            console.log('download')
                        }}
                    />
                </div>
                <div className="border-b mb-3"></div>
                <FormikProvider value={formik}>
                    {isNew ? (
                        <FormInputFile
                            name={'file'}
                            label="Upload a File"
                            onChange={(e) => {
                                selectFileHandling(e)
                            }}
                        />
                    ) : (
                        <></>
                    )}
                    <FormDropdown
                        parentDivClassname="w-full"
                        name="document_type"
                        options={dropdownService.documentTypeData}
                        parentDivClassName="w-full"
                        useUppercaseLabel={true}
                        label="document type"
                        placeholder="Select Document Type"
                    />
                    <FormTextarea
                        name={'description'}
                        label="Description"
                        className="mb-3"
                    />
                </FormikProvider>
            </div>
            <div className="p-4 flex justify-between border-t">
                <Button
                    label="DELETE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-delete-bin-line"
                    variant="red-inverse"
                    onClick={() => {
                        deleteHandling()
                    }}
                    isDisabled={isNew}
                    isLoading={deleteLoading}
                />
                <Button
                    label="SAVE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-save-line"
                    variant="brand"
                    onClick={() => {
                        formik.submitForm()
                    }}
                    isLoading={submitLoading}
                />
            </div>
        </div>
    )
}

export default EDocsForm
