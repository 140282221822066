import { ISTColumn } from '@components/simple-table/simple-table.interface'
import { IContainers } from 'repository/interface/containers.interface'

export const header: ISTColumn<IContainers>[] = [
    {
        accessor: 'container_number',
        label: 'Container No',
        minWidth: 100,
    },
    {
        accessor: 'seal_number',
        label: 'Seal No',
        minWidth: 100,
    },
    {
        accessor: 'container_type',
        label: 'Container Type',
        minWidth: 100,
        customBuild: (dt, a, b, c, rowData) => {
            const name = rowData?.container_type?.name ?? ''
            return <>{`${name}`}</>
        },
    },
    {
        accessor: 'tare_weight',
        label: 'Tare Weight',
        minWidth: 100,
        customBuild: (dt, a, b, c, rowData) => {
            const weight = rowData?.tare_weight ?? ''
            const unit = rowData?.tare_weight_unit?.symbol ?? ''
            return <>{`${weight} ${unit}`}</>
        },
    },
    {
        accessor: 'delivery_mode',
        label: 'Delivery Mode',
        minWidth: 100,
    },
]
