import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { useAppDispatch } from 'store'
import {
    quotesDataSelector,
    quotesFetchParamSelector,
    quotesMetaSelector,
    quotesSelectedTabItemSelector,
    quotesTabItemsSelector,
    setData,
    setFetchParam,
    setSelectedTabItem,
    setSelectedTabItemHelper,
    setTabItems,
} from './quotes.slice'
import { useSelector } from 'react-redux'
import { Toast } from '@components/toast/toast.component'
import { useNavigate } from 'react-router-dom'
import { getQuotation } from 'repository/quotes.repository'
import { IFetchParams } from 'common/common.interface'
import { useModal } from '@components/modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { generateSimpleReport } from '@services/common.export.service'
import { quoteHeaders } from './quotes.static'
import moment from 'moment'
import { ITableColumn } from '@components/table/table.interface'
import useDropdown from 'common/dropdown/dropdown.service'

const useQuotes = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const exportModalService = useModal()
    const filterOverlay = useOverlay()

    // selectors
    const fetchParam = useSelector(quotesFetchParamSelector)
    const quotesData = useSelector(quotesDataSelector)
    const quotesMeta = useSelector(quotesMetaSelector)
    const tabItems = useSelector(quotesTabItemsSelector)
    const selectedTabItem = useSelector(quotesSelectedTabItemSelector)
    const dropdownService = useDropdown()

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [exportLoading, setExportLoading] = useState<boolean>(false)

    useEffect(() => {
        loadData({ ...fetchParam, page: 1, size: 50 })
    }, [fetchParam])

    useEffect(() => {
        dropdownService.getTransportModes()
        dropdownService.getCurrency()
        dropdownService.getWeightUnit()
        dropdownService.getVolumeUnit()
        dropdownService.getServiceRates()
        dropdownService.getUnlocos()
        dropdownService.getChargeCode()
    }, [])

    // Get Data Function
    const loadData = async (parameters: IFetchParams) => {
        const { recountingData, ...params } = parameters
        try {
            setLoading(true)
            const response = await getQuotation(params, true)
            if (!response) {
                setLoading(false)
                dispatch(
                    setData({
                        data: [],
                        meta: {
                            start: 0,
                            size: 0,
                            page: 0,
                            data_count: 0,
                            total_data_count: 0,
                            total_page: 0,
                        },
                    }),
                )
                return
            }
            if (recountingData) {
                getTabItems(params)
            }
            dispatch(setData(response))
            dispatch(
                setSelectedTabItemHelper((params.transport as string) ?? 'all'),
            )
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const exportLoadData = async (filter: IFetchParams): Promise<void> => {
        const params = { ...filter, fileExportType: undefined }
        const fileExportType = filter.fileExportType
        const response = await getQuotation(params, true)
        if (!response) {
            setLoading(false)
            return
        }
        const data = response.data
        const date = moment().format('DD-MM-YYYY HH:mm')
        const generateReport = generateSimpleReport({
            headers: quoteHeaders as ITableColumn[],
            data: data,
            fileName: `quotes_${date}`,
            title: 'Quotation Report',
        })

        if (fileExportType === 'xls') {
            generateReport.exportToXLS()
        } else if (fileExportType === 'pdf') {
            generateReport.exportToPDF({
                jsPDFOptions: {
                    orientation: 'l',
                    unit: 'px',
                    format: 'a4',
                },
                options: {
                    margin: { top: 5, right: 5, bottom: 5, left: 5 }, // Set padding to 5px
                    columnStyles: {
                        0: { cellWidth: 30 }, // 'Custom Column'
                        1: { cellWidth: 100 },
                    },
                },
            })
        }
        return
    }

    const handleSearch = (values: string) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: true,
            size: 50,
            page: 1,
            search: values,
        }
        dispatch(setFetchParam(fParams))
    }

    const handlePagination = (values: number) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: false,
            size: 50,
            page: values,
        }
        loadData(fParams)
    }

    const handleFilter = (values: IFetchParams) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            ...values,
            recountingData: true,
            size: 50,
            page: 1,
            fileExportType: undefined,
        }
        dispatch(setFetchParam(fParams))
    }

    // generate tab Items
    const getTabItems = async (fParams: IFetchParams) => {
        let totalSea = 0
        let totalAir = 0
        let totalData = 0
        const responseData = await getQuotation({
            ...fParams,
            size: 1,
            transport: undefined,
        })
        const responseSea = await getQuotation({
            ...fParams,
            transport: 'SEA',
            size: 1,
        })
        const responseAir = await getQuotation({
            ...fParams,
            transport: 'AIR',
            size: 1,
        })

        if (!responseData || !responseSea || !responseAir) {
            Toast({
                header: 'Error',
                message: 'Failed count tab items data',
                type: 'error',
            })
            return
        }

        totalData = responseData?.meta.total_data_count
        totalAir = responseAir?.meta.total_data_count
        totalSea = responseSea?.meta.total_data_count

        const tItems: ITabItem[] = tabItems.map((d) => {
            let count
            if (d.value === 'all') count = totalData
            else if (d.value === 'SEA') count = totalSea
            else if (d.value === 'AIR') count = totalAir
            else count = 0

            return { ...d, totalData: count }
        })
        dispatch(setTabItems(tItems))
    }

    const setTabFilter = (data: ITabItem) => {
        dispatch(setSelectedTabItem(data))
    }

    return {
        setTabItems,
        quotesMeta,
        quotesData,
        tabItems,
        loading,
        selectedTabItem,
        fetchParam,
        exportModalService,
        filterOverlay,
        exportLoading,
        setExportLoading,
        loadData,
        exportLoadData,
        setTabFilter,
        handleSearch,
        handleFilter,
        navigate,
        handlePagination,
    }
}

export default useQuotes
