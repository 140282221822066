var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage, useField } from 'formik';
import Input from '@components/input/input.component';
import TogglePassword from '@components/toggle-password/toggle-password.component';
import { useEffect, useState } from 'react';
import { formatDecimalNumber } from '@services/common.service';
var FormInput = function (_a) {
    var _b = _a.readonly, readonly = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.type, type = _d === void 0 ? 'text' : _d, _e = _a.parentDivClassName, parentDivClassName = _e === void 0 ? '' : _e, _f = _a.hideError, hideError = _f === void 0 ? false : _f, props = __rest(_a, ["readonly", "disabled", "type", "parentDivClassName", "hideError"]);
    //formik
    var _g = useField(props.name), field = _g[0], meta = _g[1], helpers = _g[2];
    var borderColorClass = meta.error && meta.touched ? 'themes-border-red-v3' : '';
    var isError = !(!meta.error || (meta.error && !meta.touched));
    var errorClassMessage = isError && !hideError ? '' : 'hidden';
    // PasswordVisible
    var _h = useState(false), isPasswordVisible = _h[0], setIsPasswordVisible = _h[1];
    var togglePasswordVisibility = function () {
        setIsPasswordVisible(!isPasswordVisible);
    };
    // use custom value
    var _j = useState(field.value ? field.value.toString() : ''), customValue = _j[0], setCustomValue = _j[1];
    var handleChangeCustomValue = function (e) {
        var rawValue = e.target.value;
        console.log('e', e.target.value);
        setCustomValue(rawValue.toString());
    };
    var removeSuffixValue = function () {
        var cleanedLength = customValue.replace(" ".concat(props.valueSuffix), '');
        setCustomValue(cleanedLength);
    };
    var handleOnBlurCustomValue = function () {
        // remove suffix value
        var cleanedLength = customValue.replace(" ".concat(props.valueSuffix), '');
        // remove string value
        var numericValue = cleanedLength.replace(/[^0-9.]/g, '');
        // handle multiple point
        var parts = numericValue.split('.');
        if (parts.length > 2) {
            numericValue = parts[0] + '.' + parts.slice(1).join('');
        }
        var parsedValue = parseFloat(numericValue) || 0;
        var formattedValue = formatDecimalNumber(parsedValue);
        var suffixValue = props.valueSuffix ? " ".concat(props.valueSuffix) : '';
        helpers.setValue(parsedValue);
        setCustomValue(formattedValue + suffixValue);
    };
    useEffect(function () {
        if (props.valueSuffix || props.useDecimalFormat) {
            var suffixValue = props.valueSuffix && field.value ? " ".concat(props.valueSuffix) : '';
            var updatedValue = field.value
                ? formatDecimalNumber(field === null || field === void 0 ? void 0 : field.value)
                : '';
            setCustomValue(updatedValue + suffixValue);
        }
    }, [field.value]);
    useEffect(function () {
        if (customValue && props.valueSuffix) {
            var numericValue = customValue.replace(/[^0-9.]/g, '');
            var updatedValue = "".concat(numericValue, " ").concat(props === null || props === void 0 ? void 0 : props.valueSuffix);
            setCustomValue(updatedValue);
        }
    }, [props === null || props === void 0 ? void 0 : props.valueSuffix]);
    return (_jsxs("div", __assign({ className: "".concat(parentDivClassName, " mb-3") }, { children: [_jsxs("div", __assign({ className: "flex relative" }, { children: [_jsx(Input, __assign({}, props, { value: props.valueSuffix || props.useDecimalFormat
                            ? customValue
                            : field.value, onChange: function (e) {
                            if (props.valueSuffix || props.useDecimalFormat) {
                                handleChangeCustomValue(e);
                            }
                            else {
                                field.onChange(e);
                            }
                            props.onChange && props.onChange(e);
                        }, disabled: disabled, type: type !== 'password'
                            ? type
                            : isPasswordVisible
                                ? 'text'
                                : 'password', placeholder: props.placeholder, className: "".concat(borderColorClass, " ").concat(props.className), readonly: readonly, onFocus: function () {
                            if (props.valueSuffix) {
                                removeSuffixValue();
                            }
                        }, onBlur: function () {
                            if (props.valueSuffix || props.useDecimalFormat) {
                                handleOnBlurCustomValue();
                            }
                        } })), type === 'password' ? (_jsx(TogglePassword, { isPasswordVisible: isPasswordVisible, toggleVisibility: togglePasswordVisibility })) : (_jsx(_Fragment, {}))] })), _jsx("div", __assign({ className: "ml-small-x text-size-XS themes-text-red-v3 ".concat(errorClassMessage) }, { children: _jsx(ErrorMessage, { name: props.name, component: "p" }) }))] })));
};
export default FormInput;
