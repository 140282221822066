import { FormikProps, FormikProvider } from 'formik'
import FormInput from '@components/form-input/form-input.component'
import Button from '@components/button/button.component'
import { IUseModal } from '@components/modal/modal.service'
import { useContactForm } from './form-contact.service'
import { IFormContact } from 'form-validation/form-contact.validation'
import TabButton from 'pages/contact/components/tab-button/tab-button.component'
import { contactModalTabHeaders } from 'pages/contact/components/contact-modal/contact-modal.static'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import FormToggleSwitch from '@components/form-toggle-switch/form-toggle-switch.component'
import LinkText from '@components/link-text/link-text.component'

const ContactForm = ({
    formik,
    isCreate,
    isEdit,
    submitLoading,
}: {
    formik: FormikProps<IFormContact>
    isCreate: boolean
    isEdit: boolean
    modalService: IUseModal
    submitLoading: boolean
    resetHandling: () => void
}) => {
    const {
        buttonSubmitLabel,
        height,
        tabFilter,
        setTabFilter,
        dropdownService,
    } = useContactForm({
        isCreate,
        isEdit,
    })

    return (
        <div>
            <div className="p-4 pb-1">
                <TabButton
                    containerClassName="overflow-auto"
                    items={[
                        {
                            label: 'Details',
                            key: 'status',
                            value: 'details',
                        },
                        {
                            label: 'Contacts',
                            key: 'status',
                            value: 'contacts',
                        },
                    ]}
                    tabFilter={tabFilter}
                    onChange={setTabFilter}
                />
            </div>
            {tabFilter.value === 'details' && (
                <div className="flex flex-col h-full">
                    <div
                        className={`flex-auto overflow-auto pt-3 ${height} mx-4`}
                    >
                        <FormikProvider value={formik}>
                            {(isEdit || isCreate) && (
                                <FormToggleSwitch
                                    name="status"
                                    label="Active"
                                    disabled={false}
                                />
                            )}
                            <div className="w-full">
                                <FormInput
                                    label="CONTACT NAME"
                                    placeholder="Enter Contact Name"
                                    required={true}
                                    type="text"
                                    name={'name'}
                                    disabled={!isEdit}
                                />
                            </div>
                            <div className="flex gap-2">
                                <div className="w-full">
                                    <FormInput
                                        label="TITLE"
                                        placeholder="Enter Contact Title"
                                        required={true}
                                        type="text"
                                        name={'title'}
                                        disabled={!isEdit}
                                    />
                                </div>
                                <div className="w-full">
                                    <FormInput
                                        label="NICK NAME (OPTIONAL)"
                                        placeholder="Enter Nick Name"
                                        required={false}
                                        type="text"
                                        name={'nick_name'}
                                        disabled={!isEdit}
                                    />
                                </div>
                            </div>
                            <FormDatePicker
                                isRange={false}
                                name="birth_date"
                                placeholder="Enter Born Date"
                                parentDivClassName="mb-4"
                                label="BORN DATE (OPTIONAL)"
                                disable={!isEdit}
                            />
                            <FormInput
                                label="JOB TITLE"
                                placeholder="Enter Job Title"
                                required={true}
                                type="text"
                                name={'job_title'}
                                disabled={!isEdit}
                            />
                            <FormDropdown
                                label="JOB CATEGORY"
                                placeholder="Select Job Category"
                                name="job_category"
                                required={true}
                                disabled={!isEdit}
                                options={dropdownService.jobCategoriesData}
                            />
                            <FormDropdown
                                name="organisations"
                                options={dropdownService.organisationData}
                                label={'RELATED ORGANISATION'}
                                useUppercaseLabel={true}
                                placeholder={'Select Related Organisation'}
                                isClearable={true}
                                isSearchable={true}
                                isMultiSelect={true}
                                disabled={!isEdit}
                                required={true}
                            />
                            <FormInput
                                label="WORKING ADDRESS (OPTIONAL)"
                                placeholder="Enter Working Address"
                                required={false}
                                type="text"
                                name={'working_address'}
                                disabled={!isEdit}
                            />
                        </FormikProvider>
                    </div>
                    {/* Footer */}
                    <div className="w-full flex-1 p-4 border-t">
                        {isEdit && (
                            <Button
                                type="button"
                                label={buttonSubmitLabel}
                                useUpperCase={true}
                                isLoading={submitLoading}
                                className="w-full"
                                variant="brand-inverse"
                                onClick={() => {
                                    formik.submitForm()
                                }}
                            />
                        )}
                        {!isEdit && (
                            <Button
                                type="button"
                                label={buttonSubmitLabel}
                                useUpperCase={true}
                                isLoading={false}
                                className="w-full"
                                variant="brand-inverse"
                                onClick={() => {
                                    formik.submitForm()
                                }}
                                isDisabled={!isEdit}
                            />
                        )}
                    </div>
                </div>
            )}

            {tabFilter.value === 'contacts' && (
                <div>
                    <div className={`flex-auto overflow-auto ${height} mx-4`}>
                        <FormikProvider value={formik}>
                            <div className="flex justify-between items-center pb-2">
                                <div className="my-2 font-semibold text-size-S">
                                    Phone Numbers (Max 3)
                                </div>
                                <div>
                                    {formik.values.phones.length < 3 &&
                                        (isEdit || isCreate) && (
                                            <LinkText
                                                label=" + Add Phone Number"
                                                classname="themes-text-brand-v5 text-size-S font-normal"
                                                onClick={() =>
                                                    formik.setFieldValue(
                                                        'phones',
                                                        [
                                                            ...formik.values
                                                                .phones,
                                                            {
                                                                number: '',
                                                                country_code:
                                                                    '',
                                                            },
                                                        ],
                                                    )
                                                }
                                            />
                                        )}
                                </div>
                            </div>
                            {formik.values.phones?.map((phone: any, index) => (
                                <div
                                    key={index}
                                    className="flex gap-2 items-center"
                                >
                                    <div className="w-[30%]">
                                        <FormDropdown
                                            placeholder="+62"
                                            name={`phones[${index}].country_code`}
                                            options={dropdownService.countriesData.map(
                                                (country) => ({
                                                    ...country,
                                                    label: `${country.additionalData?.phone_code} ${country.additionalData?.name}`,
                                                    value: `${country.additionalData?.phone_code}`,
                                                }),
                                            )}
                                            disabled={!isEdit}
                                            required={true}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <FormInput
                                            placeholder="81198983431"
                                            required={true}
                                            type="text"
                                            name={`phones[${index}].number`}
                                            disabled={!isEdit}
                                        />
                                    </div>
                                    <div className="w-[12%]">
                                        <Button
                                            className="text-size-M themes-border-red-v2 py-2.5 w-full rounded-md -mt-3 hover:bg-red-50"
                                            onClick={() => {
                                                const updatedPhones =
                                                    formik.values.phones.filter(
                                                        (_, i) => i !== index,
                                                    )
                                                formik.setFieldValue(
                                                    'phones',
                                                    updatedPhones,
                                                )
                                            }}
                                            isDisabled={
                                                !isEdit ||
                                                formik.values.phones.length ===
                                                    1
                                            }
                                            icon="ri-delete-bin-line themes-text-red-v3 text-size-M"
                                        ></Button>
                                    </div>
                                </div>
                            ))}

                            <div className="flex justify-between items-center">
                                <div className="my-4 mb-2 font-semibold text-size-S">
                                    Emails (Max 3)
                                </div>
                                <div>
                                    {formik.values.emails.length < 3 &&
                                        (isEdit || isCreate) && (
                                            <LinkText
                                                label="+ Add Email"
                                                classname="themes-text-brand-v5 text-size-S font-normal"
                                                onClick={() =>
                                                    formik.setFieldValue(
                                                        'emails',
                                                        [
                                                            ...formik.values
                                                                .emails,
                                                            '',
                                                        ],
                                                    )
                                                }
                                            />
                                        )}
                                </div>
                            </div>
                            {formik.values.emails?.map((email, index) => (
                                <div key={index} className="flex gap-2">
                                    <div className="w-full">
                                        <FormInput
                                            placeholder="Enter Email"
                                            required={true}
                                            type="text"
                                            name={`emails[${index}]`}
                                            disabled={!isEdit}
                                        />
                                    </div>
                                    <div className="w-[9%]">
                                        <Button
                                            className="text-size-M themes-border-red-v2 py-2.5 w-full rounded-md hover:bg-red-50"
                                            onClick={() => {
                                                const updatedEmails =
                                                    formik.values.emails.filter(
                                                        (_, i) => i !== index,
                                                    )
                                                formik.setFieldValue(
                                                    'emails',
                                                    updatedEmails,
                                                )
                                            }}
                                            isDisabled={
                                                !isEdit ||
                                                formik.values.emails.length ===
                                                    1
                                            }
                                            icon="ri-delete-bin-line themes-text-red-v3 text-size-M"
                                        ></Button>
                                    </div>
                                </div>
                            ))}
                        </FormikProvider>
                    </div>

                    <div className="w-full flex-1 p-4 border-t">
                        <Button
                            type="button"
                            label={buttonSubmitLabel}
                            useUpperCase={true}
                            isLoading={submitLoading}
                            className="w-full"
                            variant="brand-inverse"
                            onClick={() => {
                                formik.submitForm()
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default ContactForm
