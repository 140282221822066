import { IFetchParams, IResponseData } from 'common/common.interface'
import { errorAxiosToast, fetch, post, put } from 'common/common.service'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { IBooking } from './interface/booking.interface'
import { IFormBookingPayload } from 'form-validation/form-booking.validation'
import { Toast } from '@components/toast/toast.component'

export const getBooking = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const endpoint = `${endpoints.bookings}`
        const response = await fetch<IResponseData<IBooking[]>, IFetchParams>({
            endpoint: endpoint,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Bookings Data',
            })
        }
        return undefined
    }
}

export const getBookingDetail = async (code: string) => {
    try {
        const endpoint = `${endpoints.bookings}/${code}`
        const response = await fetch<IResponseData<IBooking>, IFetchParams>({
            endpoint: endpoint,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Fetch',
            message: 'Failed Fetch Booking Detail',
        })
        return undefined
    }
}

export const submitBooking = async (payload: IFormBookingPayload) => {
    try {
        const endpoint = `${endpoints.bookings}`
        await post<IResponseData<any>, IFormBookingPayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'New booking is created',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Submit Booking Data',
        })
        return undefined
    }
}

export const changeBookingStatus = async (
    bookingCode: string,
    status: 'OPEN' | 'CANCELED' | 'CLOSED',
) => {
    try {
        const endpoint = `${endpoints.bookings}/${bookingCode}`
        await put<IResponseData<any>, IFormBookingPayload>({
            endpoint: endpoint,
            payload: { status: status },
        })

        Toast({
            header: 'Success',
            message: `Success update status to '${status}'`,
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed',
            message: `Failed update status to '${status}'`,
        })
        return undefined
    }
}

export const updateBooking = async (
    bookingCode: string,
    payload: IFormBookingPayload,
) => {
    try {
        const endpoint = `${endpoints.bookings}/${bookingCode}`
        await put<IResponseData<any>, IFormBookingPayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Update Success',
            message: 'Booking is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Update',
            message: 'Failed Update Booking Data',
        })
        return undefined
    }
}
