var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage, useField } from 'formik';
import MeasurementInput from '@components/measurement-input/measurement-input.component';
import { formatDecimalNumber } from '@services/common.service';
import { useEffect, useState } from 'react';
function FormMeasurementInput(_a) {
    var _b, _c, _d, _e;
    var _f = _a.options, options = _f === void 0 ? [] : _f, _g = _a.parentDivClassName, parentDivClassName = _g === void 0 ? '' : _g, _h = _a.showErrorMessage, showErrorMessage = _h === void 0 ? true : _h, props = __rest(_a, ["options", "parentDivClassName", "showErrorMessage"]);
    //formik
    var _j = useField(props.name), field = _j[0], meta = _j[1], helpers = _j[2];
    var _k = useState(''), customValue = _k[0], setCustomValue = _k[1];
    var measurement = {
        qty: props.useDecimalFormat ? customValue : (_b = field === null || field === void 0 ? void 0 : field.value) === null || _b === void 0 ? void 0 : _b.qty,
        unit: (_c = field === null || field === void 0 ? void 0 : field.value) === null || _c === void 0 ? void 0 : _c.unit,
        unitLabel: (_d = field === null || field === void 0 ? void 0 : field.value) === null || _d === void 0 ? void 0 : _d.unitLabel,
    };
    var borderColorClass = meta.error && meta.touched ? 'themes-border-red-v3' : '';
    var isError = meta.error && meta.touched;
    var errorClassMessage = isError ? '' : 'hidden';
    var handleOnBlurCustomValue = function () {
        var numericValue = customValue.replace(/[^0-9.]/g, '');
        // handle multiple point
        var parts = numericValue.split('.');
        if (parts.length > 2) {
            numericValue = parts[0] + '.' + parts.slice(1).join('');
        }
        var parsedValue = parseFloat(numericValue) || 0;
        var formattedValue = formatDecimalNumber(parsedValue);
        helpers.setValue(__assign(__assign({}, field.value), { qty: parsedValue }));
        setCustomValue(formattedValue);
    };
    useEffect(function () {
        if (props.useDecimalFormat) {
            var formattedValue = field.value.qty
                ? formatDecimalNumber(field.value.qty)
                : '';
            setCustomValue(formattedValue);
        }
    }, [(_e = field === null || field === void 0 ? void 0 : field.value) === null || _e === void 0 ? void 0 : _e.qty]);
    return (_jsxs("div", __assign({ className: "".concat(parentDivClassName) }, { children: [_jsx(MeasurementInput, __assign({ options: options, measurement: measurement, value: customValue, setMeasurement: function (change) {
                    if (props.useDecimalFormat) {
                        helpers.setValue(__assign(__assign({}, field.value), { unit: change.unit }));
                        setCustomValue(change.qty || '');
                    }
                    else {
                        helpers.setValue(change);
                    }
                }, borderError: borderColorClass, onBlur: function () {
                    if (props.useDecimalFormat) {
                        handleOnBlurCustomValue();
                    }
                }, type: props.useDecimalFormat ? 'text' : 'number' }, props)), showErrorMessage && (_jsx("div", __assign({ className: "ml-small-x text-size-XS text-red-500 ".concat(errorClassMessage) }, { children: _jsx(ErrorMessage, __assign({ name: props.name, component: "p" }, { children: function (msg) { return _jsx("p", { children: msg }); } })) })))] })));
}
export default FormMeasurementInput;
