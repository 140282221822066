import Button from '@components/button/button.component'
import { FormikProvider } from 'formik'
import FormInput from '@components/form-input/form-input.component'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import FormCheckbox from '@components/form-checkbox/form-checkbox.component'
import { IOrganisation } from 'repository/interface/organisation.interface'
import { copyTextToClipboard } from '@services/common.service'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IUnlocodes } from 'repository/interface/unlocodes.interface'
import { IAddress } from 'repository/interface/address.interface'
import { useNavigate } from 'react-router-dom'
import FreightType from 'pages/bookings/form-booking/component/freight-type.component'
import useCreateShipment from './create-shipment.service'
import { ShipmentType } from 'pages/shipments/shipments.interface'

const CreateShipment = (shipmentType: ShipmentType) => {
    const navigate = useNavigate()
    const {
        formik,
        dropdownService,
        shipperAddress,
        consigneeAddress,
        agents,
        isSubmitLoading,
        addressHandling,
        deleteNotifyByIndex,
        addNewNotify,
    } = useCreateShipment(shipmentType)

    // Formik Values
    const { notify_contacts } = formik.values

    return (
        <div className="container-global sm:overflow-auto content-full-height flex-col w-full">
            <div className="flex justify-between items-center w-full border-b p-4 ">
                CREATE SHIPMENT
            </div>

            <div className={`p-4 flex-col h-[calc(100vh-230px)] overflow-auto`}>
                <FormikProvider value={formik}>
                    <div className="flex mobile:flex-wrap sm:flex-wrap gap-2 sm:gap-0">
                        <FormInput
                            disabled
                            parentDivClassName="w-full "
                            name="booking_id"
                            label="BOOKING ID"
                            placeholder="Input Booking Id"
                            useUppercaseLabel={true}
                            className="w-full"
                        />
                        <FormInput
                            disabled
                            parentDivClassName="w-full"
                            name="created_by"
                            label="Created By"
                            useUppercaseLabel={true}
                        />
                        <FormInput
                            disabled
                            parentDivClassName="w-full"
                            name="created"
                            label="Creation Date"
                            useUppercaseLabel={true}
                        />
                        <FormDropdown
                            options={dropdownService.bookingStatusesData}
                            parentDivClassName="w-full"
                            name="status"
                            useUppercaseLabel={true}
                            className="themes-bg-extra-blue-v3 themes-text-extra-blue-v4"
                        />
                    </div>

                    <div className="flex sm:flex-wrap mobile:flex-wrap gap-2">
                        <div className="w-full">
                            <div className="tex-size-M mb-3">
                                Shipments Detail
                            </div>
                            {/* Big Button */}
                            <div className="flex gap-2 mb-3">
                                <FreightType
                                    icon="ri-plane-line"
                                    isSelected={
                                        formik.getFieldMeta('transport_mode')
                                            .value === 'AIR'
                                    }
                                    subtitle="Air cargo, is the transportation of goods by air using airplanes"
                                    title="Air Freight"
                                    onClick={() => {
                                        formik.setFieldValue(
                                            'transport_mode',
                                            'AIR',
                                        )
                                    }}
                                />
                                <FreightType
                                    icon="ri-ship-line"
                                    isSelected={
                                        formik.getFieldMeta('transport_mode')
                                            .value === 'SEA'
                                    }
                                    subtitle="Involves transporting goods by ships or vessels over oceans"
                                    title="Sea Freight"
                                    onClick={() => {
                                        formik.setFieldValue(
                                            'transport_mode',
                                            'SEA',
                                        )
                                    }}
                                />
                            </div>

                            <div className="flex gap-2">
                                <FormDatePicker
                                    isRange={false}
                                    name="etd"
                                    placeholder="ETD"
                                    parentDivClassName="mb-4"
                                    label="ETD"
                                />
                                <FormDatePicker
                                    isRange={false}
                                    name="eta"
                                    placeholder="ETA"
                                    parentDivClassName="mb-4"
                                    label="ETA"
                                />
                            </div>

                            <div className="flex sm:flex-wrap mobile:flex-wrap gap-2 border-b">
                                <div className="flex flex-col sm:flex-wrap mobile:flex-wrap w-full">
                                    <div className="tex-size-M mb-3">
                                        Origin Location (Optional)
                                    </div>
                                    <FormDropdown
                                        name="origin_location"
                                        placeholder="Select UNLOCO"
                                        label="Origin Location"
                                        useUppercaseLabel={true}
                                        options={dropdownService.unlocodesData}
                                        isSearchable={true}
                                        additionalOnClick={(d) => {
                                            const data =
                                                d as unknown as IDropdownItem<IUnlocodes>
                                            const country =
                                                data.additionalData?.name ?? ''
                                            const state =
                                                data.additionalData?.country ??
                                                ''
                                            formik.setFieldValue(
                                                'origin_location_country',
                                                country,
                                            )
                                            formik.setFieldValue(
                                                'origin_location_state',
                                                state,
                                            )
                                        }}
                                        isClearable={true}
                                    />
                                    <div className="flex gap-2 sm:flex-wrap mobile:flex-wrap">
                                        <FormInput
                                            parentDivClassName="w-full "
                                            name="origin_location_country"
                                            label="Country/Region"
                                            useUppercaseLabel={true}
                                            placeholder="Country/Region"
                                            disabled={true}
                                        />
                                        <FormInput
                                            parentDivClassName="w-full"
                                            name="origin_location_state"
                                            label="State"
                                            useUppercaseLabel={true}
                                            placeholder="state"
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col sm:flex-wrap mobile:flex-wrap w-full">
                                    <div className="tex-size-M mb-3">
                                        Destination Location
                                    </div>
                                    <FormDropdown
                                        name="destination_location"
                                        placeholder="Select UNLOCO"
                                        label="Destination Location"
                                        useUppercaseLabel={true}
                                        options={dropdownService.unlocodesData}
                                        isSearchable={true}
                                        additionalOnClick={(d) => {
                                            const data =
                                                d as unknown as IDropdownItem<IUnlocodes>
                                            const country =
                                                data.additionalData?.name ?? ''
                                            const state =
                                                data.additionalData?.country ??
                                                ''
                                            formik.setFieldValue(
                                                'destination_location_country',
                                                country,
                                            )
                                            formik.setFieldValue(
                                                'destination_location_state',
                                                state,
                                            )
                                        }}
                                    />
                                    <div className="flex gap-2 sm:flex-wrap mobile:flex-wrap">
                                        <FormInput
                                            parentDivClassName="w-full sm:mb-1"
                                            name="destination_location_country"
                                            label="Country/Region"
                                            useUppercaseLabel={true}
                                            placeholder="Country/Region"
                                            disabled={true}
                                        />
                                        <FormInput
                                            parentDivClassName="w-full"
                                            name="destination_location_state"
                                            label="State"
                                            useUppercaseLabel={true}
                                            placeholder="state"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex sm:flex-wrap mobile:flex-wrap gap-2">
                                <div className="flex flex-col sm:flex-wrap mobile:flex-wrap w-full ">
                                    <div className="tex-size-M mb-3 mt-3">
                                        Shipper Info
                                    </div>
                                    <FormDropdown<IOrganisation>
                                        name="shipper"
                                        label="Shipper"
                                        placeholder="Select Shipper"
                                        useUppercaseLabel={true}
                                        options={
                                            dropdownService.shipperOrganisationData
                                        }
                                        parentDivClassName="w-full"
                                        additionalOnClick={(d) => {
                                            const data =
                                                d as unknown as IDropdownItem<IOrganisation>
                                            addressHandling(
                                                data.additionalData?.code ??
                                                    'null',
                                                'shipper',
                                            )
                                            formik.setFieldValue(
                                                'shipper_address_detail',
                                                '',
                                            )
                                        }}
                                    />
                                    <FormDropdown
                                        name="shipper_address"
                                        label="Address"
                                        placeholder="Select Address"
                                        useUppercaseLabel={true}
                                        options={shipperAddress}
                                        parentDivClassName="w-full"
                                        isSearchable={true}
                                        additionalOnClick={(data) => {
                                            const d = (
                                                data as IDropdownItem<IAddress>
                                            ).additionalData
                                            if (!d) return
                                            const label = `${d.street}, ${d.street_2}, ${d.city.name}, ${d.state.name}, ${d.country.name}`
                                            formik.setFieldValue(
                                                'shipper_address_detail',
                                                label,
                                            )
                                        }}
                                    />
                                    <FormTextarea
                                        parentDivClassName="mb-3"
                                        name="shipper_address_detail"
                                        label="ADDRESS"
                                        disabled={true}
                                    />
                                    <FormCheckbox
                                        name={'include_pick_up'}
                                        label="Include Pick Up"
                                    />
                                </div>
                                <div className="flex flex-col sm:flex-wrap mobile:flex-wrap w-full ">
                                    <div className="tex-size-M mb-3 mt-3">
                                        Consignee Info
                                    </div>
                                    <FormDropdown
                                        name="consignee"
                                        label="Consignee"
                                        placeholder="Select Consignee"
                                        useUppercaseLabel={true}
                                        options={
                                            dropdownService.consigneeOrganisationData
                                        }
                                        parentDivClassName="w-full"
                                        additionalOnClick={(data) => {
                                            const d = (
                                                data as IDropdownItem<IOrganisation>
                                            ).additionalData
                                            addressHandling(
                                                d?.code ?? 'null',
                                                'consignee',
                                            )
                                            formik.setFieldValue(
                                                'consignee_address_detail',
                                                '',
                                            )
                                        }}
                                    />
                                    <FormDropdown
                                        name="consignee_address"
                                        label="Address"
                                        placeholder="Select Address"
                                        useUppercaseLabel={true}
                                        options={consigneeAddress}
                                        parentDivClassName="w-full"
                                        additionalOnClick={(data) => {
                                            const d = (
                                                data as IDropdownItem<IAddress>
                                            ).additionalData
                                            if (!d) return
                                            const label = `${d.street}, ${d.street_2}, ${d.city.name}, ${d.state.name}, ${d.country.name}`
                                            formik.setFieldValue(
                                                'consignee_address_detail',
                                                label,
                                            )
                                        }}
                                    />
                                    <FormTextarea
                                        name="consignee_address_detail"
                                        parentDivClassName="mb-3"
                                        label="ADDRESS"
                                        disabled={true}
                                    />

                                    <FormCheckbox
                                        name={'with_delivery'}
                                        label="With Delivery"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Second Div */}
                        <div className="w-full">
                            <div className="text-size-M mb-3">
                                Agent (Optional)
                            </div>

                            <div className="flex flex-wrap gap-2 sm:mb-3">
                                <FormDropdown
                                    name="agent.code"
                                    placeholder="Select Agent"
                                    label="Agent"
                                    parentDivClassName="w-full !mb-0"
                                    useUppercaseLabel={true}
                                    options={agents}
                                    isClearable={true}
                                    additionalOnClick={(dt) => {
                                        const d = (
                                            dt as IDropdownItem<IOrganisation>
                                        ).additionalData
                                        const value = !d
                                            ? {
                                                  code: '',
                                                  name: '',
                                                  email: '',
                                                  phone: '',
                                              }
                                            : {
                                                  code: d.code,
                                                  name: d.name,
                                                  email: d?.email_1 ?? '',
                                                  phone: d?.mobile_no ?? '',
                                              }
                                        formik.setFieldValue('agent', value)
                                    }}
                                />
                                <div className="w-full flex gap-2 mb-2 sm:flex-wrap">
                                    <FormInput
                                        parentDivClassName="w-full !mb-0"
                                        name="agent.email"
                                        id="agent.email"
                                        placeholder="Email"
                                        disabled={true}
                                        icon="ri-file-copy-line"
                                        onIconClick={() => {
                                            copyTextToClipboard('agent.email')
                                        }}
                                    />
                                    <FormInput
                                        parentDivClassName="w-full !mb-0"
                                        name="agent.phone"
                                        id="agent.phone"
                                        placeholder="Phone Number"
                                        disabled={true}
                                        icon="ri-file-copy-line"
                                        onIconClick={() => {
                                            copyTextToClipboard('agent.phone')
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="text-size-M mb-3 flex items-center justify-between">
                                <div>Notify (Optional) </div>
                                <div>
                                    <Button
                                        label="Add More Notify"
                                        onClick={() => {
                                            addNewNotify()
                                        }}
                                        icon="ri-add-line"
                                    />
                                </div>
                            </div>

                            {notify_contacts.map((data, index) => {
                                return (
                                    <div
                                        key={'notify-parent-' + index}
                                        className="flex gap-2"
                                    >
                                        <div
                                            key={'notify-' + index}
                                            className="flex flex-wrap flex-grow gap-2 mb-3"
                                        >
                                            <FormDropdown
                                                name={`notify_contacts[${index}].code`}
                                                key={`notify_contacts[${index}].code`}
                                                placeholder="Select Contact to Notify"
                                                label="Notify Contacts"
                                                parentDivClassName="w-full !mb-0"
                                                useUppercaseLabel={true}
                                                options={agents}
                                                isClearable={true}
                                                additionalOnClick={(dt) => {
                                                    const d = (
                                                        dt as IDropdownItem<IOrganisation>
                                                    ).additionalData
                                                    const value = !d
                                                        ? {
                                                              code: '',
                                                              name: '',
                                                              email: '',
                                                              phone: '',
                                                          }
                                                        : {
                                                              code: d.code,
                                                              name: d.name,
                                                              email: d.email_1,
                                                              phone:
                                                                  d.phone_no ??
                                                                  '',
                                                          }
                                                    formik.setFieldValue(
                                                        `notify_contacts[${index}]`,
                                                        value,
                                                    )
                                                }}
                                            />
                                            <div className="w-full flex gap-2 sm:flex-wrap">
                                                <FormInput
                                                    id={`notify_contacts[${index}].email`}
                                                    key={`notify_contacts[${index}].email`}
                                                    parentDivClassName="w-full !mb-0"
                                                    name={`notify_contacts[${index}].email`}
                                                    placeholder="Email"
                                                    disabled={true}
                                                    icon="ri-file-copy-line"
                                                    onIconClick={() => {
                                                        copyTextToClipboard(
                                                            `notify_contacts[${index}].email`,
                                                        )
                                                    }}
                                                />
                                                <FormInput
                                                    parentDivClassName="w-full !mb-0"
                                                    key={`notify[${index}].phone`}
                                                    name={`notify[${index}].phone`}
                                                    id={`notify[${index}].phone`}
                                                    placeholder="Phone Number"
                                                    disabled={true}
                                                    icon="ri-file-copy-line"
                                                    onIconClick={() => {
                                                        copyTextToClipboard(
                                                            `notify_contacts[${index}].phone`,
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="w-[40px]">
                                            <Button
                                                className="w-full !text-size-M "
                                                icon="ri-delete-bin-line"
                                                variant="red-inverse"
                                                onClick={() => {
                                                    deleteNotifyByIndex(index)
                                                    formik.setFieldValue(
                                                        'serviceTemplateName',
                                                        'Custom',
                                                    )
                                                    formik.setFieldValue(
                                                        'serviceTemplateCode',
                                                        '',
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </FormikProvider>
            </div>

            <div className="flex justify-between border-t p-4">
                <Button
                    label="BACK"
                    onClick={function (): void {
                        navigate('/inquiry')
                    }}
                />

                <Button
                    onClick={() => {
                        formik.submitForm()
                    }}
                    isLoading={isSubmitLoading}
                    label={'SAVE AND CREATE'}
                    variant="brand"
                />
            </div>
        </div>
    )
}

export default CreateShipment
