import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import Checkbox from '@components/checkbox/checkbox.component'
import { IBooking } from 'repository/interface/booking.interface'
import { formatDate } from '@services/common.service'

export const tabItemStatic: ITabItem[] = [
    {
        label: 'Open',
        totalData: 0,
        key: 'status',
        value: 'OPEN',
        className: 'brand',
    },
    {
        label: 'Confirmed',
        totalData: 0,
        key: 'status',
        value: 'CONFIRMED',
        className: 'green',
    },
    {
        label: 'Closed',
        totalData: 0,
        key: 'status',
        value: 'CLOSED',
        className: 'gray',
    },
    {
        label: 'Canceled',
        totalData: 0,
        key: 'status',
        value: 'CANCELED',
        className: 'red',
    },
]

export const bookingSummaryHeader: ITableColumn<IBooking>[] = [
    {
        label: 'Mode',
        accessor: 'transport_mode',
        customBuild: (data) => {
            let iconMode: string
            let labelMode: string
            switch (data) {
                case 'AIR':
                    iconMode = 'ri-plane-line'
                    labelMode = 'AIR'
                    break
                case 'SEA':
                    iconMode = 'ri-ship-line'
                    labelMode = 'SEA'
                    break
                default:
                    iconMode = 'ri-plane-line'
                    labelMode = 'AIR'
            }

            return (
                <div className="flex gap-2">
                    <i className={`${iconMode}`}></i> {labelMode}
                </div>
            )
        },
    },
    {
        label: 'Booking No.',
        accessor: 'booking_id',
        customBuild: (data) => {
            return (
                <div className="flex items-center px-2 rounded themes-bg-brand-v1 themes-text-brand-v5 text-size-S">
                    {data}
                </div>
            )
        },
    },
    {
        label: 'Consignee',
        accessor: 'consignee.name',
    },
    {
        label: 'Shipper',
        accessor: 'shipper.name',
    },
    {
        label: 'Origin',
        accessor: 'origin_location',
        customBuild: (data, row) => {
            const unloco = row?.origin_location?.code ?? ''
            const unlocoName = row?.origin_location?.name ?? ''
            return (
                <>
                    {unloco} - {unlocoName}
                </>
            )
        },
    },
    {
        label: 'Destination',
        accessor: 'destination',
        customBuild: (data, row) => {
            const unloco = row?.destination_location?.code ?? ''
            const unlocoName = row?.destination_location?.name ?? ''
            return (
                <>
                    {unloco} - {unlocoName}
                </>
            )
        },
    },
    {
        label: 'Inc. Pickup',
        accessor: 'incPickup',
        customBuild: (data, rowData) => {
            return (
                <Checkbox
                    onChecked={() => {}}
                    isChecked={rowData?.include_pick_up}
                    disabled
                />
            )
        },
    },
    {
        label: 'Inc. Delivery',
        accessor: 'incDelivery',
        customBuild: (data, rowData) => {
            return (
                <Checkbox
                    onChecked={() => {}}
                    isChecked={rowData?.with_delivery}
                    disabled
                />
            )
        },
    },
    {
        label: 'ETD',
        accessor: 'etd',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
    {
        label: 'Created By',
        accessor: 'createdBy',
    },
    {
        label: 'Creation Date',
        accessor: 'creationDate',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
    {
        label: 'Last Updated',
        accessor: 'lastUpdated',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
]
