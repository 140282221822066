import Button from '@components/button/button.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import LabelData from '@components/label-data/label-data.component'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFormNotes } from 'form-validation/notes.validation'
import { FormikProps, FormikProvider } from 'formik'

const NotesForm = ({
    formik,
    deleteHandling,
    submitLoading,
    isNew = true,
    deleteLoading,
}: {
    formik: FormikProps<IFormNotes>
    deleteHandling: () => void
    submitLoading: boolean
    deleteLoading: boolean
    isNew: boolean
}) => {
    const dropdownService = useDropdown()
    const { values } = formik
    const headerClass = 'themes-text-gray-v4 font-normal text-size-XS'
    const dataClass = 'text-size-S'

    return (
        <div className="flex flex-col h-full">
            <div className={`flex-col overflow-auto h-[calc(100vh-240px)] p-4`}>
                <div className="flex gap-2 sm:flex-wrap mb-3">
                    <LabelData
                        label="Created By"
                        data={values.created_by ?? '-'}
                        dataClass={`${dataClass}`}
                        headerClass={`${headerClass}`}
                        containerClass="w-full"
                    />
                    <LabelData
                        label="Source"
                        data={values.source ?? '-'}
                        dataClass={`${dataClass}`}
                        headerClass={`${headerClass}`}
                        containerClass="w-full"
                    />
                    <LabelData
                        label="Last Updated"
                        data={values.last_updated ?? '-'}
                        dataClass={`${dataClass}`}
                        headerClass={`${headerClass}`}
                        containerClass="w-full"
                    />
                    <LabelData
                        label="Creation Date"
                        data={values.creation_date ?? '-'}
                        dataClass={`${dataClass}`}
                        headerClass={`${headerClass}`}
                        containerClass="w-full"
                    />
                </div>
                <FormikProvider value={formik}>
                    <FormDropdown
                        parentDivClassname="w-full"
                        name="category"
                        options={dropdownService.noteCategoryData}
                        parentDivClassName="w-full"
                        useUppercaseLabel={true}
                        label="category"
                        placeholder="Select Category"
                    />
                    <FormTextarea
                        name={'text'}
                        label="Notes Text"
                        className="mb-3"
                    />
                </FormikProvider>
            </div>
            <div className="p-4 flex justify-between border-t">
                <Button
                    label="DELETE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-delete-bin-line"
                    variant="red-inverse"
                    onClick={() => {
                        deleteHandling()
                    }}
                    isDisabled={isNew}
                    isLoading={deleteLoading}
                />
                <Button
                    label="SAVE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-save-line"
                    variant="brand"
                    onClick={() => {
                        formik.submitForm()
                    }}
                    isLoading={submitLoading}
                />
            </div>
        </div>
    )
}

export default NotesForm
