import { useState, useEffect } from 'react'
import { useFormik } from 'formik'

import {
    createOrganisationContact,
    createOrganisationNote,
    deleteOrganisationNotes,
    getOrganisationContactData,
    getOrganisationNoteData,
    updateOrganisationContact,
    updateOrganisationNote,
} from 'pages/organisation/organisation.repository'
import { useParams } from 'react-router-dom'
import { INotes } from '../../organisation-details.interface'

export const useNoteListService = () => {
    const { id = 'not found' } = useParams()

    const [noteListData, setNoteListData] = useState<INotes[]>([])
    const [noteDetail, setNoteDetail] = useState<INotes>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        loadNoteList()

        return () => {
            setNoteListData([])
        }
    }, [])

    const formikOrgNoteDetail = useFormik<INotes>({
        initialValues: {
            created: '',
            updated: '',
            code: '',
            source: '',
            category: '',
            text: '',
        },
        onSubmit: async (values) => {
            if (id) {
                const res = await updateOrganisationNote(id, values)
                if (res) {
                    loadNoteList()
                }
            }
        },
        enableReinitialize: true,
    })

    const formikOrgCreateNote = useFormik<INotes>({
        initialValues: {
            category: '',
            text: '',
        },
        onSubmit: async (values) => {
            if (id) {
                const res = await createOrganisationNote(id, values)
                if (res) {
                    loadNoteList()
                }
            }
        },
        enableReinitialize: true,
    })

    const deleteNote = async (dataId: string) => {
        try {
            setLoading(true)
            const response = await deleteOrganisationNotes(id, dataId)
            const filterData = noteListData.filter(
                (data) => data.code !== dataId,
            )
            setNoteListData(filterData)

            if (filterData?.length > 0) {
                formikOrgNoteDetail.setValues(filterData[0])
                setNoteDetail(filterData[0])
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const handleDuplicate = async () => {
        if (id && noteDetail) {
            const res = await createOrganisationNote(id, noteDetail)
            if (res) {
                loadNoteList()
            }
        }
    }

    const handleNoteDetail = (data: INotes) => {
        if (data) {
            setNoteDetail(data)
            formikOrgNoteDetail.setValues(data)
        }
    }

    const loadNoteList = async () => {
        try {
            setLoading(true)
            const response = await getOrganisationNoteData(id)
            setNoteListData(response.data as INotes[])

            if (response?.data?.length > 0) {
                formikOrgNoteDetail.setValues(response.data?.[0])
                setNoteDetail(response.data?.[0])
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    return {
        loadNoteList,
        handleNoteDetail,
        formikOrgCreateNote,
        formikOrgNoteDetail,
        loading,
        noteListData,
        noteDetail,
        deleteNote,
        handleDuplicate,
    }
}
