var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { useNavigation } from 'react-day-picker';
export default function DatePickerHeader(_a) {
    var changeDateHandler = _a.changeDateHandler;
    var _b = useNavigation(), currentMonth = _b.currentMonth, nextMonth = _b.nextMonth, previousMonth = _b.previousMonth;
    // set next month
    var nextMonthHandler = function () {
        var next = nextMonth !== null && nextMonth !== void 0 ? nextMonth : currentMonth;
        changeDateHandler(next);
    };
    // set prev month
    var prevMonthHandler = function () {
        var prev = previousMonth !== null && previousMonth !== void 0 ? previousMonth : currentMonth;
        changeDateHandler(prev);
    };
    // set next year
    var nextYearHandler = function () {
        var nextYear = Number(moment(nextMonth).format('YYYY')) + 1;
        var parseToDate = new Date(nextYear, moment(currentMonth).month());
        changeDateHandler(parseToDate);
    };
    // set prev year
    var prevYearHandler = function () {
        var prevYear = Number(moment(previousMonth).format('YYYY')) - 1;
        var parseToDate = new Date(prevYear, moment(currentMonth).month());
        changeDateHandler(parseToDate);
    };
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "flex items-center gap-x-2 mb-2 mt-2 justify-evenly" }, { children: [_jsxs("ul", __assign({ className: "header" }, { children: [_jsx("li", __assign({ onClick: prevMonthHandler }, { children: _jsx("i", { className: "ri-arrow-left-s-line" }) })), _jsx("li", __assign({ className: " font-bold themes-text-brand-v5" }, { children: _jsx("label", { children: moment(currentMonth).format('MMM') }) })), _jsx("li", __assign({ onClick: nextMonthHandler }, { children: _jsx("i", { className: "ri-arrow-right-s-line" }) }))] })), _jsxs("ul", __assign({ className: "header" }, { children: [_jsx("li", __assign({ onClick: prevYearHandler }, { children: _jsx("i", { className: "ri-arrow-left-s-line" }) })), _jsx("li", __assign({ className: "font-bold themes-text-brand-v5" }, { children: _jsx("label", { children: moment(currentMonth).format('YYYY') }) })), _jsx("li", __assign({ onClick: nextYearHandler }, { children: _jsx("i", { className: "ri-arrow-right-s-line" }) }))] }))] })) }));
}
