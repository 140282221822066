import React from 'react'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'

const NotesListForm = () => {
    return (
        <div className="overflow-auto">
            <div className="flex flex-col p-3 gap-3">
                <FormDropdown
                    name="category"
                    label="Note Category"
                    placeholder="Select Note Category"
                    options={[
                        {
                            label: 'FOLLOW_UP',
                            value: 'FOLLOW_UP',
                        },
                    ]}
                />
                <FormTextarea
                    label="Notes Text"
                    name="text"
                    placeholder="Enter Notes Text"
                />
            </div>
        </div>
    )
}

export default NotesListForm
