/* eslint-disable no-unused-vars */
import SimpleTable from '@components/simple-table/simple-table.component'
import useOrganisationDetail from '../../organisation-details.service'
import { IContactList, IEDocs } from '../../organisation-details.interface'
import {
    contactListHeaders,
    eDocumentationHeaders,
} from '../../organisation-details.static'
import { FormikProvider } from 'formik'
import Button from '@components/button/button.component'
import FormToggleSwitch from '@components/form-toggle-switch/form-toggle-switch.component'
import CreateOrEditContactModal from '../create-or-edit-contact-modal.component'
import { useModal } from '@components/modal/modal.service'
import EDocumentationListForm from './eDocumentation-form.component'
import { useEDocumentationListService } from './eDocumentation.service'
import CreateOrEditDocumentationModal from '../create-or-edit-documentation-modal.component'
import RemoveModal from '../remove-modal.component'
import DuplicateModal from '../duplicate-modal.component'

const EDocumentationList = () => {
    const {
        docListData: data,
        formikOrgDocDetail,
        formikOrgCreateDoc,
        docDetail,
        deleteDoc,
        handleDocumentationDetail,
        handleDuplicate,
    } = useEDocumentationListService()

    const { removeContactModalService: removeModalService } =
        useOrganisationDetail()

    const createOrEditContactModal = useModal()
    const duplicateModal = useModal()

    return (
        <div className="flex gap-x-2">
            <div
                className={`${data?.length > 0 ? 'w-3/4' : 'w-full'} h-[calc(100vh-15.5rem)]`}
            >
                <SimpleTable<IEDocs>
                    headers={eDocumentationHeaders}
                    data={data || []}
                    addRowLabel="Add EDocumentation"
                    isAddRow={true}
                    variant="gray"
                    onRowClick={(data) => handleDocumentationDetail(data)}
                    customBuildParam4={() => ({
                        // set details
                        func1: () => {
                            // setIsCreateAddress(false)
                            createOrEditContactModal.openModalHandling()
                        },
                        func2: () => {
                            removeModalService.openModalHandling()
                        },
                    })}
                    addRowClick={() => {
                        createOrEditContactModal.openModalHandling()
                    }}
                />
            </div>
            {data?.length > 0 && (
                <FormikProvider value={formikOrgDocDetail}>
                    <div className="w-1/4 border rounded h-[calc(100vh-15.5rem)] flex flex-col">
                        <div className="flex justify-between items-center sm:flex-col">
                            <div className="p-4">EDocumentation Details</div>
                            <div className="m-2 flex">
                                <i
                                    onClick={() => {
                                        duplicateModal.openModalHandling()
                                    }}
                                    className="border-l-2 cursor-pointer p-1 ri-file-copy-line text-[1.25rem]"
                                ></i>
                                <i
                                    onClick={() => {
                                        removeModalService.openModalHandling()
                                    }}
                                    className="border-l-2 cursor-pointer p-1 ri-delete-bin-line text-[1.25rem] text-red-600"
                                ></i>
                            </div>
                        </div>
                        <div className="border-b"></div>
                        <div className="p-3 overflow-auto">
                            <div className="flex flex-col gap-3 py-3">
                                <EDocumentationListForm />
                            </div>
                            <div className="flex flex-col gap-3">
                                <Button
                                    label="SAVE CHANGES"
                                    variant="brand-inverse"
                                    className="w-full"
                                    icon="ri-save-line"
                                    onClick={() => {
                                        formikOrgDocDetail.submitForm()
                                    }}
                                />
                                <Button
                                    label="DISCARD CHANGES"
                                    variant="red-inverse"
                                    className="w-full"
                                    icon="ri-save-line"
                                    onClick={() => {
                                        formikOrgDocDetail.resetForm({
                                            values: { ...docDetail },
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </FormikProvider>
            )}
            <RemoveModal
                modalService={removeModalService}
                type="removeDoc"
                onClick={() => {
                    const id = docDetail?.code || ''
                    deleteDoc(id)
                }}
                data={docDetail?.code || ''}
            />
            <DuplicateModal
                modalService={duplicateModal}
                type="duplicateDoc"
                onClick={handleDuplicate}
                data={docDetail?.code || ''}
            />

            <CreateOrEditDocumentationModal
                modalService={createOrEditContactModal}
                formik={formikOrgCreateDoc}
                isCreate={true}
            />
        </div>
    )
}

export default EDocumentationList
