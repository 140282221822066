import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { RootState, useAppDispatch } from 'store'
import {
    shipmentDataSelector,
    shipmentFetchParamSelector,
    shipmentMetaSelector,
    shipmentSelectedTabItemSelector,
    shipmentTabItemsSelector,
    setData,
    setFetchParam,
    setSelectedTabItem,
    setTabItems,
} from './shipments.slice'
import { useSelector } from 'react-redux'
import { Toast } from '@components/toast/toast.component'
import { useNavigate } from 'react-router-dom'
import { IFetchParams } from 'common/common.interface'
import { useModal } from '@components/modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { generateSimpleReport } from '@services/common.export.service'
import {
    airShipmentSummaryHeader,
    consolidationShipmentStatusHeader,
    consolidationShipmentSummaryHeader,
    seaShipmentSummaryHeader,
    shipmentStatusHeader,
} from './shipments.static'
import moment from 'moment'
import { getShipments } from 'repository/shipment.repository'
import { ShipmentType } from './shipments.interface'
import { ITableColumn } from '@components/table/table.interface'
import { IShipment } from 'repository/interface/shipment.interface'

const useShipments = (shipmentType: ShipmentType) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const exportModalService = useModal()
    const filterOverlay = useOverlay()

    // selectors
    const fetchParam = useSelector(shipmentFetchParamSelector)
    const shipmentsData = useSelector(shipmentDataSelector)
    const shipmentsMeta = useSelector(shipmentMetaSelector)
    const tabItems = useSelector((state: RootState) =>
        shipmentTabItemsSelector(state, shipmentType),
    )
    const selectedTabItem = useSelector((state: RootState) =>
        shipmentSelectedTabItemSelector(state, shipmentType),
    )

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [exportLoading, setExportLoading] = useState<boolean>(false)

    useEffect(() => {
        loadData({
            ...fetchParam,
            transport:
                shipmentType === 'CONSOLIDATION' ? undefined : shipmentType,
            page: 1,
            size: 50,
        })
    }, [fetchParam])

    // Get Data Function
    const loadData = async (parameters: IFetchParams) => {
        const { recountingData, ...params } = parameters
        try {
            setLoading(true)
            const response = await getShipments(params, true)
            if (!response) {
                setLoading(false)
                dispatch(
                    setData({
                        data: [],
                        meta: {
                            start: 0,
                            size: 0,
                            page: 0,
                            data_count: 0,
                            total_data_count: 0,
                            total_page: 0,
                        },
                    }),
                )
                return
            }
            if (recountingData) {
                getTabItems(params)
            }
            dispatch(setData(response))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const exportLoadData = async (filter: IFetchParams): Promise<void> => {
        const params = { ...filter, fileExportType: undefined }
        const fileExportType = filter.fileExportType
        const response = await getShipments(params, true)
        if (!response) {
            setLoading(false)
            return
        }
        const data = response.data
        const date = moment().format('DD-MM-YYYY HH:mm')
        const generateReport = generateSimpleReport({
            headers: seaShipmentSummaryHeader,
            data: data,
            fileName: `Shipment_${date}`,
            title: 'Shipment Report',
        })

        if (fileExportType === 'xls') {
            generateReport.exportToXLS()
        } else if (fileExportType === 'pdf') {
            generateReport.exportToPDF({
                jsPDFOptions: {
                    orientation: 'l',
                    unit: 'px',
                    format: 'a4',
                },
                options: {
                    margin: { top: 5, right: 5, bottom: 5, left: 5 }, // Set padding to 5px
                    columnStyles: {
                        0: { cellWidth: 30 }, // 'Custom Column'
                        1: { cellWidth: 100 },
                    },
                },
            })
        }
        return
    }

    const handleSearch = (values: string) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: true,
            size: 50,
            page: 1,
            search: values,
        }
        dispatch(setFetchParam(fParams))
    }

    const handlePagination = (values: number) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: false,
            size: 50,
            page: values,
            transport:
                shipmentType === 'CONSOLIDATION' ? undefined : shipmentType,
        }
        loadData(fParams)
    }

    const handleFilter = (values: IFetchParams) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            ...values,
            recountingData: true,
            size: 50,
            page: 1,
            fileExportType: undefined,
        }
        dispatch(setFetchParam(fParams))
    }

    // generate tab Items
    const getTabItems = async (fParams: IFetchParams) => {
        let totalOpen = 0
        let totalClosed = 0
        let totalConfirmed = 0
        let totalCanceled = 0
        let totalDelivered = 0

        const rOpen = await getShipments({
            ...fParams,
            status: 'OPEN',
            size: 1,
        })
        const rConfirmed = await getShipments({
            ...fParams,
            status: 'CONFIRMED',
            size: 1,
        })
        const rDelivered = await getShipments({
            ...fParams,
            status: 'DELIVERED',
            size: 1,
        })
        const rClosed = await getShipments({
            ...fParams,
            status: 'CLOSED',
            size: 1,
        })
        const rCanceled = await getShipments({
            ...fParams,
            status: 'CANCELED',
            size: 1,
        })

        if (!rOpen || !rConfirmed || !rClosed || !rCanceled || !rDelivered) {
            Toast({
                header: 'Error',
                message: 'Failed count tab items data',
                type: 'error',
            })
            return
        }

        totalOpen = rOpen?.meta.total_data_count
        totalClosed = rClosed?.meta.total_data_count
        totalConfirmed = rConfirmed?.meta.total_data_count
        totalCanceled = rCanceled?.meta.total_data_count
        totalDelivered = rDelivered?.meta.total_data_count

        const tItems: ITabItem[] = tabItems.map((d) => {
            let count
            if (d.value === 'OPEN') count = totalOpen
            else if (d.value === 'CLOSED') count = totalClosed
            else if (d.value === 'CANCELED') count = totalCanceled
            else if (d.value === 'CONFIRMED') count = totalConfirmed
            else if (d.value === 'DELIVERED') count = totalDelivered
            else count = 0

            return { ...d, totalData: count }
        })
        dispatch(setTabItems({ type: shipmentType, tabs: tItems }))
    }

    const setTabFilter = (data: ITabItem) => {
        dispatch(setSelectedTabItem({ type: shipmentType, tab: data }))
    }

    const getShipmentColumn = (): ITableColumn<IShipment>[] => {
        if (shipmentType === 'AIR') {
            return airShipmentSummaryHeader
        } else if (shipmentType === 'SEA') {
            return seaShipmentSummaryHeader
        } else if (shipmentType === 'CONSOLIDATION') {
            return consolidationShipmentSummaryHeader
        } else {
            return []
        }
    }
    const getShipmentTab = (): ITabItem[] => {
        if (shipmentType === 'CONSOLIDATION') {
            return consolidationShipmentStatusHeader
        } else {
            return shipmentStatusHeader
        }
    }

    return {
        setTabItems,
        shipmentsMeta,
        shipmentsData,
        tabItems,
        loading,
        selectedTabItem,
        fetchParam,
        exportModalService,
        filterOverlay,
        exportLoading,
        setExportLoading,
        loadData,
        exportLoadData,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        handleFilter,
        getShipmentColumn,
        getShipmentTab,
    }
}

export default useShipments
