import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { useAppDispatch } from 'store'
import {
    bookingDataSelector,
    bookingFetchParamSelector,
    bookingMetaSelector,
    bookingSelectedTabItemSelector,
    bookingTabItemsSelector,
    setData,
    setFetchParam,
    setSelectedTabItem,
    setTabItems,
} from './bookings.slice'
import { useSelector } from 'react-redux'
import { Toast } from '@components/toast/toast.component'
import { useNavigate } from 'react-router-dom'
import { IFetchParams } from 'common/common.interface'
import { useModal } from '@components/modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { generateSimpleReport } from '@services/common.export.service'
import { bookingSummaryHeader } from './bookings.static'
import moment from 'moment'
import { ITableColumn } from '@components/table/table.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { changeBookingStatus, getBooking } from 'repository/booking.repository'
import { IBooking } from 'repository/interface/booking.interface'

const useBookings = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const exportModalService = useModal()
    const filterOverlay = useOverlay()

    // selectors
    const fetchParam = useSelector(bookingFetchParamSelector)
    const bookingsData = useSelector(bookingDataSelector)
    const bookingsMeta = useSelector(bookingMetaSelector)
    const tabItems = useSelector(bookingTabItemsSelector)
    const selectedTabItem = useSelector(bookingSelectedTabItemSelector)

    const dropdownService = useDropdown()

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [exportLoading, setExportLoading] = useState<boolean>(false)

    useEffect(() => {
        loadData({ ...fetchParam, page: 1, size: 50 })
    }, [fetchParam])

    useEffect(() => {
        dropdownService.getUnlocos()
        dropdownService.getChargeCode()
    }, [])

    // Get Data Function
    const loadData = async (parameters: IFetchParams) => {
        const { recountingData, ...params } = parameters
        try {
            setLoading(true)
            const response = await getBooking(params, true)
            if (!response) {
                setLoading(false)
                dispatch(
                    setData({
                        data: [],
                        meta: {
                            start: 0,
                            size: 0,
                            page: 0,
                            data_count: 0,
                            total_data_count: 0,
                            total_page: 0,
                        },
                    }),
                )
                return
            }
            if (recountingData) {
                getTabItems(params)
            }
            dispatch(setData(response))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const exportLoadData = async (filter: IFetchParams): Promise<void> => {
        const params = { ...filter, fileExportType: undefined }
        const fileExportType = filter.fileExportType
        const response = await getBooking(params, true)
        if (!response) {
            setLoading(false)
            return
        }
        const data = response.data
        const date = moment().format('DD-MM-YYYY HH:mm')
        const generateReport = generateSimpleReport({
            headers: bookingSummaryHeader as ITableColumn[],
            data: data,
            fileName: `Booking_${date}`,
            title: 'Booking Report',
        })

        if (fileExportType === 'xls') {
            generateReport.exportToXLS()
        } else if (fileExportType === 'pdf') {
            generateReport.exportToPDF({
                jsPDFOptions: {
                    orientation: 'l',
                    unit: 'px',
                    format: 'a4',
                },
                options: {
                    margin: { top: 5, right: 5, bottom: 5, left: 5 }, // Set padding to 5px
                    columnStyles: {
                        0: { cellWidth: 30 }, // 'Custom Column'
                        1: { cellWidth: 100 },
                    },
                },
            })
        }
        return
    }

    const handleSearch = (values: string) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: true,
            size: 50,
            page: 1,
            search: values,
        }
        dispatch(setFetchParam(fParams))
    }

    const handlePagination = (values: number) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: false,
            size: 50,
            page: values,
        }
        loadData(fParams)
    }

    const handleFilter = (values: IFetchParams) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            ...values,
            recountingData: true,
            size: 50,
            page: 1,
            fileExportType: undefined,
        }
        dispatch(setFetchParam(fParams))
    }

    // generate tab Items
    const getTabItems = async (fParams: IFetchParams) => {
        let totalOpen = 0
        let totalClosed = 0
        let totalConfirmed = 0
        let totalCanceled = 0

        const rOpen = await getBooking({
            ...fParams,
            status: 'OPEN',
            size: 1,
        })
        const rConfirmed = await getBooking({
            ...fParams,
            status: 'CONFIRMED',
            size: 1,
        })
        const rClosed = await getBooking({
            ...fParams,
            status: 'CLOSED',
            size: 1,
        })
        const rCanceled = await getBooking({
            ...fParams,
            status: 'CANCELED',
            size: 1,
        })

        if (!rOpen || !rConfirmed || !rClosed || !rCanceled) {
            Toast({
                header: 'Error',
                message: 'Failed count tab items data',
                type: 'error',
            })
            return
        }

        totalOpen = rOpen?.meta.total_data_count
        totalClosed = rClosed?.meta.total_data_count
        totalConfirmed = rConfirmed?.meta.total_data_count
        totalCanceled = rCanceled?.meta.total_data_count

        const tItems: ITabItem[] = tabItems.map((d) => {
            let count
            if (d.value === 'OPEN') count = totalOpen
            else if (d.value === 'CLOSED') count = totalClosed
            else if (d.value === 'CANCELED') count = totalCanceled
            else if (d.value === 'CONFIRMED') count = totalConfirmed
            else count = 0

            return { ...d, totalData: count }
        })
        dispatch(setTabItems(tItems))
    }

    const setTabFilter = (data: ITabItem) => {
        dispatch(setSelectedTabItem(data))
    }

    const updateStatus = async (
        rowData: IBooking,
        status: 'OPEN' | 'CANCELED' | 'CLOSED',
    ) => {
        if (!rowData?.code) return
        const response = await changeBookingStatus(rowData?.code, status)
        if (response) {
            loadData(fetchParam)
        }
    }

    return {
        setTabItems,
        bookingsMeta,
        bookingsData,
        tabItems,
        loading,
        selectedTabItem,
        fetchParam,
        exportModalService,
        filterOverlay,
        exportLoading,
        setExportLoading,
        loadData,
        exportLoadData,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        handleFilter,
        updateStatus,
    }
}

export default useBookings
