import { useState } from 'react'
import { ITabButton } from './tab-button.interface'
import { useTranslation } from 'react-i18next'
import './tab-button.style.css'

const TabButton = ({
    items,
    selectedItem,
    onChange,
    tabFilter,
    containerClassName = '',
}: ITabButton) => {
    const { t } = useTranslation()
    const getSelectedIndex = items.findIndex(
        (x) => x.value === selectedItem?.value,
    )
    const [activeIndex, setActiveIndex] = useState(
        getSelectedIndex < 0 ? 0 : getSelectedIndex,
    )

    return (
        <div
            className={`${containerClassName} header-status mb-3 mt-1 text-size-S`}
        >
            {items.map((dt, idx) => {
                const isActive = tabFilter
                    ? dt.value === tabFilter.value
                    : activeIndex === idx
                const classActive = isActive
                    ? 'active bg-sky-400 text-white'
                    : 'bg-gray-100'
                const paddingTop = dt.totalData !== undefined ? 'pt-2' : ''
                const className = '' + (dt.className ?? 'p-2')
                const label = dt.labelTranslationCode
                    ? t(dt.labelTranslationCode)
                    : dt.label
                return (
                    <div
                        className={`item ${paddingTop} ${className} ${classActive} p-2 px-3 rounded-full`}
                        key={idx}
                        onClick={() => {
                            setActiveIndex(idx)
                            onChange(dt)
                        }}
                    >
                        {label}
                        {dt.totalData !== undefined && (
                            <span>{dt.totalData}</span>
                        )}
                        <br />
                    </div>
                )
            })}
        </div>
    )
}

export default TabButton
