import Button from '@components/button/button.component'
import { IButton } from '@components/button/button.interface'
import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import { Toast } from '@components/toast/toast.component'
import { IToastProps } from '@components/toast/toast.interface'

const DuplicateModal = ({
    modalService,
    data,
    type,
    onClick,
}: {
    modalService: IUseModal
    data: string
    type:
        | 'nonActiveOrg'
        | 'duplicateAddress'
        | 'duplicateContact'
        | 'duplicateNote'
        | 'duplicateDoc'
        | 'reactivateOrg'
        | 'duplicateAccount'
    onClick: () => void
}) => {
    let header: string = ''
    let message: string = ''
    let propertyButton1: IButton = {
        variant: 'default',
        label: '',
        onClick: () => {},
    }
    let toastProperty: IToastProps

    switch (type) {
        case 'nonActiveOrg':
            header = 'Mark Organisation as Non Active'
            message = `Are you sure you want to mark this ${data}as non-active? This action means you will no longer be able to add this organization to any activity.`
            propertyButton1 = {
                variant: 'red',
                label: 'MARK AS NON ACTIVE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'Organisation Marked as Non-Active',
                message: `Organisation successfully marked as non-active.`,
                type: 'success',
            }
            break
        case 'reactivateOrg':
            header = 'Reactivate Organisation'
            message = `Are you sure you want to reactivate ${data}? This will restore its active status.`
            propertyButton1 = {
                variant: 'brand',
                label: 'REACTIVATE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'Contact Reactivated',
                message: `Organisation/Contact successfully reactivated`,
                type: 'success',
            }
            break
        case 'duplicateAddress':
            header = 'Duplicate Address'
            message = `Are you sure you want to duplicate ${data} address?`
            propertyButton1 = {
                variant: 'brand',
                label: 'DUPLICATE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'Address Duplicated',
                message: `Address: ${data} successfully duplicated`,
                type: 'success',
            }
            break
        case 'duplicateContact':
            header = 'Duplicate Contact'
            message = `Are you sure you want to duplicate ${data} contact?`
            propertyButton1 = {
                variant: 'brand',
                label: 'DUPLICATE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'Contact Duplicated',
                message: `Contact: ${data} successfully duplicated`,
                type: 'success',
            }
            break
        case 'duplicateNote':
            header = 'Duplicate Note'
            message = `Are you sure you want to duplicate ${data} Note?`
            propertyButton1 = {
                variant: 'brand',
                label: 'DUPLICATE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'Note Duplicated',
                message: `Note: ${data} successfully duplicated`,
                type: 'success',
            }
            break
        case 'duplicateDoc':
            header = 'Duplicate EDocs'
            message = `Are you sure you want to duplicate ${data} EDocs?`
            propertyButton1 = {
                variant: 'brand',
                label: 'DUPLICATE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'EDocs Duplicated',
                message: `EDocs: ${data} successfully duplicated`,
                type: 'success',
            }
            break
        case 'duplicateAccount':
            header = 'Duplicate Account'
            message = `Are you sure you want to duplicate ${data} from Account? This action cannot be undone`
            propertyButton1 = {
                variant: 'brand',
                label: 'DUPLICATE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'Account Duplicated',
                message: `Account: ${data} successfully duplicated`,
                type: 'success',
            }
            break
    }

    return (
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/3  px-0">
            <div>
                <div className="px-3 text-size-L font-bold">{header}</div>
                <div className="border-b"></div>
                <div className="p-3">{message}</div>
                <div className="border-b"></div>
                <div className="flex justify-between mt-3 px-3">
                    <Button
                        onClick={() => modalService.closeModalHandling()}
                        label={'BACK'}
                    />
                    <Button
                        onClick={() => {
                            modalService.closeModalHandling()
                            onClick()
                        }}
                        label={propertyButton1.label}
                        variant={propertyButton1.variant}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DuplicateModal
