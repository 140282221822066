import { useState, useEffect } from 'react'
import { useFormik } from 'formik'

import { IContactList } from '../../organisation-details.interface'
import {
    createOrganisationContact,
    deleteOrganisationContacts,
    getOrganisationContactData,
    updateOrganisationContact,
} from 'pages/organisation/organisation.repository'
import { useParams } from 'react-router-dom'

export const useContactListService = () => {
    const { id = 'not found' } = useParams()

    const [contactListData, setContactListData] = useState<IContactList[]>([])
    const [contactDetail, setContactDetail] = useState<IContactList>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        loadContactList()

        return () => {
            setContactListData([])
        }
    }, [])

    const formikOrgContactDetail = useFormik<IContactList>({
        initialValues: {
            code: '',
            name: '',
            first_name: '',
            last_name: '',
            contact_type: {
                code: '',
                name: '',
            },
            job_title: '',
            job_category: {
                code: '',
                name: '',
            },
            phone: '',
            email: '',
            birth_date: '',
            working_address: '',
            is_active: false,
        },
        onSubmit: async (values) => {
            if (id) {
                const payload = {
                    ...values,
                    contact_type: values?.contact_type?.code || '-',
                    first_name: values?.name || '-',
                    last_name: values?.name || '-',
                }
                const res = await updateOrganisationContact(id, payload)
                if (res) {
                    loadContactList()
                }
            }
        },
        enableReinitialize: true,
    })

    const formikOrgCreateContact = useFormik<IContactList>({
        initialValues: {
            code: '',
            name: '',
            first_name: '',
            last_name: '',
            contact_type: {
                code: '',
                name: '',
            },
            job_title: '',
            job_category: {
                code: '',
                name: '',
            },
            phone: '',
            email: '',
            birth_date: '',
            working_address: '',
            is_active: false,
        },
        onSubmit: async (values) => {
            if (id) {
                const payload = {
                    ...values,
                    contact_type: values?.contact_type?.code || '-',
                    first_name: values?.name || '-',
                    last_name: values?.name || '-',
                }
                const res = await createOrganisationContact(id, payload)
                if (res) {
                    loadContactList()
                }
            }
        },
        enableReinitialize: true,
    })

    const handleDuplicate = async () => {
        if (id && contactDetail) {
            const res = await createOrganisationContact(id, contactDetail)
            if (res) {
                loadContactList()
            }
        }
    }

    const handleContactDetail = (data: IContactList) => {
        if (data) {
            setContactDetail(data)
            formikOrgContactDetail.setValues(data)
        }
    }

    const loadContactList = async () => {
        try {
            setLoading(true)
            const response = await getOrganisationContactData(id)
            setContactListData(response.data as IContactList[])

            if (response?.data?.length > 0) {
                formikOrgContactDetail.setValues(response.data?.[0])
                setContactDetail(response.data?.[0])
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const deleteContact = async (dataId: string) => {
        try {
            setLoading(true)
            const response = await deleteOrganisationContacts(id, dataId)
            const filterData = contactListData.filter(
                (data) => data.code !== dataId,
            )
            setContactListData(filterData)

            if (filterData?.length > 0) {
                formikOrgContactDetail.setValues(filterData[0])
                setContactDetail(filterData[0])
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    return {
        contactListData,
        handleContactDetail,
        formikOrgContactDetail,
        formikOrgCreateContact,
        contactDetail,
        deleteContact,
        handleDuplicate,
    }
}
