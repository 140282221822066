import { IRole, IRoleModule } from './interface/role.interface'
import { Toast } from '@components/toast/toast.component'
import json from './static-data/dummy-role.json'
import jsonRoleModule from './static-data/dummy-role-module.json'

export const getRole = () => {
    try {
        const response: IRole[] = json
        return response
    } catch (error: any) {
        console.error(error)
        Toast({
            header: 'Failed!',
            message: 'Failed get data module',
            type: 'error',
        })
        return null
    }
}

export const getRoleModule = () => {
    try {
        const response = jsonRoleModule as unknown as IRoleModule[]
        return response
    } catch (error: any) {
        console.error(error)
        Toast({
            header: 'Failed!',
            message: 'Failed get data role module',
            type: 'error',
        })
        return null
    }
}
