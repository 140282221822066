import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useFormik } from 'formik'
import { useEffect } from 'react'

// Hold - Still waiting API filter quotes
interface IQuotesFilter
    extends Pick<
        IFetchParams,
        | 'container'
        | 'origin'
        | 'destination'
        | 'currency'
        | 'fileExportType'
        | 'dateRange'
        | 'dateFrom'
        | 'dateTo'
        | 'mode'
    > {}

export const initialQuotesFilter: IQuotesFilter = {
    fileExportType: 'xls',
    currency: [],
    container: [],
    destination: [],
    origin: [],
    mode: undefined,
    dateRange: { from: undefined, to: undefined },
    dateFrom: '',
    dateTo: '',
}

const useQuotesFilter = (
    onFilterSubmit: (data: IFetchParams) => void,
    isGenerateReport?: boolean,
) => {
    const dropdown = useDropdown()
    const formik = useFormik<IQuotesFilter>({
        initialValues: initialQuotesFilter,
        onSubmit: (values) => {
            console.log(values)
            generateFilter(values)
        },
    })

    useEffect(() => {
        dropdown.getAllContainerModes()
        dropdown.getCurrency()
        dropdown.getUnlocos()
    }, [])

    const generateFilter = async (values: IQuotesFilter) => {
        let params: IFetchParams = { ...values, size: 50, page: 1 }
        if (isGenerateReport) params = { ...values, size: 2000, page: 1 }
        await onFilterSubmit(params)
    }

    return { formik, dropdown }
}

export default useQuotesFilter
