import { useKeycloakService } from 'config/config.keycloak.service'
import { getConfig } from 'config/config.service'
import { useEffect, useState } from 'react'

export const Login = (): JSX.Element => {
    const config = getConfig()
    const { kcRealm, kcClient, kcUrl } = config
    const keycloakService = useKeycloakService()
    const [triggerOnce, setTriggerOnce] = useState(false)
    useEffect(() => {
        if (
            config.kcClient === '' ||
            config.kcRealm === '' ||
            config.kcUrl === ''
        ) {
            return
        }
        if (triggerOnce === false) {
            keycloakService.login(kcUrl, kcRealm, kcClient)
            setTriggerOnce(true)
        }
    }, [config])

    return <></>
}
export const Logout = (): JSX.Element => {
    const config = getConfig()
    const { kcRealm, kcClient, kcUrl } = config
    const keycloakService = useKeycloakService()
    const [triggerOnce, setTriggerOnce] = useState(false)
    useEffect(() => {
        if (
            config.kcClient === '' ||
            config.kcRealm === '' ||
            config.kcUrl === ''
        ) {
            return
        }
        if (triggerOnce === false) {
            keycloakService.logout(kcUrl, kcRealm, kcClient)
            setTriggerOnce(true)
        }
    }, [config])
    return <></>
}
