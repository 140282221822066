import { useFormik } from 'formik'
import { IOrganisation } from 'pages/organisation/organisation.interface'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    getLanguages,
    getOrganisationCategories,
    getOrganisationDetail,
    getTax,
    updateOrganisationDetail,
} from 'pages/organisation/organisation.repository'
import {
    IFormikOrgDetail,
    IDropdown,
} from '../../organisation-details.interface'
import { useAppDispatch } from 'store'
import {
    organisationDetailDataSelector,
    setOrganisationDetailData,
} from 'pages/organisation/organisation.slice'
import { useSelector } from 'react-redux'

const useDetailsService = () => {
    const { id } = useParams()
    const dispatch = useAppDispatch()

    const [isEdit, setIsEdit] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)

    const [organisationTypesData, setOrganisationTypesData] = useState<
        IDropdown[]
    >([])

    const [languagesData, setLanguagesData] = useState<IDropdown[]>([])
    const [taxData, setTaxData] = useState<IDropdown[]>([])

    const organisationDetailData = useSelector(organisationDetailDataSelector)

    const formikOrgDetails = useFormik<IFormikOrgDetail>({
        initialValues: organisationDetailData,
        onSubmit: (values) => {
            console.log(values)
            submitOrgDetails(values)
        },
        enableReinitialize: true,
    })

    // useEffect
    useEffect(() => {
        if (organisationTypesData.length > 0) return
        loadOrganisationCategories()
        loadLanguages()
        loadTax()
    }, [])

    useEffect(() => {
        if (id) {
            loadOrganisationDetail()
        }
    }, [id])

    // functions

    const loadOrganisationDetail = async () => {
        try {
            setLoading(true)
            const response: any = await getOrganisationDetail(id)
            dispatch(setOrganisationDetailData(response))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const loadOrganisationCategories = async () => {
        try {
            setLoading(true)
            const response = await getOrganisationCategories()
            const transformedData = response.data?.map((type) => ({
                value: type.code,
                label: type.label,
            }))
            setOrganisationTypesData(transformedData)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const loadLanguages = async () => {
        try {
            setLoading(true)
            const response = await getLanguages()
            const transformedData = response.data?.map((type) => ({
                value: type.code,
                label: type.label,
            }))
            setLanguagesData(transformedData)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const loadTax = async () => {
        try {
            setLoading(true)
            const response = await getTax()
            const transformedData = response.data?.map((type) => ({
                value: type.code,
                label: type.label,
            }))
            setTaxData(transformedData)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const submitOrgDetails = async (data: IOrganisation) => {
        if (id) {
            console.log('updating')
            // console.log(data)
            await updateOrganisationDetail(id, data)
            // setIsEdit(false)
        }
    }

    return {
        isEdit,
        setIsEdit,
        formikOrgDetails,
        submitOrgDetails,
        organisationTypesData,
        organisationDetailData,
        languagesData,
        taxData,
    }
}

export default useDetailsService
