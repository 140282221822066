import * as YUP from 'yup'
import { requiredMessage } from './validation-message.static'

export interface IFUser {
    // User Profile
    fullName: string
    // first_name: string
    // last_name: string
    job_title: string
    job_category: string
    // User Account
    email: string
    username: string
    // User Role
    role: string
    branches: string[]
    // User Address
    country?: string
    province?: string
    state?: string
    city?: string
    addressDetail?: string
    // User Contact
    mobile?: string
    emergency_contact_name?: string
    emergency_contact_phone?: string
    phone?: string
    status?: string
}

export interface IFUserPayload extends IFUser {
    role: any
}

export const userFormValidation: YUP.Schema<IFUser> = YUP.object().shape({
    // User Profile
    fullName: YUP.string().required(requiredMessage('Full Name')),
    job_title: YUP.string().required(requiredMessage('Job Title')),
    job_category: YUP.string().required(requiredMessage('Job Category')),
    // User Role
    role: YUP.string().required(requiredMessage('Role')),
    branches: YUP.array()
        .of(YUP.string().required())
        .required(requiredMessage('Site'))
        .default([]),

    // User Address
    country: YUP.string(),
    province: YUP.string(),
    state: YUP.string(),
    city: YUP.string(),
    addressDetail: YUP.string(),
    status: YUP.string(),

    // User Contact
    mobile: YUP.string(),
    phone: YUP.string(),
    emergency_contact_name: YUP.string(),
    emergency_contact_phone: YUP.string(),

    // User Account
    username: YUP.string().required(requiredMessage('User ID')).default(''),
    email: YUP.string()
        .email('Invalid format (eg. loglines@gmail.com)')
        .max(50, '')
        .required(requiredMessage('Email'))
        .default(''),
})

// form user initial data
export const userFormInitial: IFUser = {
    fullName: '',
    job_title: '',
    job_category: '',
    email: '',
    username: '',
    branches: [],
    role: '',
    country: '',
    state: '',
    city: '',
}

// Dummy Edit User
export const userFormUpdate: IFUser = {
    fullName: 'Alice Johnson',
    job_title: 'Logistics Coordinator',
    job_category: 'Logistics',
    email: 'alice.johnson@example.com',
    username: 'aliceJ',
    branches: ['Kuta Site, Bali', 'Sanur Site, Bali'],
    role: 'admin',
}
