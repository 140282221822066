import { ITabItem } from '@components/tab/tab.interface'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IMeta2 } from '@components/pagination/pagination.interface'
import { IFetchParams, IResponseData } from 'common/common.interface'
import { IShipment } from 'repository/interface/shipment.interface'
import {
    consolidationShipmentStatusHeader,
    shipmentStatusHeader,
} from './shipments.static'
import { ShipmentType } from './shipments.interface'

interface IShipmentSlice {
    data: IShipment[]
    meta: IMeta2
    fetchParams: IFetchParams
    exportFetchParams: IFetchParams
    tabItemsAir: ITabItem[]
    tabItemAirSelected: ITabItem
    tabItemsConsolidation: ITabItem[]
    tabItemConsolidationSelected: ITabItem
    tabItemsSea: ITabItem[]
    tabItemSeaSelected: ITabItem
    isLoading: boolean
}

export const initialState: IShipmentSlice = {
    data: [],
    tabItemAirSelected: shipmentStatusHeader[0],
    tabItemsAir: shipmentStatusHeader,
    meta: {
        start: 1,
        size: 50,
        page: 1,
        data_count: 50,
        total_data_count: 50,
        total_page: 1,
    },
    fetchParams: {
        recountingData: true,
        status: 'OPEN',
    },
    exportFetchParams: {},
    isLoading: false,
    tabItemsConsolidation: consolidationShipmentStatusHeader,
    tabItemConsolidationSelected: consolidationShipmentStatusHeader[0],
    tabItemsSea: shipmentStatusHeader,
    tabItemSeaSelected: shipmentStatusHeader[0],
}

const shipmentSlice = createSlice({
    name: 'shipment',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setExportFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, setExportFetchParam: data }
        },
        setData(state, action: PayloadAction<IResponseData<IShipment[]>>) {
            const data = action.payload
            return { ...state, data: data.data, meta: data.meta }
        },
        setTabItems(
            state,
            action: PayloadAction<{ type: ShipmentType; tabs: ITabItem[] }>,
        ) {
            const { type, tabs } = action.payload
            if (type === 'CONSOLIDATION') {
                return { ...state, tabItemsConsolidation: tabs }
            } else if (type === 'SEA') {
                return { ...state, tabItemsSea: tabs }
            } else if (type === 'AIR') {
                return { ...state, tabItemsAir: tabs }
            }
        },
        setSelectedTabItem(
            state,
            action: PayloadAction<{ type: ShipmentType; tab: ITabItem }>,
        ) {
            const { type, tab } = action.payload

            const fetchParam = state.fetchParams

            if (type === 'CONSOLIDATION') {
                return {
                    ...state,
                    fetchParams: {
                        ...fetchParam,
                        status: tab.value,
                        recountingData: false,
                    },
                    tabItemConsolidationSelected: tab,
                }
            } else if (type === 'SEA') {
                return {
                    ...state,
                    fetchParams: {
                        ...fetchParam,
                        status: tab.value,
                        recountingData: false,
                    },
                    tabItemSeaSelected: tab,
                }
            } else if (type === 'AIR') {
                return {
                    ...state,
                    fetchParams: {
                        ...fetchParam,
                        status: tab.value,
                        recountingData: false,
                    },
                    tabItemAirSelected: tab,
                }
            }
        },
    },
})

// these all the variables used for selector
export const shipmentFetchParamSelector = (state: RootState) =>
    state.shipment.fetchParams || {}
export const shipmentExportFetchParamSelector = (state: RootState) =>
    state.shipment.exportFetchParams || {}
export const shipmentDataSelector = (state: RootState) =>
    state.shipment.data || []
export const shipmentMetaSelector = (state: RootState) =>
    state.shipment.meta || {}
export const shipmentIsLoading = (state: RootState) => state.shipment.isLoading
export const shipmentTabItemsSelector = (
    state: RootState,
    type: ShipmentType,
) => {
    if (type === 'CONSOLIDATION') {
        return state.shipment.tabItemsConsolidation
    } else if (type === 'SEA') {
        return state.shipment.tabItemsSea
    } else if (type === 'AIR') {
        return state.shipment.tabItemsAir
    }
    return []
}
export const shipmentSelectedTabItemSelector = (
    state: RootState,
    type: ShipmentType,
) => {
    if (type === 'CONSOLIDATION') {
        return state.shipment.tabItemConsolidationSelected
    } else if (type === 'SEA') {
        return state.shipment.tabItemSeaSelected
    } else if (type === 'AIR') {
        return state.shipment.tabItemAirSelected
    }
    return {}
}

// all actions
export const {
    setData,
    setSelectedTabItem,
    setTabItems,
    setFetchParam,
    setIsLoading,
} = shipmentSlice.actions

export default shipmentSlice.reducer
