var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import './tooltip.style.css';
var Tooltip = function (_a) {
    var text = _a.text, isShow = _a.isShow, children = _a.children;
    var _b = useState(false), show = _b[0], setShow = _b[1];
    var clazz = show ? '' : 'hidden';
    useEffect(function () {
        setShow(isShow);
    }, [isShow]);
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "w-full relative" }, { children: [children, _jsxs("div", __assign({ className: "".concat(clazz, " absolute z-[2] mt-1 ") }, { children: [_jsx("div", { className: "arrow-up" }), _jsx("div", __assign({ className: "themes-bg-gray-v8 themes-text-white bg-opacity-90 leading-none rounded p-3" }, { children: text }))] }))] })) }));
};
export default Tooltip;
