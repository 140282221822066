import React from 'react'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'

const AccountsListForm = () => {
    return (
        <div className="overflow-auto">
            <div className="flex flex-col p-3 gap-3">
                <FormInput
                    label="Account Name"
                    placeholder="Enter Account Name"
                    name="name"
                    parentDivClassName="col-span-2"
                    className="!mb-0"
                />
                <FormInput
                    label="Account Number"
                    placeholder="Enter Account Number"
                    name="account_number"
                    parentDivClassName="col-span-2"
                    className="!mb-0"
                />
                <FormInput
                    label="Routing Number"
                    placeholder="Enter Routing Number"
                    name="routing_number"
                    parentDivClassName="col-span-2"
                    className="!mb-0"
                />
                <FormDropdown
                    name="currency.code"
                    label="Note Category"
                    placeholder="Select Note Category"
                    options={[
                        {
                            label: 'CUR_1000',
                            value: 'CUR_1000',
                        },
                    ]}
                />
                <FormTextarea
                    label="Notes Text"
                    name="remark"
                    placeholder="Enter Notes Text"
                />
            </div>
        </div>
    )
}

export default AccountsListForm
