import Table from '@components/table/table.component'
import { UAHeaders } from './user-access.static'
import './user-access.style.css'
import useUserAccess from './user-access.service'
import { IUser } from 'repository/interface/user.interface'
import TabMaster from '@components/tab-master/tab-master.component'
import UserFilterOverlay from './component/user-filter-overlay.component'

export default function UserAccess() {
    const {
        loading,
        userData,
        userDataMeta,
        tabItems,
        // tabFilter,
        selectedTabItem,
        filterOverlayService,
        setSearch,
        setTabFilter,
        navigate,
        setPageData,
        handleSearch,
    } = useUserAccess()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster
                    items={tabItems ?? []}
                    tabFilter={selectedTabItem}
                    onChange={(item) => setTabFilter(item)}
                    // selectedItem={tabFilter}
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <UserFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch="Search User"
                    onSearchSubmit={(values) => handleSearch(values)}
                    actionButton1={{
                        onClick: () => {
                            navigate('/manage-users/create')
                        },
                        label: 'CREATE USER',
                        className: '!text-size-S',
                    }}
                />
                <Table<IUser>
                    headers={UAHeaders}
                    data={userData}
                    loading={loading}
                    nextHandling={(page) => {
                        setPageData(page)
                    }}
                    previousHandling={(page) => {
                        setPageData(page)
                    }}
                    meta2={userDataMeta}
                    moduleTitle={'User'}
                    onRowClick={(data) => {
                        navigate(`/manage-users/details/${data.code}`)
                    }}
                    enableExport={false}
                />
            </div>
        </div>
    )
}
