var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage, useField } from "formik";
import Textarea from "@components/text-area/text-area.component";
var FormTextarea = function (_a) {
    var _b = _a.readonly, readonly = _b === void 0 ? false : _b, _c = _a.parentDivClassName, parentDivClassName = _c === void 0 ? "" : _c, _d = _a.hideError, hideError = _d === void 0 ? false : _d, props = __rest(_a, ["readonly", "parentDivClassName", "hideError"]);
    //formik
    var _e = useField(props.name), field = _e[0], meta = _e[1];
    var borderColorClass = meta.error && meta.touched ? "themes-border-red-v3" : "";
    var isError = !(!meta.error || (meta.error && !meta.touched));
    var errorClassMessage = isError && !hideError ? "" : "hidden";
    return (_jsxs("div", __assign({ className: "".concat(parentDivClassName) }, { children: [_jsx("div", __assign({ className: "flex relative h-full" }, { children: _jsx(Textarea, __assign({}, props, { value: field.value, onChange: field.onChange, placeholder: props.placeholder, className: "".concat(borderColorClass, " ").concat(props.className), readonly: readonly })) })), _jsx("div", __assign({ className: "ml-small-x text-size-XS themes-text-red-v3 ".concat(errorClassMessage) }, { children: _jsx(ErrorMessage, { name: props.name, component: "p" }) }))] })));
};
export default FormTextarea;
