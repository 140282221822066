import { ITableColumn } from '@components/table/table.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { IUser, IUserActivities } from 'repository/interface/user.interface'
import { ISTColumn } from '@components/simple-table/simple-table.interface'
import { formatDate } from '@services/common.service'

export const UAHeaders: ITableColumn<IUser>[] = [
    {
        label: 'Name',
        accessor: 'name',
        width: 1, // min width
    },
    {
        label: 'User ID',
        accessor: 'code',
        width: 1, // min width
    },
    {
        label: 'Email',
        accessor: 'email',
        width: 1, // min width
    },
    {
        label: 'Site',
        accessor: 'branches',
        width: 1, // min width
        customBuild: (data, rowData) => {
            return <>{'-'}</>
        },
    },
    {
        label: 'Job Title',
        accessor: 'job_title',
        width: 1, // min width
    },
    {
        label: 'Job Category',
        accessor: 'job_category',
        width: 1, // min width
        customBuild: (data, rowData) => {
            return <>{rowData?.job_category.name}</>
        },
    },
    {
        label: 'Role',
        accessor: 'role',
        width: 1,
        customBuild: (data, rowData) => {
            return <>{rowData?.role.name}</>
        },
    },
    {
        label: 'Last Active',
        accessor: 'lastActive',
        customBuild: (data) => {
            return <>{formatDate(data)}</>
        },
    },
    {
        label: 'Status',
        accessor: 'status',
    },
]

export const TabDataUser: ITabItem[] = [
    {
        label: 'Active',
        key: 'status',
        value: 'ACTIVE',
        className: 'brand',
        totalData: 0,
    },
    {
        label: 'Suspended',
        key: 'status',
        value: 'SUSPENDED',
        className: 'yellow',
        totalData: 0,
    },
    {
        label: 'Inactive',
        key: 'status',
        value: 'INACTIVE',
        className: 'red',
        totalData: 0,
    },
]

export const UserDetailHeaders: ITableColumn[] = [
    {
        label: 'Select',
        accessor: 'selected',
    },
    {
        label: 'Image',
        accessor: 'image',
    },
    {
        label: 'User ID',
        accessor: 'user_id',
    },
    {
        label: 'Name',
        accessor: 'name',
    },
    {
        label: 'Role',
        accessor: 'role',
    },
    {
        label: 'Organization Name',
        accessor: 'organization_name',
    },
    {
        label: 'Status',
        accessor: 'status',
    },
    {
        label: 'Last Access',
        accessor: 'last_access',
    },
    {
        label: 'Address',
        accessor: 'address',
    },
    {
        label: 'Module Adjusment',
        accessor: 'module_access',
    },
    {
        label: 'Log',
        accessor: 'log',
    },
    {
        label: 'Email Address',
        accessor: 'email',
    },
    {
        label: 'Phone Number',
        accessor: 'phone_number',
    },
]

export const ButtonActionName = [
    'CONTACT USER',
    'EDIT USER',
    'RESET PASSWORD',
    'SUSPEND USER',
    'REMOVE USER',
]

export const tabItemsInitial: ITabItem[] = [
    {
        label: 'Active',
        key: 'status',
        value: 'ACTIVE',
        totalData: 35,
        className: 'brand',
    },
    {
        label: 'Suspended',
        key: 'status',
        value: 'SUSPENDED',
        totalData: 15,
        className: 'yellow',
    },
    {
        label: 'Inactive',
        key: 'status',
        value: 'INACTIVE',
        className: 'red',
        totalData: 13,
    },
]

export const userActivityHeaders: ISTColumn<IUserActivities>[] = [
    {
        accessor: 'timestamp',
        label: 'Time',
        customBuild: (data) => {
            return <>{formatDate(data as string)}</>
        },
    },
    {
        accessor: 'module',
        label: 'Module',
    },
    {
        accessor: 'action',
        label: 'Action',
    },
    {
        accessor: 'description',
        label: 'Descriptions',
    },
]
