import { IFetchParams, IResponseData } from 'common/common.interface'
import { errorAxiosToast, fetch, post, put } from 'common/common.service'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { IQuotation } from './interface/quotations.interface'
import { IFormQuote, IFormQuotePayload } from 'form-validation/quote.validation'
import { Toast } from '@components/toast/toast.component'

export const getQuotation = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const endpoint = `${endpoints.quotations}`
        const response = await fetch<IResponseData<IQuotation[]>, IFetchParams>(
            {
                endpoint: endpoint,
                params: params,
            },
        )
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Quotation Data',
            })
        }
        return undefined
    }
}

export const getQuotationDetail = async (uuid: string) => {
    try {
        const endpoint = `${endpoints.quotations}/${uuid}`
        const response = await fetch<IResponseData<IQuotation>, IFetchParams>({
            endpoint: endpoint,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Fetch',
            message: 'Failed Fetch Quotation Detail',
        })
        return undefined
    }
}

export const submitQuotation = async (payload: IFormQuotePayload) => {
    try {
        const endpoint = `${endpoints.quotations}`
        await post<IResponseData<IQuotation>, IFormQuote>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'New quote is generated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Submit Quotation Data',
        })
        return undefined
    }
}

export const updateQuotation = async (
    code: string,
    payload: IFormQuotePayload,
) => {
    try {
        const endpoint = `${endpoints.quotations}/${code}`
        await put<IResponseData<IQuotation>, IFormQuote>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Update Success',
            message: 'Quotation is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Update',
            message: 'Failed to update Quotation Data',
        })
        return undefined
    }
}
