import { useEffect, useRef, useState } from "react";
var useOverlay = function () {
    var _a = useState(false), isOverlayOpen = _a[0], setOverlayOpen = _a[1];
    var ref = useRef(null);
    var openOverlayHandling = function () { return setOverlayOpen(true); };
    var closeOverlayHandling = function () { return setOverlayOpen(false); };
    var toggleOverlayHandling = function () { return setOverlayOpen(!isOverlayOpen); };
    var handleClickOutside = function (event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setOverlayOpen(false);
        }
    };
    useEffect(function () {
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return {
        openOverlayHandling: openOverlayHandling,
        closeOverlayHandling: closeOverlayHandling,
        toggleOverlayHandling: toggleOverlayHandling,
        isOverlayOpen: isOverlayOpen,
        ref: ref,
    };
};
export default useOverlay;
