/* eslint-disable no-unused-vars */
import SimpleTable from '@components/simple-table/simple-table.component'
import {
    contactListHeaders,
    notesHeaders,
} from '../../organisation-details.static'
import { IContactList, INotes } from '../../organisation-details.interface'
import Input from '@components/input/input.component'
import ToggleSwitch from '@components/toggle-switch/toggle-switch.component'
import { IUseModal, useModal } from '@components/modal/modal.service'
import RemoveModalAddress from '../remove-modal.component'
import Dropdown from '@components/dropdown/dropdown.component'
import { useNoteListService } from './notes.service'
import useOrganisationDetail from '../../organisation-details.service'
import CreateOrEditNoteModal from '../create-or-edit-note-modal.component'
import NotesListForm from './notes-form.component'
import { FormikProvider } from 'formik'
import Button from '@components/button/button.component'
import RemoveModal from '../remove-modal.component'
import DuplicateModal from '../duplicate-modal.component'

const Notes = () => {
    const {
        noteListData: data,
        loading,
        handleNoteDetail,
        formikOrgNoteDetail,
        formikOrgCreateNote,
        noteDetail,
        deleteNote,
        handleDuplicate,
    } = useNoteListService()

    const { removeModalService } = useOrganisationDetail()

    const createOrEditModal = useModal()
    const duplicateModal = useModal()

    return (
        <>
            <div className="flex gap-x-2">
                <div
                    className={`${data?.length > 0 ? 'w-3/4' : 'w-full'} h-[calc(100vh-15.5rem)]`}
                >
                    <SimpleTable<INotes>
                        headers={notesHeaders}
                        data={data || []}
                        addRowLabel="Add New Note"
                        isAddRow={true}
                        variant="gray"
                        onRowClick={(data) => handleNoteDetail(data)}
                        customBuildParam4={(data) => ({
                            // set details
                            // func1: () => func1(data.code),
                            func2: () => {
                                createOrEditModal.openModalHandling()
                            },
                            func3: () => {
                                removeModalService.openModalHandling()
                            },
                        })}
                        addRowClick={() => {
                            createOrEditModal.openModalHandling()
                        }}
                    />
                </div>
                {data?.length > 0 && (
                    <FormikProvider value={formikOrgNoteDetail}>
                        <div className="w-1/4 border rounded h-[calc(100vh-15.5rem)] flex flex-col">
                            <div className="flex justify-between items-center sm:flex-col">
                                <div className="p-4">Contact Details</div>
                                <div className="m-2 flex">
                                    <i
                                        onClick={() => {
                                            duplicateModal.openModalHandling()
                                        }}
                                        className="border-l-2 cursor-pointer p-1 ri-file-copy-line text-[1.25rem]"
                                    ></i>
                                    <i
                                        onClick={() => {
                                            removeModalService.openModalHandling()
                                        }}
                                        className="border-l-2 cursor-pointer p-1 ri-delete-bin-line text-[1.25rem] text-red-600"
                                    ></i>
                                </div>
                            </div>
                            <div className="border-b"></div>
                            <div className="overflow-auto">
                                <NotesListForm />
                            </div>
                            <div className="flex flex-col gap-2 p-3">
                                <Button
                                    label="SAVE CHANGES"
                                    variant="brand-inverse"
                                    className="w-full"
                                    icon="ri-save-line"
                                    onClick={() => {
                                        formikOrgNoteDetail.submitForm()
                                    }}
                                />
                                <Button
                                    label="DISCARD CHANGES"
                                    variant="red-inverse"
                                    className="w-full"
                                    icon="ri-save-line"
                                    onClick={() => {
                                        formikOrgNoteDetail.resetForm({
                                            values: noteDetail,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </FormikProvider>
                )}
                <RemoveModal
                    modalService={removeModalService}
                    type="removeNote"
                    onClick={() => {
                        const id = noteDetail?.code || ''
                        deleteNote(id)
                    }}
                    data={noteDetail?.code || ''}
                />
                <DuplicateModal
                    modalService={duplicateModal}
                    type="duplicateNote"
                    onClick={handleDuplicate}
                    data={noteDetail?.code || ''}
                />
            </div>
            <CreateOrEditNoteModal
                modalService={createOrEditModal}
                formik={formikOrgCreateNote}
                isCreate={true}
            />
        </>
    )
}

export default Notes
