var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage, useField } from 'formik';
import { useRef } from 'react';
import Button from '@components/button/button.component';
var FormInputFile = function (_a) {
    var _b;
    var _c = _a.readonly, readonly = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.type, type = _e === void 0 ? 'text' : _e, _f = _a.parentDivClassName, parentDivClassName = _f === void 0 ? '' : _f, _g = _a.hideError, hideError = _g === void 0 ? false : _g, props = __rest(_a, ["readonly", "disabled", "type", "parentDivClassName", "hideError"]);
    //formik
    var _h = useField(props.name), field = _h[0], meta = _h[1];
    var fileInputRef = useRef(null);
    var isError = !(!meta.error || (meta.error && !meta.touched));
    var errorClassMessage = isError && !hideError ? '' : 'hidden';
    var handleButtonClick = function () {
        if (fileInputRef === null || fileInputRef === void 0 ? void 0 : fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    return (_jsxs("div", __assign({ className: "".concat(parentDivClassName, " mb-4") }, { children: [_jsx("div", __assign({ className: "text-size-S font-bold mb-1\n                    ".concat(props.label ? '' : 'hidden') }, { children: (_b = props.label) !== null && _b !== void 0 ? _b : '' })), _jsxs("div", __assign({ className: "flex rounded-[5px] border themes-bg-gray-v1 items-center themes-border-gray-v4" }, { children: [_jsx(Button, { onClick: handleButtonClick, variant: "brand-inverse", label: "BROWSE FILE", className: "rounded-[0px] rounded-tl-[5px] rounded-bl-[5px] -mb-[0.5px] -ml-[1px] -mt-[0.5px]" }), _jsx("span", __assign({ className: "themes-text-gray-v5 px-4 !text-size-XS" }, { children: field.value || 'No file selected' }))] })), _jsx("input", __assign({}, props, { ref: fileInputRef, value: field.value, onChange: function (e) {
                    field.onChange(e);
                }, type: 'file', style: { display: 'none' }, accept: ".png, .jpg, .jpeg, .pdf, .xlsx, .xls" })), _jsx("div", __assign({ className: "ml-small-x text-size-XS themes-text-red-v3 ".concat(errorClassMessage) }, { children: _jsx(ErrorMessage, { name: props.name, component: "p" }) }))] })));
};
export default FormInputFile;
