import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'
import { IMeasurement } from '@components/measurement-input/measurement-input.interface'
export interface IFormContainer {
    containerCode?: string
    container_number: string
    container_type: string
    seal_number: string
    tare_weight: IMeasurement
    delivery_mode: string
    code?: string
}

export interface IFormContainerPayload {
    container_number: string
    container_type: { code: string }
    seal_number: string
    tare_weight?: string
    tare_weight_unit?: { code: string }
    delivery_mode: string
}

export const useContainerValidation = () => {
    const schema = Yup.object<IFormContainer>().shape({
        container_number: Yup.string().required(
            requiredMessage('Container Number'),
        ),
        container_type: Yup.string().required(
            requiredMessage('Container Type'),
        ),
        seal_number: Yup.string().required(requiredMessage('Seal Number')),
        tare_weight: Yup.object<IMeasurement>().shape({
            qty: Yup.string().nullable(),
            unit: Yup.string().nullable(),
        }),
    })

    const initialValue: IFormContainer = {
        containerCode: '',
        container_number: '',
        container_type: '',
        seal_number: '',
        tare_weight: { qty: '0', unit: '', unitLabel: '' },
        delivery_mode: '',
    }

    return {
        initialValue,
        schema,
    }
}
