import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
    eDocsSelector,
    setEdocsData,
    setFetchEdocs,
    shipmentDetailSelector,
} from '../detail-shipment.slice'
import { useFormik } from 'formik'
import { useModal } from '@components/modal/modal.service'
import {
    IFormEdocs,
    IFormEdocsPayload,
    useEdocsValidation,
} from 'form-validation/edocs.validation'
import {
    deleteEdocs,
    fetchEdocs,
    submitEdocs,
    updateEdocs,
} from 'repository/edocs'
import { IEDoc } from 'repository/interface/edocs.interface'

export const useEdocs = () => {
    const dropdownService = useDropdown()
    const dispatch = useAppDispatch()

    // States
    const [isLoading, setIsLoading] = useState(false)
    const [isNew, setIsNew] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState<File | undefined>()

    // Selector
    const edocs = useSelector(eDocsSelector)
    const shipmentDetailData = useSelector(shipmentDetailSelector)

    // Variable
    const { data, isFetchNew } = edocs
    const modalService = useModal()

    // Formik
    const useEdocsFormik = useEdocsValidation()
    const formikEdocs = useFormik<IFormEdocs>({
        initialValues: useEdocsFormik.initialValue,
        validationSchema: useEdocsFormik.schema,
        validateOnChange: true,
        onSubmit: (values) => {
            submit(values)
        },
    })

    const getEdocs = async () => {
        if (!shipmentDetailData?.code) return
        if (!isFetchNew) return
        setIsLoading(true)
        const response = await fetchEdocs(shipmentDetailData.code)
        if (!response?.data?.length) {
            dispatch(setEdocsData([]))
        } else {
            dispatch(setEdocsData(response.data))
        }

        setIsLoading(false)
        dispatch(setFetchEdocs(false))
    }

    const resetForm = () => {
        formikEdocs.setValues(useEdocsFormik.initialValue)
    }

    const deleteItem = async () => {
        const code = shipmentDetailData.code ?? ''
        const noteCode = formikEdocs.getFieldProps('code').value
        setDeleteLoading(true)
        await deleteEdocs(code, noteCode)
        setDeleteLoading(false)
        resetForm()
        modalService.closeModalHandling()
        dispatch(setFetchEdocs(true))
        getEdocs()
    }

    const submit = async (value: IFormEdocs) => {
        const code = shipmentDetailData.code ?? ''
        const noteCode = value.code ?? ''

        const formData = new FormData()
        formData.append('document_type', value.document_type)
        formData.append('description', value.description)
        formData.append('file', selectedFile ?? '')

        setSubmitLoading(true)
        let response: true | undefined
        if (isNew) {
            response = await submitEdocs(code, formData)
        } else {
            response = await updateEdocs(code, noteCode, formData)
        }
        if (!response) {
            setSubmitLoading(false)
            return
        }

        resetForm()
        modalService.closeModalHandling()
        setSubmitLoading(false)
        getEdocs()
    }

    const selectRow = (value: IEDoc) => {
        const newValue: IFormEdocs = {
            file: 'update',
            description: value.description,
            document_type: value.document_type,
            isFileChanged: false,
            fileName: value.filename,
            fileUrl: value.path,
            code: value.code,
        }

        formikEdocs.setValues(newValue)
        modalService.openModalHandling()
    }

    useEffect(() => {
        getEdocs()
    }, [isFetchNew, shipmentDetailData])

    useEffect(() => {
        dropdownService.getDocumentType()
    }, [])

    return {
        dropdownService,
        modalService,
        isLoading,
        data,
        formikEdocs,
        isNew,
        submitLoading,
        deleteLoading,
        setIsNew,
        resetForm,
        submit,
        selectRow,
        deleteItem,
        setSelectedFile,
    }
}
